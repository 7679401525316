<script>
import { mapGetters } from 'vuex'
import { VStepper } from 'vue-stepper-component'
import GoodWizard from '../../utility/vueGoodWizard'
import Axios from 'axios'
import _ from 'lodash'
import ImageSelector from '../../utility/image-selector'
import Multiselect from 'vue-multiselect'

export default {
  name: 'estimate-options-send-to-suppliers',
  data: function () {
    return {
      preset: [],
      suppliers: [],
      supplierIds: [],
      isCanSend: true,
      supplier_parts: [],
      suppliersOnPage: 15,
      suppliersPages: 1,
      magicSearchSuppliers: '',
      estimateToSupplier: {
        customer: {
          customer_name: '',
        },
        customer_vehicle: {
          rego_number: '',
        },
        estimate: {
          vehicleRego: '',
          vehicleOwner: '',
          number: '',
          estimate_number: '',
          supplement_number: '',
        },
      },
      supplier_select_all_parts: [],
      estimateToSupplierParts: {
        suppliers: [],
        parts: [],
      },
      supplier_type: {
        OEM: 'OEM',
        AM: 'After Market',
        WRECKER: 'Wrecker',
      },
      states: {
        QLD: 'Queensland',
        NT: 'Northern Territory',
        NSW: 'New South Wales',
        VIC: 'Victoria',
        TAS: 'Tasmania',
        SA: 'South Australia',
        WA: 'Western Australia'
      },
      images: [],
      selectedImages: [],
      steps: [
        {
          label: 'Select Suppliers',
          slot: 'estimate-options-send-to-suppliers-step-1',
        },
        {
          label: 'Select Parts',
          slot: 'estimate-options-send-to-suppliers-step-2',
        },
        {
          label: 'Select Images',
          slot: 'estimate-options-send-to-suppliers-step-3',
          options: {
            nextDisabled: true,
          },
        }
      ],
      step: 1,
      fields: [
        { label: '', key: 'id', sortable: false, tdClass: 'checkbox clickable', thClass: 'checkbox bg-navyblue' },
        { label: 'Supplier Name', key: 'business_name', sortable: true, tdClass: 'clickable', thClass: 'bg-navyblue' },
        { label: 'Type', key: 'type', sortable: true, tdClass: 'clickable', thClass: 'bg-navyblue' },
        { label: 'Email', key: 'email', sortable: true, tdClass: 'clickable', thClass: 'bg-navyblue' },
        { label: 'Suburb', key: 'suburb', sortable: true, tdClass: 'clickable', thClass: 'bg-navyblue' },
        { label: 'State', key: 'state', sortable: true, tdClass: 'clickable', thClass: 'bg-navyblue' },
        { label: 'Phone', key: 'phone', sortable: true, tdClass: 'clickable', thClass: 'bg-navyblue' },
      ],

    }
  },
  methods: {
    supplierChangeTab () {

    },
    showMoreSuppliers () {
      this.suppliersPages = this.suppliersPages + 1
    },
    supplierModalChangeTab (data) {
      if (data.newIndex === 1) { // this mean Select Parts tab active
        this.estimateToSupplierParts.suppliers = [] // important
        this.suppliersShow.forEach((supplier, i) => {
          if (this.supplierIds.includes(supplier.supplier_id.toString())) {
            if (supplier.selected_parts === undefined) {
              supplier.selected_parts = []
            }

            if (this.supplier_parts['id_' + supplier.supplier_id] === undefined) {
              this.supplier_parts['id_' + supplier.supplier_id] = []
            }
            this.estimateToSupplierParts.suppliers.push(supplier)
          }
        })

      }
    },
    selectAllPartsForSupplier (supplier, parts) {
      supplier.selected_parts = []
      this.supplier_parts['id_' + supplier.supplier_id] = []

      this.supplier_select_all_parts.forEach((supplier_id, i) => {
        if (supplier.supplier_id === supplier_id) {
          parts.forEach((part, j) => {
            supplier.selected_parts.push(part.id)
            this.supplier_parts['id_' + supplier_id].push(part.id.toString())
          })
        }
      })
    },
    selectPartForSupplier (supplier, parts) {
      console.log('row', supplier)
      console.log('parts', parts)
      if (this.supplier_parts['id_' + supplier.supplier_id].length === parts.length) {
        if (!this.supplier_select_all_parts.includes(supplier.supplier_id)) {
          this.supplier_select_all_parts.push(supplier.supplier_id)
        }
      } else {
        const indexOfAllParts = this.supplier_select_all_parts.indexOf(supplier.supplier_id)
        if (indexOfAllParts > -1) {
          this.supplier_select_all_parts.splice(indexOfAllParts, 1)
        }
      }

      supplier.selected_parts = []
      this.supplier_parts['id_' + supplier.supplier_id].forEach((part_id, i) => {
        parts.forEach((partItem, j) => {
          if (part_id === partItem.id.toString()) {
            supplier.selected_parts.push(partItem.id)
          }
        })
      })
    },
    loadSuppliers: function (make) {
      this.supplierIds = []
      make = make + '/'
      NProgress.start()
      Axios({
        method: 'post',
        responseType: 'json',
        url: `/fe/supplier/make/active`,
        validateStatus: function (status) {
          return status < 500
        },
        data: {
          make: make,
          pageNum: 1,
          sort_by: 'supplier_id',
          sort_type: 'DESC'
        }
      })
          .then(response => {
            this.suppliers = response.data.data
            this.current_page = response.data.current_page
            this.total_pages = response.data.total_pages
            this.total_records = response.data.total_records
            this.start_num = response.data.start_num
            this.end_num = response.data.end_num

            NProgress.done()
          })
          .catch(error => {
            // console.log(error)
            NProgress.done()
          })
    },
    getImages(v) {
      let r = [];
      _.forEach(_.cloneDeep(v), (itm) => {
        if (!_.includes(itm.src, 'at=')) {
          itm.src += '/?at=' + localStorage.getItem('token')
        }
        if (!_.includes(itm.previewW220, 'at=')) {
          itm.previewW220 += '/?at=' + localStorage.getItem('token')
        }
        r.push(itm)
      })
      return r
    },
    loadEstimateToSupplier: function (estimate_id) {
      NProgress.start()
      Axios.get(`/fe/estimate/to/supplier/${estimate_id}`)
          .then(response => {
            this.estimateToSupplier = response.data
            this.images = this.getImages(response.data.images)
            this.estimateToSupplierParts.parts = response.data.rightParts
            NProgress.done()
            this.loadSuppliers(this.estimateToSupplier.customer_vehicle.make)
          })
          .catch(error => {
            toastr.error('Error occurred while loading estimate to customer info')
            // console.log(error)
            NProgress.done()
            /*todo
            needs to change to correct VueRouterAddress
             */
            //   this.$router.push('/');
          })
    },
    goToEstimate () {
      this.$router.push({
        name: 'EstimatesEdit',
        params: { estimate_id: this.computedEstimateId, tab: this.$route.params.tab }
      })
    },
    clickCancel: function () {
      this.$router.push({
        name: 'EstimatesEdit',
        params: { estimate_id: this.computedEstimateId, tab: this.$route.params.tab }
      })
      return
    },
    clickSend: function () {
      if (!this.isCanSend) {
        return
      }

      this.isCanSend = false

      let suppliersList = []

      let estimate_id = this.$route.params.estimate_id
      this.selectedSuppliersForPartsTab.forEach((supplier, i) => {
        suppliersList.push({
          supplierId: supplier.id,
          partIds: supplier.selected_parts,
        })
      })

      NProgress.start()
      Axios({
        method: 'post',
        responseType: 'json',
        url: `/fe/email/supplier/${estimate_id}`,
        validateStatus: function (status) {
          return status < 500
        },
        data: JSON.stringify({
          suppliers: suppliersList,
          images: this.selectedImages,
        })
      })
          .then(response => {
            if (response.status == 200) {
              toastr.success(response.data.msg)
              this.$router.push({
                name: 'EstimatesEdit',
                params: { estimate_id: this.computedEstimateId, tab: this.$route.params.tab }
              })
              // this.$refs.supplierModal.hide();
            } else {
              toastr.error(response.data.msg)
            }
            NProgress.done()
          })
          .catch(error => {
            NProgress.done()
            toastr.error(error)
          })
          .finally(() => {
            this.isCanSend = true
          })
    },
    onImageSelect: function (imgs) {
      // console.log(imgs)
      this.selectedImages = imgs
    },
  },
  computed: {
    ...mapGetters({
      getterBusinessName: 'getBusinessName',
      isShowBusinessName: 'isShowBusinessName',
    }),
    computedEstimateId () {
      if (this.estimateToSupplier.estimate.supplement_number > 0) {
        return this.estimateToSupplier.estimate.parent_estimate_id
      }
      return this.$route.params.estimate_id
    },
    availableSuppliers: function () {
      return this.suppliers
    },
    selectedSuppliersForPartsTab: function () {
      let suppliers = []
      try {
        this.suppliersShow.forEach((supplier, i) => {
          if (this.supplierIds.includes(supplier.supplier_id.toString())) {
            if (supplier.selected_parts === undefined) {
              supplier.selected_parts = []
            }

            if (this.supplier_parts['id_' + supplier.supplier_id] === undefined) {
              this.supplier_parts['id_' + supplier.supplier_id] = []
            }
            suppliers.push(supplier)
          }
        })
      } catch (e) {
        console.log(e)
      }
      let listOEM = _.sortBy(
          _.filter(suppliers,
              function (i) {
                return i.type == 'OEM'
              }),
          'name'
      )
      let listAFTM = _.sortBy(
          _.filter(suppliers,
              function (i) {
                return i.type == 'AM'
              }),
          'name'
      )
      let listUSED = _.sortBy(
          _.filter(suppliers,
              function (i) {
                return i.type == 'USED'
              }),
          'name'
      )
      let listWRECKER = _.sortBy(
          _.filter(suppliers,
              function (i) {
                return i.type == 'WRECKER'
              }),
          'name'
      )
      return _.concat(listOEM, listAFTM, listUSED, listWRECKER)
      //return suppliers;
    },
    suppliersLength: function () {
      return this.suppliersList.length
    },
    suppliersShow: function () {
      return this.suppliersList.slice(0, this.suppliersCount)
    },
    suppliersList: function () {
      try {
        // this.supplierIds = [];
        if (this.magicSearchSuppliers == '') {
          return this.suppliers
        }
        this.suppliersPages = 1
        var st = this.magicSearchSuppliers
        var f = _.filter(this.suppliers, function (i) {
          let a1 = i.business_name.match(new RegExp(st, 'i'))
          let a2 = i.email.match(new RegExp(st, 'i'))
          let a3 = i.type.match(new RegExp(st, 'i'))
          let a4 = i.suburb.match(new RegExp(st, 'i'))
          let a5 = i.state.match(new RegExp(st, 'i'))
          let a6 = i.phone.match(new RegExp(st, 'i'))
          if (a1 || a2 || a3 || a4 || a5 || a6) {
            return true
          }
          return false
        })
        return f
      } catch (error) {
        console.log(error)
        return []
      }

    },
    suppliersCount: function () {
      if ((this.suppliersOnPage * this.suppliersPages) > this.suppliersLength) {
        return this.suppliersLength
      }
      return (this.suppliersOnPage * this.suppliersPages)
    },
    suppliersShowCount: function () {
      return this.suppliersCount
    },
    isShowMoreSuppliers: function () {
      if (this.suppliersShowCount < this.suppliersLength) {
        return true
      }
      return false
    },
  },
  mounted: function () {
    this.loadEstimateToSupplier(this.$route.params.estimate_id)
  },
  components: {
    Multiselect,
    VStepper,
    GoodWizard,
    ImageSelector,
  }
}

</script>

<template>
  <div class="estimate-options-send-to-suppliers">
    <div class="page-header">
      <h4>Edit Estimate - {{ estimateToSupplier.estimate.estimate_number }} <span
          v-if="estimateToSupplier.estimate.supplement_number > 0">- {{ estimateToSupplier.estimate.supplement_number }} </span><span
          v-if="estimateToSupplier.customer.customer_name">| {{ estimateToSupplier.customer.customer_name }} </span><span
          v-if="estimateToSupplier.customer_vehicle.rego_number">| {{ estimateToSupplier.customer_vehicle.rego_number }}</span>
      </h4>
      <ol class="breadcrumbs">
        <li class="breadcrumb-item home">
          <router-link :to="'/'">Home</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link :to="'/estimates'">View Estimates</router-link>
        </li>
        <li class="breadcrumb-item"><a href="" @click.prevent="goToEstimate">Edit Estimate</a></li>
        <li class="breadcrumb-item">Options</li>
        <li class="breadcrumb-item active">Send To Suppliers</li>
      </ol>
      <h4 v-if="isShowBusinessName" class="header-display-name">{{ getterBusinessName }}</h4>
    </div>
    <div class="">

      <good-wizard
          :steps="steps"
          :currentStep="step"
          ref="estimateOptionsSendToSuppliers"
      >
        <div slot="estimate-options-send-to-suppliers-step-1" class="estimate-options-send-to-suppliers-step-1">
          <div class="wizard-header">
            <div class="wizard-header-name">
              Select Suppliers
            </div>
            <div class="wizard-subheader-name">
              <div class="wizard-subheader-name-text">
                Select the supplier(s) to send
              </div>
            </div>
            <div class="wizard-header-button pull-right">
              <input v-model="magicSearchSuppliers" type="text" class="form-control search-input search-input-mobile"
                     style="width:300px"
                     :placeholder="'Search'">
            </div>
          </div>
          <div class="estimate-options">
            <b-table
                responsive
                hover
                :items="suppliersShow"
                :fields="fields"
                class="rs-scroll--y"
            >
              <template v-slot:cell(id)="row">
                <label class="form-check-inline checkbox">
                  <input class="form-check-input"
                         type="checkbox"
                         :value="row.item.supplier_id"
                         v-model="supplierIds">
                  <span class="icon"><i class='bx bx-check'></i></span>
                </label>
              </template>
            </b-table>
            <div class="select-suplier-show-more">
              <a v-if="isShowMoreSuppliers" @click="showMoreSuppliers" class="nav-link clickable">Show More <i
                  class="fa fa-caret-down"></i></a>
              <p>Showing {{ suppliersShowCount }} of {{ suppliersLength }} suppliers</p>
            </div>
          </div>
        </div>
        <div slot="estimate-options-send-to-suppliers-step-2" class="estimate-options-send-to-suppliers-step-2">
          <div class="header">
            Select Parts
          </div>
          <div class="subheader">
            Choose the Part(s) to send to your suppliers for a quote.
          </div>
          <div>
            <table class="table b-table select-parts">
              <thead class="bg-navyblue">
              <tr>
                <th class="header-name" style="text-align: left;">Part Name</th>
                <th class="header-name">Qty</th>
                <th class="text-center vertical-top header-supplier" :class="{ 'supplier-first': index === 0 }"
                    v-for="supplier, index in selectedSuppliersForPartsTab" :key="'supplier'+supplier.supplier_id">
                  <div class="supplier-default-icon"><i class='bx bx-car'></i></div>
                  <div class="supplier-name">{{ supplier.business_name }}</div>
                  <div class="supplier-type">{{ supplier.type }}</div>
                  <div class="select-parts-check-all">
                    <label class="form-check-inline checkbox" style="margin-top:18px;">
                      <input class="form-check-input"
                             type="checkbox"
                             @change="selectAllPartsForSupplier(supplier, estimateToSupplierParts.parts)"
                             :value="supplier.supplier_id"
                             v-model="supplier_select_all_parts"
                      >
                      <span class="icon"><i class='bx bx-check'></i></span>
                    </label>
                  </div>
                  <div class="supplier-due-by">Due by:</div>
                  <div class="supplier-due-by-time">
                    <Multiselect
                        v-model="preset[supplier.supplier_id]"
                        :options="[{key:0,value:'preset 30 mins'}, {key:1, value:'45 mins'}, {key:'2',value:'1 hour'},
                                                        {key:'3',value:'1.5 hours'}, {key:'4',value:'2 hours'}, {key:'5',value:'3 hours'}, {key:'6',value:'4 hours'},
                                                        {key:'7',value:'6 hours'}, {key:'8',value:'8 hours'}, {key:'9',value:'24 hours'}, {key:'10',value:'48 hours'}]"
                        :showLabels="false"
                        :option-height="29"
                        :max-height="203"
                        :close-on-select="true"
                        track-by="key"
                        label="value"
                    ></Multiselect>
                  </div>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="partItem in estimateToSupplierParts.parts">
                <td>{{ partItem.name }}</td>
                <td class="text-center">{{ partItem.qty }}</td>
                <td v-for="supplier in selectedSuppliersForPartsTab" class="text-center vertical-middle">
                  <label class="form-check-inline checkbox">
                    <input type="checkbox" class="vertical-middle"
                           @change="selectPartForSupplier(supplier, estimateToSupplierParts.parts)"
                           v-model="supplier_parts['id_'+supplier.supplier_id]"
                           :value="partItem.id"
                           :data-part-id="partItem.id"/>
                    <span class="icon"><i class='bx bx-check'></i></span>
                  </label>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="send-email-type">
              <label class="form-check-inline checkbox">
                <input class="form-check-input"
                       type="checkbox"
                       checked
                       disabled
                >
                <span class="icon"><i class='bx bx-check'></i></span><span class="text">Email to Suppliers</span>
              </label>
              <label class="form-check-inline checkbox">
                <input class="form-check-input"
                       type="checkbox"
                       disabled
                >
                <span class="icon"><i class='bx bx-check'></i></span><span
                  class="text">Send through to PartsSearch</span>
              </label>
            </div>
          </div>
        </div>
        <div slot="estimate-options-send-to-suppliers-step-3" class="estimate-options-send-to-suppliers-step-3">
          <div class="header">
            Select Images
          </div>
          <div class="subheader">
            Choose the images to attach by ticking the box next to the image
          </div>
          <div class="box box-block">
            <div class="row send-to-additional-position-image-wizard">
              <image-selector :images="images" :path="''" @onChange="onImageSelect"></image-selector>
            </div>
          </div>
        </div>
      </good-wizard>

      <div class="wizard__buttons">
        <div class=" pull-right">
          <button
              class="btn btn-outline-primary pull-left button-cancel mx-0"
              type="button"
              @click="clickCancel"
          >
            Cancel
          </button>
          <button
              v-if="$refs.estimateOptionsSendToSuppliers && $refs.estimateOptionsSendToSuppliers.currentStep != 0"
              class="btn btn-outline-primary-light pull-left button-back mr-0 ml-1"
              type="button"
              @click="$refs.estimateOptionsSendToSuppliers.goBack()"
          >
            Back
          </button>
          <button
              v-if="$refs.estimateOptionsSendToSuppliers && $refs.estimateOptionsSendToSuppliers.currentStep != 2"
              class="btn btn-primary pull-right button-next"
              type="button"
              :disabled="supplierIds.length === 0"
              @click="$refs.estimateOptionsSendToSuppliers.goNext()"
              :tabindex="4"
          >
            Next
          </button>
          <button
              v-if="$refs.estimateOptionsSendToSuppliers && $refs.estimateOptionsSendToSuppliers.currentStep == 2"
              class="btn btn-primary pull-right button-card-estimate"
              type="button"
              @click="clickSend"
              :disabled="!isCanSend"
              style=""
          >
            Send
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.V3 .estimate-options-send-to-suppliers .wizard__body {
  padding: 15px;
  width: 100%;
}

.V3 .estimate-options-send-to-suppliers .wizard__body .estimate-options-send-to-suppliers-step-1 {
  width: 75%;
}

.V3 .estimate-options-send-to-suppliers .wizard__body .estimate-options-send-to-suppliers-step-2 {
  width: 75%;
}

.V3 .wizard-header .wizard-header-name {
  margin-right: 300px;
}

.V3 .wizard-header .wizard-subheader-name-text {
  margin-right: 300px;
}

@media screen and (max-width: 767px) {
  .V3 .estimate-options-send-to-suppliers .wizard__body .estimate-options-send-to-suppliers-step-1,
  .V3 .estimate-options-send-to-suppliers .wizard__body .estimate-options-send-to-suppliers-step-2 {
    width: 100%;
  }

  .V3 .wizard-header .wizard-header-name {
    margin-right: 200px !important;
  }

  .V3 .wizard-header .wizard-subheader-name-text {
    margin-right: 200px !important;
  }

  .V3 .estimate-options-send-to-customers .wizard__buttons {
    width: 100% !important;
  }

  .search-input-mobile {
    width: 200px !important;
  }

  .V3 .estimate-options-send-to-suppliers .wizard__buttons {
    width: 100% !important;
  }

  .V3 .estimate-options-send-to-suppliers .wizard__buttons {
    margin-top: 20px !important;
  }
}

@media screen and (max-width: 460px) {
  .V3 .page-header {
    padding: 5px 0 5px 15px !important;
  }
}

@media screen and (max-width: 400px) {
  .V3 .wizard-header {
    display: flex;
    flex-direction: column;
  }

  .V3 .wizard-header .wizard-header-button {
    position: static;
    margin-left: 0;
    padding-top: 5px;
    width: 100%;
  }

  .V3 .search-input-mobile {
    width: 100% !important;
  }

  .V3 .wizard-header .wizard-header-name {
    margin-right: 0 !important;
  }

  .V3 .wizard-header .wizard-subheader-name-text {
    margin-right: 0 !important;
  }
}

.V3 .estimate-options-send-to-suppliers .wizard__body .estimate-options-send-to-suppliers-step-1 .table th,
.V3 .estimate-options-send-to-suppliers .wizard__body .estimate-options-send-to-suppliers-step-2 .table th {
  color: #1C1F39;
  font-size: 13px;
  font-weight: bold;
  line-height: 18px;
  /*   vertical-align: top; */
}

.V3 .estimate-options-send-to-suppliers .wizard__body .estimate-options-send-to-suppliers-step-1 .table td,
.V3 .estimate-options-send-to-suppliers .wizard__body .estimate-options-send-to-suppliers-step-2 .table td {
  color: #1C1F39;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
}

.V3 .estimate-options-send-to-suppliers .wizard__body .estimate-options-send-to-suppliers-step-3 {
  width: 100%;
}

.V3 .estimate-options-send-to-suppliers .wizard__buttons {
  width: 75%;
  margin: 0 0 70px 5px;
  padding-right: 15px;
}

</style>
<style scoped>
.V3 .select-suplier-show-more {
  display: inline-table;
  position: relative;
  transform: translateY(10px);
  margin-bottom: -25px;
  margin-left: 9px;
}

.V3 .select-suplier-show-more a {
  display: inline-block;
  color: #1C1F39;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  margin-bottom: 3px;
}

.V3 .select-suplier-show-more a i {
  color: #1C1F39;
  font-size: 11px;
  margin-left: 3px;
}

.V3 .select-suplier-show-more p {
  color: #1C1F39;
  font-size: 12px;
  font-weight: 600;
}

.V3 .send-email-type {
  display: inline-table;
  position: relative;
  transform: translateY(10px);
  margin-bottom: -25px;
  margin-left: 9px;
}

.V3 .send-email-type p {
  margin-bottom: 0.7rem;
}

@media screen and (max-width: 440px) {
  .V3 .send-email-type {
    margin-bottom: 5px;
  }

  .V3 .send-email-type .checkbox {
    margin-bottom: 5px;
  }
}

.V3 .send-email-type label.checkbox > span.text {
  display: inline-block;
  margin-top: 5px;
  padding-right: 15px;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
}

.V3 .estimate-options-send-to-suppliers >>> .card-block {
  padding: 0px;
}

.V3 .estimate-options-send-to-suppliers .card-block {
  padding: 0px;
}


.V3 .wizard__buttons .pull-left {
  float: left !important;
  margin-right: 10px;
}

.V3 .wizard__buttons .pull-right {
  float: right !important;
  margin-left: 10px;
}

.V3 .wizard__buttons .btn {
  font-size: 13px;
  font-weight: bold;
  line-height: 18px;
  height: 38px;
}

.V3 .wizard__buttons .button-cancel {
  padding: 10px 21px;
  background: #FFFFFF;
  border: 1px solid rgba(28, 31, 57, 0.25);
  color: #1C1F39;
}

.V3 .wizard__buttons .button-card {
  padding: 10px 15px;
  background: #FFFFFF;
  color: #5E79FF;
}

.V3 .wizard__buttons .button-card-estimate {
  padding: 10px 15px;
}

.V3 .wizard__buttons .button-next {
  padding: 10px 15px;
  width: 83px;
}

.V3 .wizard__buttons .button-back {
  padding: 10px 26px;
  background: #FFFFFF;
  border-color: #5E79FF;
  width: 83px;
}

@media (max-width: 823px) AND (min-width: 604px) {
  .V3 .card-new .wizard__buttons {
    padding-left: 15px;
    padding-right: 15px;
    width: auto;
    min-width: auto;
  }

  .V3 .compact-sidebar .card-new .wizard__buttons {
    width: 573px;
    margin: 0 auto;
    min-width: 573px;
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (max-width: 603px) {
  .V3 .card-new .wizard__buttons {
    padding-left: 15px;
    padding-right: 15px;
    width: auto;
    min-width: auto;
  }
}

@media (max-width: 512px) {
  .V3 .card-new .wizard__buttons button {
    margin-bottom: 10px;
    float: none !important;
    margin-left: 0px;
    margin-right: 0px;
    display: block;
  }
}
</style>

<style scoped>
.V3 .estimate-options-send-to-suppliers .estimate-options-send-to-suppliers-step-2 .supplier-default-icon {
  font-size: 18px;
}

.V3 .estimate-options-send-to-suppliers .estimate-options-send-to-suppliers-step-2 .supplier-name {
  font-size: 13px;
  font-weight: bold;
  line-height: 18px;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 5px;
}

.V3 .estimate-options-send-to-suppliers .estimate-options-send-to-suppliers-step-2 .supplier-type {
  font-size: 12px;
  font-style: italic;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
}

.V3 .estimate-options-send-to-suppliers .estimate-options-send-to-suppliers-step-2 .header-name {
  vertical-align: top;
  padding-top: 35px;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  line-height: 18px;
}

.V3 .estimate-options-send-to-suppliers .estimate-options-send-to-suppliers-step-2 .header-supplier {
  width: 140px;
}

.V3 .estimate-options-send-to-suppliers .estimate-options-send-to-suppliers-step-2 th.header-supplier {
  padding-left: 0px;
  padding-right: 0px;
}

.V3 .estimate-options-send-to-suppliers .estimate-options-send-to-suppliers-step-2 .supplier-due-by-time {
  width: 166px;
  margin: 0;
  padding-left: 15px;
  padding-right: 15px;
  border-left: 1px solid rgba(27, 30, 56, 0.25);
}

.V3 .estimate-options-send-to-suppliers .estimate-options-send-to-suppliers-step-2 .header-supplier.supplier-first .supplier-due-by-time {
  border-left: none;
}

.V3 .estimate-options-send-to-suppliers .estimate-options-send-to-suppliers-step-2 .supplier-due-by-time select.form-control {
  padding-right: 20px !important;
  color: #1C1F39;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
}

.V3 .estimate-options-send-to-suppliers .estimate-options-send-to-suppliers-step-2 .supplier-due-by {
  width: 100px;
  margin: 15px auto 0 auto;
  text-align: left;
  color: rgba(28, 31, 57, 0.5);
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
}

.V3 .estimate-options-send-to-suppliers .estimate-options-send-to-suppliers-step-2 .select-parts .select-parts-check-all span.icon,
.V3 .estimate-options-send-to-suppliers .estimate-options-send-to-suppliers-step-2 .select-parts span.icon {
  margin-right: 0px;
}

@media screen and (max-width: 500px) {
  .V3 .page-header {
    min-height: 100px !important;
  }

  .page-header h4 {
    height: auto;
  }
}
</style>
