<script>
    import {mapActions, mapGetters} from 'vuex';
    import Axios from "axios";
    import Paginate from "vuejs-paginate";
    import {VueContext} from "vue-context";
    import draggable from "vuedraggable";
    import {Vue2Storage} from "vue2-storage";
    import {isMobileOnly} from "../../deviceDetect/index";
    import _ from "lodash";
    import NotificationCell from '../notification-cell.vue';
    import CardActions from './card-actions.vue';
    import NumberFormatter from '../utility/number-formatter';
    import Multiselect from 'vue-multiselect';
    import BlockPagination from '../utility/block-pagination';
    import CardsBoard from './cards-board/cards-board'
    import SearchFilter2 from '../search-filter/search-filter-2'
    import {appConfig} from "../../config";

    export default {
        name: "cards",
        data: function () {
            return {
                copyCard: {
                    isNew: false,
                    copyToCardId: '',
                    isCopyImages: false,
                    isCopyFiles: false,
                    copyToCard: {},
                },
              categories: [],
              filtersFromRoute: [],
                reasonForDeclineORM: '',
                countOfFilterChange: 0,
                locationForDecline: '',
                cardIdForCopy: null,
                cardNumberForCopy: null,
                boardSingleSearch: {},
                filterAssignTo: '',
                intervalUpdateCards: null,
                filteredCardsAllRowsCountValue: 0,
                filteredCardsActiveRowsCountValue: 0,
                filteredCardsOpenRowsCountValue: 0,
                filteredCardsClosedRowsCountValue: 0,
                currentViewActive: "table-view",
                searchFilter: '',
                contextOpenEvent: null,
                isCardBoardChanging: false,
                isCardTableChanging: false,
                cards: [],
                card: {},
                ceStatuses: [],
                ceStatusesLimits: {
                  'All': [],
                  'Open': [],
                  'Active': [],
                  'Closed': []
                },
                ceStatusAllItemsCount: [],
                ceStatusOpenItemsCount: [],
                ceStatusActiveItemsCount: [],
                ceStatusClosedItemsCount: [],
                CESObj: [],
                order_status: [],
                cardsAllFiltered: [],

                cardsActive: [],
                cardsActiveFiltered: [],

                cardsOpen: [],
                cardsOpenFiltered: [],

                cardsClosed: [],
                cardsClosedFiltered: [],

                dpOptions: {
                    autoclose: true,
                    todayHighlight: true,
                    format: "dd/mm/yyyy",
                },
                cardProgress: {},
                current_page: 1,
                total_pages: 0,
                total_records: 0,
                start_num: 0,
                end_num: 0,
                modal: {},
                fields: [
                    {
                        label: "Number",
                        key: "card_number",
                        sortable: true,
                        tdClass: "text-center clickable cards-table-number",
                        thClass: "text-center bg-navyblue  cards-table-number",
                    },
                    {
                        label: "Vehicle Owner",
                        key: "customer_name",
                        sortable: true,
                        tdClass: "clickable cards-table-vehicle-owner",
                        thClass: "bg-navyblue  cards-table-vehicle-owner",
                    },
                    {
                        label: "Rego",
                        key: "rego_number",
                        sortable: true,
                        tdClass: "text-center clickable cards-table-rego",
                        thClass: "text-center bg-navyblue cards-table-rego",
                    },
                    {
                        label: "Make",
                        key: "make",
                        sortable: true,
                        tdClass: "text-center clickable cards-table-make",
                        thClass: "text-center bg-navyblue cards-table-make",
                    },
                    {
                        label: "Model",
                        key: "model",
                        sortable: true,
                        tdClass: "text-center clickable cards-table-model",
                        thClass: "text-center bg-navyblue cards-table-model",
                    },
                    {
                        label: "Colour",
                        key: "colour",
                        sortable: true,
                        tdClass: "text-center clickable cards-table-colour",
                        thClass: "text-center bg-navyblue cards-table-colour",
                    },
                    {
                        label: "Insurer",
                        key: "insurance",
                        sortable: true,
                        tdClass: "text-center clickable cards-table-insurer",
                        thClass: "text-center bg-navyblue cards-table-insurer",
                    },
                    {
                        label: "Date Created",
                        sortable: true,
                        key: "created_on",
                        sortByFormatted: (value, key, item) => {
                            return item.sortCreatedDate;
                        },
                        tdClass: "text-center clickable  cards-table-created-on",
                        thClass: "text-center bg-navyblue  cards-table-created-on",
                    },
                    {
                        label: "Assigned",
                        key: "assignedTo",
                        sortable: true,
                        sortByFormatted: (value, key, item) => {
                            if (item.assignedTo && item.assignedTo.name && item.assignedTo.id) {
                                return item.assignedTo.name;
                            }
                        },
                        tdClass: "text-center clickable  cards-table-assigned-to",
                        thClass: "text-center bg-navyblue  cards-table-assigned-to",
                    },
                    {
                        label: "Priority",
                        key: "priority",
                        sortable: true,
                        tdClass: "text-center clickable  cards-table-assigned-to",
                        thClass: "text-center bg-navyblue  cards-table-assigned-to",
                    },
                    {
                        label: "Notifications",
                        key: "notifications",
                        sortable: true,
                        tdClass: "clickable  cards-table-notifications",
                        thClass: "text-center bg-navyblue cards-table-notifications",
                    },
                    {
                        label: "Status",
                        key: "status_name",
                        sortable: true,
                        tdClass: "text-center clickable  cards-table-status",
                        thClass: "text-center bg-navyblue cards-table-status",
                    },
                ],
                isAutoSave: false,
                searchCard: "",
                updateData: {},
                card_drag_id: null,
                sortingCardData: {},
                openedNewTabCardId: null,
                activitiesGetted: true,
            };
        },
        computed: {
            ...mapGetters({
                isShopManagerUser: 'isShopManagerUser',
                isProductionManagerUser: 'isProductionManagerUser',
                isEstimatorUser: 'isEstimatorUser',
                isPDRTechnicianUser: 'isPDRTechnicianUser',
              isDisplayBoardUser: 'isDisplayBoardUser',
                isTechnicalUser: 'isTechnicalUser',
                isPanelTechnicianUser: 'isPanelTechnicianUser',
                isPaintTechnicianUser: 'isPaintTechnicianUser',
                isCustomerUser: 'isCustomerUser',
                isStripperFitterUser: 'isStripperFitterUser',
                isDetailerUser: 'isDetailerUser',
                cardsAll: 'card/getAllCards',
                getterOpenCards: 'card/getOpenCards',
                getterClosedCards: 'card/getClosedCards',
                getterActiveCards: 'card/getActiveCards',
                getterActiveUsers: 'activeUsers',
                getterHiddenStatuses: 'card/hiddenStatuses',
                getterVisibleUsers: 'visibleUsers',
                getterPerPage: 'card/getPerPage',
                getterCardsAllCurrentPage: 'card/getCardsAllCurrentPage',
                getterCardsOpenCurrentPage: 'card/getCardsOpenCurrentPage',
                getterCardsActiveCurrentPage: 'card/getCardsActiveCurrentPage',
                getterCardsClosedCurrentPage: 'card/getCardsClosedCurrentPage',
                getterSortByForCardsAll: 'card/getSortByForCardsAll',
                getterSortDescForCardsAll: 'card/getSortDescForCardsAll',
                getterSortByForCardsOpen: 'card/getSortByForCardsOpen',
                getterSortDescForCardsOpen: 'card/getSortDescForCardsOpen',
                getterSortByForCardsActive: 'card/getSortByForCardsActive',
                getterSortDescForCardsActive: 'card/getSortDescForCardsActive',
                getterSortByForCardsClosed: 'card/getSortByForCardsClosed',
                getterSortDescForCardsClosed: 'card/getSortDescForCardsClosed',
                isStatusActive: 'isStatusActive',
                getterBusinessName : 'getBusinessName',
              isShowBusinessName: 'isShowBusinessName',
              getterToken: 'card/getToken',
              getterTokens: 'card/getTokens',
              getterSearchFilterObj: 'card/getSearchFilterObj',
              getterInsurerCardColor: 'getInsurerCardColor',
            }),
          isCanSearchBySomeFields() {
            return !this.isPDRTechnicianUser && !this.isPanelTechnicianUser && !this.isPaintTechnicianUser && !this.isStripperFitterUser && !this.isDisplayBoardUser
          },
          isHasNotFilters() {
            return !this.isCardNumberFilterActive && !this.isPartNumberFilterActive && !this.isInsurerNameFilterActive
            && !this.isRegoFilterActive && !this.isMakeFilterActive && !this.isModelFilterActive && !this.isEstimateNumberFilterActive && !this.isCustomerNameFilterActive
          },
          computedCardsAllForTable() {
              if (this.isHasNotFilters) {
                return this.cardsAll
              }
            return this.filterCards(this.cardsAll)
          },
          computedCardsOpenForTable() {
              if (this.isHasNotFilters) {
                return this.getterOpenCards
              }
            return this.filterCards(this.getterOpenCards)
          },
          computedCardsActiveForTable() {
              if (this.isHasNotFilters) {
                return this.getterActiveCards
              }
            return this.filterCards(this.getterActiveCards)
          },
          computedCardsClosedForTable() {
              if (this.isHasNotFilters) {
                return this.getterClosedCards
              }
            return this.filterCards(this.getterClosedCards)
          },
          isDisabledModalFinishButton () {
            return !!(_.isEmpty(this.reasonForDeclineORM) || _.isEmpty(this.locationForDecline));
          },
          computedToken() {
            return this.getterToken
          },
          computedSearchFilters() {
            let cardNumbers = {};
            let estimateNumbers = {};
            let partNumbers = {};
            let insurers = {};
            let regos = {};
            let makes = {};
            let models = {};
            let vehicleOwner = {};

            this.cardsAll.forEach(card => {
              if (card.card_number) {
                cardNumbers[card.card_number.trim().toLowerCase()] = {label: card.card_number.trim()}
              }
            })

            this.cardsAll.forEach(card => {
              if (card.customer_name) {
                vehicleOwner[card.customer_name.trim().toLowerCase()] = {label: card.customer_name.trim()}
              }
            })

            this.cardsAll.forEach(card => {
              if (card.estimateNumber) {
                estimateNumbers[card.estimateNumber.trim().toLowerCase()] = {label: card.estimateNumber.trim()}
              }
            })

            this.cardsAll.forEach(card => {
              if (card.rego_number) {
                regos[card.rego_number.trim().toLowerCase()] = {label: card.rego_number.trim()}
              }
            })

            this.cardsAll.forEach(card => {
              if (card.make) {
                makes[card.make.trim().toLowerCase()] = {label: card.make.trim()}
              }
            })

            this.cardsAll.forEach(card => {
              if (card.model) {
                models[card.model.trim().toLowerCase()] = {label: card.model.trim()}
              }
            })

            this.cardsAll.forEach(card => {
              if (card.insurance) {
                insurers[card.insurance.trim().toLowerCase()] = {label: card.insurance.trim()}
              }
            })

            this.cardsAll.forEach(card => {
              if (card.partNumbers) {
                let numbers = card.partNumbers.split(' ')
                _.forEach(numbers, n => {
                  if (_.trim(n)) {
                    partNumbers[n.toLowerCase().trim()] = {label: n.toLowerCase().trim()}
                  }
                })
              }
            })

            partNumbers = _.orderBy(Object.values(partNumbers), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
            cardNumbers = _.orderBy(Object.values(cardNumbers), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
            vehicleOwner = _.orderBy(Object.values(vehicleOwner), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
            estimateNumbers = _.orderBy(Object.values(estimateNumbers), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
            regos = _.orderBy(Object.values(regos), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
            makes = _.orderBy(Object.values(makes), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
            models = _.orderBy(Object.values(models), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
            insurers = _.orderBy(Object.values(insurers), [(itm) => itm.label.toLowerCase().trim()], ['asc'])

            let result = [
              {
                id: 'cardNumber',
                label: 'Card Number',
                iconClass: 'bx-user',
                options: [
                  cardNumbers,
                ]
              },
              {
                id: 'estimateNumber',
                label: 'Estimate Number',
                iconClass: 'bx-user',
                options: [
                  estimateNumbers,
                ]
              },
              {
                id: 'partNumber',
                label: 'Part Number',
                iconClass: 'bx-user',
                options: [
                  partNumbers,
                ]
              },
              {
                id: 'insurer',
                label: 'Insurer',
                iconClass: 'bx-user',
                options: [
                  insurers,
                ]
              },
              {
                id: 'rego',
                label: 'Rego',
                iconClass: 'bx-user',
                options: [
                  regos,
                ]
              },
              {
                id: 'make',
                label: 'Make',
                iconClass: 'bx-user',
                options: [
                  makes,
                ]
              },
              {
                id: 'model',
                label: 'Model',
                iconClass: 'bx-store',
                options: [
                  models
                ]
              },
              {
                id: 'customerName',
                label: 'Vehicle Owner',
                iconClass: 'bx-store',
                options: [
                  vehicleOwner
                ]
              },
            ]
            if (!this.isCanSearchBySomeFields) {
              result = _.filter(result, (r) => {
                return r.id !== 'estimateNumber' && r.id !== 'insurer'
              })
            }
            return result
          },
          searchFilterObj() {
            return this.getterSearchFilterObj
          },
            computedSortByForCardsAll() {
                return this.getterSortByForCardsAll;
            },
            computedSortDescForCardsAll() {
                return this.getterSortDescForCardsAll;
            },
            computedSortByForCardsOpen() {
                return this.getterSortByForCardsOpen;
            },
            computedSortDescForCardsOpen() {
                return this.getterSortDescForCardsOpen;
            },
            computedSortByForCardsActive() {
                return this.getterSortByForCardsActive;
            },
            computedSortDescForCardsActive() {
                return this.getterSortDescForCardsActive;
            },
            computedSortByForCardsClosed() {
                return this.getterSortByForCardsClosed;
            },
            computedSortDescForCardsClosed() {
                return this.getterSortDescForCardsClosed;
            },
            cardsAllCurrentPage() {
                return this.getterCardsAllCurrentPage;
            },
            cardsOpenCurrentPage() {
                return this.getterCardsOpenCurrentPage;
            },
            cardsActiveCurrentPage() {
                return this.getterCardsActiveCurrentPage;
            },
            cardsClosedCurrentPage() {
                return this.getterCardsClosedCurrentPage;
            },
            cardsAllPerPage() {
                return this.getterPerPage;
            },
            hiddenStatuses() {
                return this.getterHiddenStatuses;
            },
            namesOfHiddenStatuses() {
                let names = [];
                let self = this;
                _.forEach(this.hiddenStatuses.userHiddenStatuses, function (id) {
                    let ceStatus = _.filter(self.ceStatuses, function (status) {
                        return status.ce_status_id == id;
                    });
                    if (ceStatus && ceStatus[0]) {
                        names.push({
                            name: ceStatus[0].name,
                            id: ceStatus[0].ce_status_id,
                        });
                    }
                });
                _.forEach(this.hiddenStatuses.vendorHiddenStatuses, function (id) {
                    let ceStatus = _.filter(self.ceStatuses, function (status) {
                        return status.ce_status_id == id;
                    });
                    if (ceStatus && ceStatus[0]) {
                        names.push({
                            name: ceStatus[0].name,
                            id: ceStatus[0].ce_status_id,
                        });
                    }
                });
                return _.uniqBy(names, 'id');
            },
            cardsAllFiltered2() {
                return this.cardsAllFiltered;
            },
            cardsOpenFiltered2() {
                return this.cardsOpenFiltered;
            },
            cardsActiveFiltered2() {
                return this.cardsActiveFiltered;
            },
            cardsClosedFiltered2() {
                return this.cardsClosedFiltered;
            },
            computedPerPage() {
                return this.cardsAllPerPage;
            },
            isJustSeeMine: {
                get() {
                    return this.$store.getters.isJustSeeMine;
                },
                set(val) {
                    this.$store.dispatch('updateJustSeeMine', val);
                }
            },
            computedCardsForCopyCard() {
                let vm = this;
                let cards = _.filter(this.cardsAll, function (i) {
                    return !(i.state === "3" || i.status_name == "Invoiced" || i.status_name == "Closed" || i.card_id == vm.card.card_id);
                });
                return cards;
            },
            computedActiveUsers() {
                if (!this.filterAssignTo || this.filterAssignTo == '') {
                  let users = this.getterVisibleUsers.slice();
                  users.unshift({
                    id: null,
                    name: 'None',
                    groupId: null,
                    group_id: null,
                    isActive: true,
                    isVisible: true,
                    lastName: null,
                    firstName: null,
                    role: null,
                  })
                  return users;
                }
                let self = this;
                let filterUsers = _.filter(this.getterVisibleUsers, function (user) {
                    let search = [];
                    search.push(_.toLower(user.name));
                    return !!(search.join(',').indexOf(_.toLower(self.filterAssignTo)) > -1);
                });
                return filterUsers;
            },
            filter: {
                get() {
                    return this.$store.getters.cardFilter;
                },
                set(value) {
                    this.$store.commit('setCardFilter', value);
                }
            },
            isMobileOnly: function () {
                return isMobileOnly;
            },
            isTableViewActive: function () {
                if (this.isDisplayBoardUser) {
                  return false
                }
                if (!this.isShopManagerUser && !this.isProductionManagerUser && !this.isEstimatorUser && !this.isPDRTechnicianUser && !this.isCustomerUser) {
                    return true;
                }
                if (
                    this.currentViewActive == "table-view" ||
                    (this.currentViewActive == "board-view" && isMobileOnly)
                ) {
                    return true;
                }
                return false;
            },
            isBoardViewActive: function () {
                if (this.isDisplayBoardUser) {
                  return true
                }
                if (!this.isShopManagerUser && !this.isProductionManagerUser && !this.isEstimatorUser && !this.isPDRTechnicianUser && !this.isCustomerUser) {
                    return false;
                }
                if (this.currentViewActive == "board-view" && !isMobileOnly) {
                    return true;
                }
                return false;
            },
            computedDefaultCardTabHash: function () {
                if (this.$storage.get("cards.tabs.defaultCards")) {
                    //  console.log('ret', this.$storage.get('cards.tabs.defaultCards'));
                    return this.$storage.get("cards.tabs.defaultCards");
                }
                return "table-view";
            },
            computedDefaultCardBoardTabHash: function () {
                if (this.$storage.get("cards.tabs.defaultCardsBoard")) {
                    // console.log('ret', this.$storage.get('cards.tabs.defaultCardsBoard'));
                    return this.$storage.get("cards.tabs.defaultCardsBoard");
                }
                return "cards-all-board";
            },
            computedDefaultCardTableTabHash: function () {
                if (this.$storage.get("cards.tabs.defaultCardsTable")) {
                    //  console.log('ret', this.$storage.get('cards.tabs.defaultCardsTable'));
                    return this.$storage.get("cards.tabs.defaultCardsTable");
                }
                return "cards-all";
            },

          computedCardsAll() {
              let cards = this.computedCardsAllForTable
              if (this.filter) {
                return _.filter(cards, (c) => {
                  return this.isCheckedByFilter(c, this.filter)
                })
              }
              return cards
          },
            computedOpenCards() {
              return _.filter(this.computedCardsAll, (c) => {
                return Number(c.state == 0)
              })
            },
          computedActiveCards() {
            return _.filter(this.computedCardsAll, (c) => {
              return Number(c.state != 3) && Number(c.state !== 0)
            })
          },
           computedClosedCards() {
              return _.filter(this.computedCardsAll, (c) => {
                return Number(c.state == 3)
              })
           },
          isCardNumberFilterActive() {
            return this.getIsActiveFilter('cardNumber')
          },
          computedCardNumberFilter() {
            return this.getActiveFilter('cardNumber')
          },
          isPartNumberFilterActive() {
            return this.getIsActiveFilter('partNumber')
          },
          computedPartNumberFilter() {
            return this.getActiveFilter('partNumber')
          },

          isEstimateNumberFilterActive() {
            return this.getIsActiveFilter('estimateNumber')
          },
          computedEstimateNumberFilter() {
            return this.getActiveFilter('estimateNumber')
          },

          isCustomerNameFilterActive() {
            return this.getIsActiveFilter('customerName')
          },
          computedCustomerNameFilter() {
            return this.getActiveFilter('customerName')
          },

          isInsurerNameFilterActive() {
            return this.getIsActiveFilter('insurer')
          },
          computedInsurerNameFilter() {
            return this.getActiveFilter('insurer')
          },

          isRegoFilterActive() {
            return this.getIsActiveFilter('rego')
          },
          computedRegoFilter() {
            return this.getActiveFilter('rego')
          },

          isMakeFilterActive() {
            return this.getIsActiveFilter('make')
          },
          computedMakeFilter() {
            return this.getActiveFilter('make')
          },

          isModelFilterActive() {
            return this.getIsActiveFilter('model')
          },
          computedModelFilter() {
            return this.getActiveFilter('model')
          },

        },

        methods: {
            ...mapActions({
                actionLoadCards: 'card/loadCards',
            }),
          loadCategories() {
            Axios.get('/fe/planner-board/groups')
                .then(response => {
                  if (response.data.groups && response.data._status) {
                    this.categories = response.data.groups
                  }
                })
                .finally(()=> {})
          },
          getInsurerNameStyle(id) {
            if (!id) {
              return null
            }
            let data = this.getterInsurerCardColor?.[id];
            if (data) {
              return {
                color: data.color,
                backgroundColor: data.background,
                fontWeight: 800,
                padding: '3px 7px',
                borderRadius: '5px',
                textAlign: 'center',
                display: 'inline-block'
              }
            }
            return null
          },
          getIsActiveFilter(field) {
            for (let key in this.searchFilterObj) {
              if (this.searchFilterObj[key][field]) {
                return true
              }
            }
            return false
          },
          getActiveFilter(field) {
            let filter = {            }
            filter[field] = ''
            let obj = Object.assign({}, this.searchFilterObj)
            for (let key in obj) {
              if (obj[key][field]) {
                filter = obj[key]
                break
              }
            }
            filter = _.split(filter[field], ';')
            return _.filter(filter, (f) => {return _.trim(f)})
          },
          filterCards(value) {
              return _.filter(value, (itm) => {
                if (!this.isCardNumberFilterActive && !this.isPartNumberFilterActive && !this.isInsurerNameFilterActive
                    && !this.isRegoFilterActive && !this.isMakeFilterActive && !this.isModelFilterActive && !this.isEstimateNumberFilterActive && !this.isCustomerNameFilterActive) {
                  return true
                }
                if (this.isCardNumberFilterActive) {
                  if (_.includes((this.computedCardNumberFilter), _.trim(itm.card_number))) {
                    return true
                  }
                }

                if (this.isInsurerNameFilterActive && _.trim(itm.insurance)) {
                  if (_.includes((this.computedInsurerNameFilter), _.trim(itm.insurance))) {
                    return true
                  }
                }

                if (this.isRegoFilterActive && _.trim(itm.rego_number)) {
                  if (_.includes((this.computedRegoFilter), _.trim(itm.rego_number))) {
                    return true
                  }
                }

                if (this.isMakeFilterActive && _.trim(itm.make)) {
                  if (_.includes((this.computedMakeFilter), _.trim(itm.make))) {
                    return true
                  }
                }
                if (this.isModelFilterActive && _.trim(itm.model)) {
                  if (_.includes((this.computedModelFilter), _.trim(itm.model))) {
                    return true
                  }
                }
                if (this.isPartNumberFilterActive && itm.partNumbers) {
                  let isCheckedPartNumber = _.some(itm.partNumbers.split(' '), (n) => {
                    return n && _.includes(this.computedPartNumberFilter, n)
                  })
                  if (isCheckedPartNumber) {
                    return true
                  }
                }

                if (this.isEstimateNumberFilterActive && _.trim(itm.estimateNumber)) {
                  if (_.includes((this.computedEstimateNumberFilter), _.trim(itm.estimateNumber))) {
                    return true
                  }
                }

                if (this.isCustomerNameFilterActive && _.trim(itm.customer_name)) {
                  if (_.includes((this.computedCustomerNameFilter), _.trim(itm.customer_name))) {
                    return true
                  }
                }
                return false
              })
          },
            onSearchFilterChange(filters) {
              console.log(filters, 'filters')
              this.$store.commit('card/setSearchFilterObj', filters)
              if (filters && filters[0] && filters[0].search) {
                this.$store.commit('setCardFilter', filters[0].search);
                // this.filter = filters.search;
              } else {
                this.$store.commit('setCardFilter', '');
                // this.filter = '';
              }
              this.countOfFilterChange += 1
            },
          setTitle (isNeedToClear = false) {
            let text = !isNeedToClear && this.currentViewActive == "board-view" ? 'Process Board' : appConfig.appName
            let title = document.getElementsByTagName('title')
            if (title?.[0]) {
              title[0].innerText = text
            }
          },
            isCheckedByFilter(c, f) {
              if (f == this.$route.params.filter) {
                return !!_.find(this.$route.params.items, (itm) => {
                  return Number(itm) == Number(c.card_id)
                })
              }
              if (((f == this.$route.params.trackerFilter || f == this.$route.query.trackerFilter) && !_.isEmpty(this.filtersFromRoute))) {
                return !!_.find(this.filtersFromRoute, (id) => Number(id) == Number(c.card_id))
              }
              let filter = _.trim(_.toLower(f))
              if (_.isEmpty(filter)) {
                return true
              }
              if (_.includes(_.trim(_.toLower(c.card_number)), filter)) {
                return true
              }
              if (_.includes(_.trim(_.toLower(c.rego_number)), filter)) {
                return true
              }
              if (this.isCanSearchBySomeFields && _.includes(_.trim(_.toLower(c.estimateNumber)), filter)) {
                return true
              }
              if (_.includes(_.trim(_.toLower(c.make)), filter)) {
                return true
              }
              if (_.includes(_.trim(_.toLower(c.model)), filter)) {
                return true
              }
              // if (_.includes(_.trim(_.toLower(c.priority)), filter)) {
              //   return true
              // }
              if (this.isCanSearchBySomeFields && _.includes(_.trim(_.toLower(c.insurance)), filter)) {
                return true
              }
              // if (_.includes(_.trim(_.toLower(c.colour)), filter)) {
              //   return true
              // }
              if (_.includes(_.trim(_.toLower(c.customer_name)), filter)) {
                return true
              }
              // if (_.includes(_.trim(_.toLower(c.card_id)), filter)) {
              //   return true
              // }
              // if (_.includes(_.trim(_.toLower(c.partNumbers)), filter)) {
              //   return true
              // }
              // if (_.includes(_.trim(_.toLower(c.filters)), filter)) {
              //   return true
              // }
              // if (_.includes(_.trim(_.toLower(c.claim_number)), filter)) {
              //   return true
              // }
              return false
            },
            acceptORMJob () {
              this.closeTableContext();
              NProgress.start();
              Axios.post("/fe/testAccept", {})
                  .then(response => {
                    if (response?.data?._status) {
                      toastr.success('ORM job [ORMnumber] has been successfully Accepted.');
                    }
                  })
                  .catch(error => {
                    console.log(error);
                  })
                  .finally(() => {
                    NProgress.done();
                  })
            },
            declineORMJob () {
              this.$refs.declineORMJob.hide();
              NProgress.start();
              Axios.post("/fe/testDecline", {})
                  .then(response => {
                    if (response?.data?._status) {
                      toastr.success('ORM job [TestOrmNumber] has been successfully declined.')
                    }
                  })
                  .catch(error => {
                    console.log(error);
                  })
                  .finally(() => {
                    NProgress.done();
                  })
            },
            openDeclineORMJobModal () {
              this.closeTableContext();
              this.$refs.declineORMJob.show();
            },
            openCardInNewTab(cardId) {
              this.openedNewTabCardId = cardId;
              this.openNewTabCard();
            },
            openCardInNewWindow(cardId) {
              this.openedNewTabCardId = cardId;
              this.openNewBrowserCard();
            },
            isHasPriority(priority) {
              if (priority === 'Low' || priority === 'Medium' || priority === 'High' || priority === 'OnHold') {
                return true
              }
              return  false;
            },
            setSortByAndSortDescForCardsAll(e) {
                this.$store.commit('card/setSortByForCardsAll', e.sortBy);
                this.$store.commit('card/setSortDescForCardsAll', e.sortDesc);
            },
            setSortByAndSortDescForCardsOpen(e) {
                this.$store.commit('card/setSortByForCardsOpen', e.sortBy);
                this.$store.commit('card/setSortDescForCardsOpen', e.sortDesc);
            },
            setSortByAndSortDescForCardsActive(e) {
                this.$store.commit('card/setSortByForCardsActive', e.sortBy);
                this.$store.commit('card/setSortDescForCardsActive', e.sortDesc);
            },
            setSortByAndSortDescForCardsClosed(e) {
                this.$store.commit('card/setSortByForCardsClosed', e.sortBy);
                this.$store.commit('card/setSortDescForCardsClosed', e.sortDesc);
            },
            setCardsAllCurrentPage(val) {
                this.$store.commit('card/setCardsAllCurrentPage', val);
            },
            setCardsOpenCurrentPage(val) {
                this.$store.commit('card/setCardsOpenCurrentPage', val);
            },
            setCardsActiveCurrentPage(val) {
                this.$store.commit('card/setCardsActiveCurrentPage', val);
            },
            setCardsClosedCurrentPage(val) {
                this.$store.commit('card/setCardsClosedCurrentPage', val);
            },
            setCardsAllPerPage(val) {
                this.$store.commit('card/setPerPage', val);
            },
            isShowMore(id, array, status) {
              let limit = _.find(this.ceStatusesLimits[status], (s) => {
                return Number(s?.status?.ce_status_id) == Number(id)
              })
              return array[id]?.length > limit?.limit
            },
            increaseLimit(id, status) {
              let limit = _.find(this.ceStatusesLimits[status], (s) => {
                return Number(s?.status?.ce_status_id) == Number(id)
              })
              limit.limit += 50;
            },
            clickFilterTableForMine() {
                this.isJustSeeMine = !this.isJustSeeMine;
                this.setData();
            },
            statusPosition(item) {
                let positionStatus =
                    this.ceStatuses.findIndex(
                        (value) => Number(value.ce_status_id) === Number(item.card_status)
                    ) + 1;
                return positionStatus;
            },
            statusPositionByPercentage(item) {
                let index = this.statusPosition(item);
                return ((index / this.ceStatuses.length) * 100);
            },
            showStatus(id) {
                NProgress.start();
                Axios({
                    method: 'post',
                    responseType: 'json',
                    url: '/fe/cards/kanban/show-status',
                    validateStatus: function (status) {
                        return status < 500;
                    },
                    data: {
                        statusId: id,
                    },
                }).then(response => {
                    if (response.status === 200 && response.data._status) {
                        this.$store.commit('card/updateUserHiddenStatuses', response.data.userHiddenStatuses);
                        this.$store.commit('card/updateVendorHiddenStatuses', response.data.vendorHiddenStatuses);
                    }
                })
                    .finally(() => {
                        NProgress.done();
                    });
            },
            addToVehicleInBoard(id, number) {
                this.closeTableContext();
                NProgress.start();
                Axios({
                    method: 'post',
                    responseType: 'json',
                    url: `/fe/board/in/add-card`,
                    validateStatus: function (status) {
                        return status < 500;
                    },
                    data: {
                        cardId: id,
                    },
                }).then(response => {
                    if (response.status === 200 && response.data._status) {
                        toastr.success('Card ' + number + ' added successfully to the Vehicle In Board');
                    } else {
                        toastr.error(response.data.msg);
                    }
                })
                    .catch(error => {
                        toastr.error(error);
                    })
                    .finally(() => {
                        NProgress.done();
                    });
            },
            addToDepartureBoard(id, number) {
                this.closeTableContext();
                NProgress.start();
                Axios({
                    method: 'post',
                    responseType: 'json',
                    url: `/fe/board/departure/add-card`,
                    validateStatus: function (status) {
                        return status < 500;
                    },
                    data: {
                        cardId: id,
                    },
                }).then(response => {
                    if (response.status === 200 && response.data._status) {
                        toastr.success('Card ' + number + ' added successfully to the Departure Board');
                    } else {
                        toastr.error(response.data.msg);
                    }
                })
                    .catch(error => {
                        toastr.error(error);
                    })
                    .finally(() => {
                        NProgress.done();
                    });
            },
            closeContextBoard() {
              let rows = document.getElementsByClassName('board-item');
              _.forEach(rows, (elem) => {
                elem.style.backgroundColor = "";
                elem.style.border = "2px solid #bebebe";
              })
            },
            getBoardItem(event) {
                if (!event) {
                    return;
                }
                return _.find(document.elementsFromPoint(event.clientX, event.clientY), (el) => {
                  return _.includes(el.classList, 'board-item')
                })

            },
            openMenuForBoardCards(event, title, cardId, cardNumber) {
                if (!this.isStatusActive || this.isDisplayBoardUser) {
                  return
                }
              let rows = document.getElementsByClassName('board-item');
              _.forEach(rows, (elem) => {
                elem.style.backgroundColor = "";
                elem.style.border = "2px solid #bebebe";
              })

                let boardItem = this.getBoardItem(event);
                if (boardItem){
                  this.$nextTick(() => {
                    boardItem.style.backgroundColor = "#F3F3F3";
                    boardItem.style.borderColor = "#5e77fc";
                  })
                }
                this.openMenuBoardResult(event, title, cardId, cardNumber);
            },
            completeCopyCard: function () {
                this.$refs.copyCardModal.hide();
                NProgress.start();
                if (this.copyCard.copyToCard && this.copyCard.copyToCard.card_id && this.copyCard.copyToCard.card_id > 0) {
                    this.copyCard.copyToCardId = this.copyCard.copyToCard.card_id;
                }
                delete this.copyCard.copyToCard;
                Axios({
                    method: 'post',
                    responseType: 'json',
                    url: `/fe/card/${this.cardIdForCopy}/copy-card`,
                    validateStatus: function (status) {
                        return status < 500;
                    },
                    data: this.copyCard,
                }).then(response => {
                    if (response.status === 200 && response.data._status) {
                        if (!this.copyCard.isNew) {
                            this.$store.dispatch('card/deleteCardById', this.copyCard.copyToCardId);
                        }
                        toastr.success('Duplicate Card ' + response.data.number + ' created successfully');
                        this.loadCards(1);
                        this.$router.push({
                          name: 'CardsEdit',
                          params: {
                            card_id: response.data.id,
                            filters: this.$route.params.filters ? this.$route.params.filters : this.$route.query.filters,
                            trackerFilter: this.$route.params.trackerFilter ? this.$route.params.trackerFilter : this.$route.query.trackerFilter
                          }
                        });
                        return;
                    } else {
                        toastr.error(response.data.msg);
                    }
                })
                    .catch(error => {
                        toastr.error(error);
                    })
                    .finally(() => {
                        NProgress.done();
                    });
            },
            copyCardModal: function (cardId, cardNumber) {
                this.closeTableContext();
                if (!this.isStatusActive) {
                  return
                }
                this.cardIdForCopy = cardId;
                this.cardNumberForCopy = cardNumber;
                this.copyCard.isNew = false;
                this.copyCard.copyToCardId = 0;
                this.copyCard.copyToCard = {};
                this.copyCard.isCopyImages = false;
                this.copyCard.isCopyFiles = false;
                this.$refs.copyCardModal.show();
            },
            filterForTable(row, filter) {
                if (filter == this.$route.params.filter) {
                  return !!_.find(this.$route.params.items, (itm) => {
                    return Number(itm) == Number(row.card_id)
                  })
                }
                if ((filter == this.$route.params.trackerFilter || filter == this.$route.query.trackerFilter) && !_.isEmpty(this.filtersFromRoute)) {
                  return !!_.find(this.filtersFromRoute, (id) => Number(id) == Number(row.card_id))
                }
                let formattedFilter = _.trim(_.toLower(filter))
                if (row.card_number && row.card_number.toLowerCase().includes(formattedFilter)) {
                    return true;
                } else if (row.customer_name && row.customer_name.toLowerCase().includes(formattedFilter)) {
                    return true;
                } else if (row.rego_number && row.rego_number.toLowerCase().includes(formattedFilter)) {
                    return true;
                } else if (this.isCanSearchBySomeFields && row.insurance && row.insurance.toLowerCase().includes(formattedFilter)) {
                  return true;
                }  else if (this.isCanSearchBySomeFields && row.estimateNumber && row.estimateNumber.toLowerCase().includes(formattedFilter)) {
                  return true;
                } else if (row.make && row.make.toLowerCase().includes(formattedFilter)) {
                    return true;
                } else if (row.model && row.model.toLowerCase().includes(formattedFilter)) {
                    return true;
                }
                  // else if (row.colour && row.colour.toLowerCase().includes(formattedFilter)) {
                //     return true;
                // }
                // else if (row.created_on && row.created_on.includes(formattedFilter)) {
                //     return true;
                // } else if (row.assignedTo.name && row.assignedTo.name.toLowerCase().includes(formattedFilter)) {
                //     return true;
                // } else if (row.priority && row.priority.toLowerCase().includes(formattedFilter)) {
                //     return true;
                // } else if (row.status_name && row.status_name.toLowerCase().includes(formattedFilter)) {
                //     return true;
                // } else if (row.claim_number && row.claim_number.toLowerCase().includes(formattedFilter)) {
                //     return true;
                // } else if (row.filters && row.filters.includes(filter)) {
                //     return true;
                // } else if (row.partNumbers && row.partNumbers.toLowerCase().includes(formattedFilter)) {
                //     return true;
                // } else if (row.driverName && row.driverName.toLowerCase().includes(formattedFilter)) {
                //     return true;
                // }
                return false;
            },
            setUpdateData: function (v) {
                //let uid = Math.random().toString(36).substr(2, 9) + Date.now();
              this.updateData = {};
                this.updateData.uid = v;
                this.beforeDebouncedAutoSave();
            },
            setViewTable: function () {
                this.$storage.set("views.cards.defaultView", "table-view");
                this.currentViewActive = "table-view";
                this.setTitle()
            },
            setViewBoard: function () {
                this.$storage.set("views.cards.defaultView", "board-view");
                this.currentViewActive = "board-view";
                this.setTitle()
            },
            tableTabChanged(selectedTab) {
                let tableHash = selectedTab.tab.hash;
                if (tableHash === "#cards-all") {
                    this.activeTableHash = "#cards-all";
                    this.activeBoardHash = "#cards-all-board";
                } else if (tableHash === "#cards-open") {
                    this.activeTableHash = "#cards-open";
                    this.activeBoardHash = "#cards-open-board";
                } else if (tableHash === "#cards-active") {
                    this.activeTableHash = "#cards-active";
                    this.activeBoardHash = "#cards-active-board";
                } else if (tableHash === "#cards-closed") {
                    this.activeTableHash = "#cards-closed";
                    this.activeBoardHash = "#cards-closed-board";
                }
                this.$storage.set("cards.tabs.defaultCardsTable", selectedTab.tab.id);
            },
            boardTabChanged(selectedTab) {
                let boardHash = selectedTab.tab.hash;
                if (boardHash === "#cards-all-board") {
                    this.activeTableHash = "#cards-all";
                    this.activeBoardHash = "#cards-all-board";
                } else if (boardHash === "#cards-open-board") {
                    this.activeTableHash = "#cards-open";
                    this.activeBoardHash = "#cards-open-board";
                } else if (boardHash === "#cards-active-board") {
                    this.activeTableHash = "#cards-active";
                    this.activeBoardHash = "#cards-active-board";
                } else if (boardHash === "#cards-closed-board") {
                    this.activeTableHash = "#cards-closed";
                    this.activeBoardHash = "#cards-closed-board";
                }
                this.$storage.set("cards.tabs.defaultCardsBoard", selectedTab.tab.id);
            },
            setTableActive: function () {
                // console.log("table");
            },
            testClick: function (e) {
            },
            completeMarkAsActive: function () {
                this.$refs.markAsActiveModal.hide();
                let update = {};
                update.state = 1;
                this.setUpdateData(update);
                this.beforeDebouncedAutoSave();
                var self = this;
                setTimeout(function () {
                    self.card.state = 1;
                }, 500);
            },
            completeMarkAsOpen: function () {
                this.$refs.markAsOpenModal.hide();
                let update = {};
                update.state = 2;
                this.setUpdateData(update);
                this.beforeDebouncedAutoSave();
                var self = this;
                setTimeout(function () {
                    self.card.state = 2;
                }, 500);
            },
            completeMarkAsClosed: function () {
                this.$refs.markAsClosedModal.hide();
                let update = {};
                update.state = 3;
                this.setUpdateData(update);
                this.beforeDebouncedAutoSave();
                var self = this;
                setTimeout(function () {
                    self.card.state = 3;
                }, 500);
            },
            markAsActive: function () {
                if (!this.isStatusActive) {
                  return
                }
                if (this.card.estimate_number != "") {
                    this.$refs.markAsActiveModal.show();
                } else {
                    this.completeMarkAsActive();
                }
            },
            markAsOpen: function () {
                if (!this.isStatusActive) {
                  return
                }
                if (this.card.estimate_number != "") {
                    this.$refs.markAsOpenModal.show();
                } else {
                    this.completeMarkAsOpen();
                }
            },
            markAsClosed: function () {
                if (!this.isStatusActive) {
                  return
                }
                if (this.card.estimate_number != "") {
                    this.$refs.markAsClosedModal.show();
                } else {
                    this.completeMarkAsClosed();
                }
            },

            markAsRead: function (cardId) {
                this.closeTableContext();
                this.$store.dispatch('markAsReadCardNotification', cardId);
                let item = {};
                item = _.find(this.cards, {'card_id': cardId});
                if (item && item.activity) {
                    item.activity.Assignment = {};
                    item.activity.Email = {};
                    if (item.filters) {
                        item.filters = '';
                    }
                }

                item = _.find(this.cardsAll, {'card_id': cardId});
                if (item && item.activity) {
                    item.activity.Assignment = {};
                    item.activity.Email = {};
                    if (item.filters) {
                        item.filters = '';
                    }
                }

                item = _.find(this.cardsOpen, {'card_id': cardId});
                if (item && item.activity) {
                    item.activity.Assignment = {};
                    item.activity.Email = {};
                    if (item.filters) {
                        item.filters = '';
                    }
                }

                item = _.find(this.cardsActive, {'card_id': cardId});
                if (item && item.activity) {
                    item.activity.Assignment = {};
                    item.activity.Email = {};
                    if (item.filters) {
                        item.filters = '';
                    }
                }

                item = _.find(this.cardsClosed, {'card_id': cardId});
                if (item && item.activity) {
                    item.activity.Assignment = {};
                    item.activity.Email = {};
                    if (item.filters) {
                        item.filters = '';
                    }
                }
            },
            closeTableContext() {
              this.$nextTick(()=>{
             //   console.log('nextTick.closeTableContext')
                if (this.$refs.rightClickMenuForCards) {
               //   console.log('closeTableContext2 close')
                  this.$refs.rightClickMenuForCards.close();
                }
              })
            },
            onRightClickByMenu(type, data) {
                this.closeTableContext();
                if (!this.isStatusActive) {
                  return
                }
                var card_id = data.item.card_id;
                Axios.get(`/fe/card/${card_id}`).then((response) => {
                    var card = response.data.card;
                    if (card && card.estimate_number != "") {
                        if (type == 1) {
                            this.modal = {
                                type: type,
                                card_id: card_id,
                                title: "Card - Mark As Active",
                                text:
                                    "We have found the following Estimates linked to this card. These will be marked as Active.",
                                estimate_number: card.estimate_number,
                                supplement_numbers: card.supplement_numbers,
                            };
                        } else if (type == 0) {
                            this.modal = {
                                type: type,
                                card_id: card_id,
                                title: "Card - Mark As Open",
                                text:
                                    "We have found the following Estimates linked to this card. These will be marked as Open.",
                                estimate_number: card.estimate_number,
                                supplement_numbers: card.supplement_numbers,
                            };
                        } else if (type == 3) {
                            this.modal = {
                                type: type,
                                card_id: card_id,
                                title: "Card - Mark As Closed",
                                text:
                                    "We have found the following Estimates linked to this card. These will be marked as Closed.",
                                estimate_number: card.estimate_number,
                                supplement_numbers: card.supplement_numbers,
                            };
                        }
                        this.$refs.markAsModal.show();
                    } else {
                        this.modal = {
                            type: type,
                            card_id: card_id,
                        };
                        this.completeMarkAs();
                    }
                });
            },
            completeMarkAs: function () {
                this.$refs.markAsModal.hide();
                let update = {};
                update.state = this.modal.type;
                this.setUpdateData(update);
                var card_id = this.modal.card_id;
                Axios({
                    method: "post",
                    responseType: "json",
                    headers: {Autosave: true},
                    url: `/fe/card/${card_id}`,
                    validateStatus: function (status) {
                        return status < 500;
                    },
                    data: this.updateData,
                }).then((response) => {
                    if (response.status === 200) {
                        this.loadCards(1);
                    }
                });
            },
            loadCards: function (pageNumber) {
                this.setData();
                //   NProgress.start();
                this.actionLoadCards()
                    .then((t) => {
                        this.setData();
                        this.$store.dispatch('card/loadHiddenStatuses');
                        this.loadCEStatuses();

                        //   NProgress.done();

                    })
                    .catch((error) => {
                        console.log(error);
                        //  NProgress.done();
                    });
            },
            setData() {
                // this.cardsAll = this.getterAllCards;
                this.cardsOpen = this.getterOpenCards;
                this.cardsClosed = this.getterClosedCards;
                this.cardsActive = this.getterActiveCards;

                this.cardsAllFiltered = this.cardsAll;
                this.cardsActiveFiltered = this.getterActiveCards;
                this.cardsOpenFiltered = this.getterOpenCards;
                this.cardsClosedFiltered = this.getterClosedCards;

                this.filteredCardsAllRowsCountValue = this.cardsAll.length;
                this.filteredCardsActiveRowsCountValue = this.cardsActive.length;
                this.filteredCardsOpenRowsCountValue = this.cardsOpen.length;
                this.filteredCardsClosedRowsCountValue = this.cardsClosed.length;

            },
            paginationClick(pageNumber) {
                this.loadCards(pageNumber);
            },
            navigateToCardPage(card_id) {
              this.$router.push({
                name: 'CardsEdit',
                params: {card_id: card_id,
                filters: this.$route.params.filters ? this.$route.params.filters : this.$route.query.filters,
                trackerFilter: this.$route.params.trackerFilter ? this.$route.params.trackerFilter : this.$route.query.trackerFilter
                }});
            },
            onAllCardsFiltered: function (fi) {
                this.cardsAllFiltered = fi;
                this.setCardsAllCurrentPage(1);
                this.filteredCardsAllRowsCountValue = fi.length;
            },
            onActiveCardsFiltered: function (fi) {
                this.cardsActiveFiltered = fi;
                this.setCardsActiveCurrentPage(1);
                this.filteredCardsActiveRowsCountValue = fi.length;
            },
            onOpenCardsFiltered: function (fi) {
                this.cardsOpenFiltered = fi;
                this.setCardsOpenCurrentPage(1);
                this.filteredCardsOpenRowsCountValue = fi.length;
            },
            onClosedCardsFiltered: function (fi) {
                this.cardsClosedFiltered = fi;
                this.setCardsClosedCurrentPage(1);
                this.filteredCardsClosedRowsCountValue = fi.length;
            },
            onCardsRowClick: function (item, index, event) {
              this.$router.push({
                name: 'CardsEdit',
                params: {
                  card_id: item.card_id,
                  filters: this.$route.params.filters ? this.$route.params.filters : this.$route.query.filters,
                  trackerFilter: this.$route.params.trackerFilter ? this.$route.params.trackerFilter : this.$route.query.trackerFilter
                }});
            },
            openMenuCards2: function (item, index, event) {
                event.preventDefault();
                let row = event.target.parentElement;
                let rows = row.parentElement.rows;
                for (let i = 0; i < rows.length; i++) {
                    let elem = rows[i];
                    elem.style.backgroundColor = "";
                }
                row.style.backgroundColor = "#F3F3F3";
                this.openMenuCards(event, index, item);
            },
            contextOpen: function (event, w, e) {
                this.contextOpenEvent = event;
            },
            contextClose: function () {
                let event = this.contextOpenEvent;
                if (event) {
                    let row = event.target.parentElement;
                    let rows = row.parentElement.rows;
                    for (let i = 0; i < rows.length; i++) {
                        let elem = rows[i];
                        elem.style.backgroundColor = "";
                    }
                }
            },
            openMenuCards: function (event, index, item) {
                if (!this.isStatusActive) {
                  return
                }
                let vm = this;
                this.$refs.rightClickMenuForCards.open(event, {
                    item: item,
                    index: index,
                });
                setTimeout(() => {
                    [].slice
                        .call(this.$refs.contextMenuForCards.children)
                        .forEach((elem) => {
                            if (elem.children.length && elem.querySelector(".subContext")) {
                                elem.classList.add("hasSubContext");
                                elem.addEventListener("click", (e) => {
                                    e.stopPropagation();
                                });
                                elem.addEventListener("mouseenter", () => {
                                    if (
                                        window.innerWidth <
                                        elem.getBoundingClientRect().right +
                                        elem.querySelector(".subContext").getBoundingClientRect()
                                            .width
                                    ) {
                                        elem
                                            .querySelector(".subContext")
                                            .classList.add("position_sub_context_left");
                                    } else
                                        elem
                                            .querySelector(".subContext")
                                            .classList.remove("position_sub_context_left");
                                });
                            } else elem.classList.remove("hasSubContext");
                        });
                }, 0);
            },
            openNewBrowserCard() {
                window.open(
                    window.location + "/view/" + this.openedNewTabCardId,
                    "_blank",
                    "toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,width=" +
                    screen.width +
                    ",height=" +
                    screen.height
                );
                this.closeTableContext();
            },
            openNewTabCard() {
              //  console.log(this.openedNewTabCardId);
                window.open(
                    window.location + "/view/" + this.openedNewTabCardId,
                    "_blank"
                );
                this.closeTableContext();
            },
            assignedToPersonOnTable(data, user) {
                this.assignedToPerson(data, user);
                this.$refs.rightClickMenuForCards.close();
            },
            assignedToPersonOnBoard(data, user) {
                this.assignedToPerson(data, user);
                //this.$refs.rightClickMenuForCards.close();
            },
            assignedToPerson(data, user) {
             //  console.log("data", data);
              //  console.log("user", user);
                if (data && data.item && data.item.card_id > 0) {
                    let card_id = data.item.card_id;
                    let uid = Math.random().toString(36).substr(2, 9) + Date.now();
                    let update = {};
                    update[uid] = {};
                    update[uid].assignedTo = {};
                    update[uid].assignedTo.id = user;
                    Axios({
                        method: "post",
                        responseType: "json",
                        headers: {Autosave: true},
                        url: `/fe/card/${card_id}`,
                        validateStatus: function (status) {
                            return status < 500;
                        },
                        data: update,
                    })
                        .then((response) => {
                            if (response.status === 200) {
                                this.loadCards(1);
                            }
                        })
                        .catch((error) => {
                        });
                }
            },
            onAllCardsPageChange: function () {
            },
            onActiveCardsPageChange: function () {
            },
            onOpenCardsPageChange: function () {
            },
            onClosedCardsPageChange: function () {
            },
            // Change view status for all external activities
            markExternalAsRead(cardItem) {
              //  console.log("cardItem", cardItem);
                // this.$store.dispatch('updateActivityViewedStatus', {activity_id: null, card_id: null, viewed: null, activity_object: cardItem}).then((updatedActivies) => {
                //     console.log('updatedActivies', updatedActivies);
                // });
            },

            handleChange() {
            },
            inputChanged(value) {
                this.activeNames = value;
            },
            getComponentData() {
                return {
                    on: {
                        change: this.handleChange,
                        input: this.inputChanged,
                    },
                    props: {
                        value: this.activeNames,
                    },
                };
            },
            sortLayout(e) {
                let el = e.target;
                let divHolder = el.closest(".board-layout-col");
                divHolder.classList.toggle("double-cols");
            },

            openMenuBoardResult: function (e, title, cardId, cardNumber) {
                if (!this.isStatusActive || this.isDisplayBoardUser) {
                  return
                }
                this.openedNewTabCardId = cardId;
                this.$refs.rightClickMenuForBoardResult.open(e, {item: title, cardId: cardId, cardNumber: cardNumber});
            },
            updateCardProgressBar() {
                this.card.statuses = [];
                let currentStatusIndex = 0;
                this.ceStatuses.forEach((ce_status, i) => {
                    if (ce_status.ce_status_id.toString() === this.card.card_status) {
                        currentStatusIndex = i;
                    }
                });
                this.cardProgress.startIndex = currentStatusIndex;
                this.ceStatuses.forEach((ce_status, i) => {
                    let status = {
                        id: ce_status.ce_status_id,
                        title: ce_status.name,
                    };
                    if (currentStatusIndex >= i) {
                        status.icon = "ti-check";
                    } else {
                        status.icon = " ";
                    }
                    this.card.statuses.push(status);
                });
            },
            updateStatusForBoardView() {
              //  console.log('updateBoardView');
            },
            loadCEStatuses() {
                this.$store.dispatch("loadCEStatuses")
                    .then((res) => {
                        if (res.status === 200) {
                            this.ceStatuses = res.data;
                            _.forEach(res.data, (s) => {
                              this.ceStatusesLimits['All'].push({
                                status: s,
                                limit: 50
                              })
                              this.ceStatusesLimits['Open'].push({
                                status: s,
                                limit: 50
                              })
                              this.ceStatusesLimits['Active'].push({
                                status: s,
                                limit: 50
                              })
                              this.ceStatusesLimits['Closed'].push({
                                status: s,
                                limit: 50
                              })
                            })
                            //Replace for statusPosition function
                            //this.cardsAll.map((e) => {
                            //    // console.log("card_id", e.card_id);
                            //    // console.log('value.ce_status_id',value.ce_status_id)
                            //    // console.log("e.card_status", e.card_status);
                            //    let eceStatus =
                            //        this.ceStatuses.findIndex(
                            //            (value) => value.ce_status_id === e.card_status
                            //        ) + 1;
                            //    // console.log("eceStatus", eceStatus);
                            //    e.ceStatus = eceStatus;
                            //    e.ceStatusPercentage = (eceStatus / this.ceStatuses.length) * 100;
                            //});
                            this.updateCardProgressBar();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            getRef() {
                console.log('getRef.CALL');
                return;
            },
            isDraggedAll(v, ref) {

              let evt = v.evt
              let id = Number(evt.item.getAttribute("data-itemArray"))
                let card_info = this.cardsAll.filter(
                    (e) => Number(e.card_id) === id
                );

                //console.log('card_info', card_info);
                // card_info[0].card_status = evt.to.getAttribute("data-card-status-id");
                // card_info[0].status_name = evt.to.getAttribute("data-status-name");

              this.$store.commit('card/changeCardStatusId', {
                cardId: card_info[0].card_id,
                cardStatus: evt.to.getAttribute("data-card-status-id"),
                statusName: evt.to.getAttribute("data-status-name"),
              })

                if (evt.to.getAttribute("data-card-status-id") == evt.from.getAttribute("data-card-status-id")) {
                  _.forEach(v.ids, (i, ind) => {
                    let card = _.find(this.cardsAll, (c) => {
                      return Number(c.card_id) == Number(i)
                    })
                    if (card) {
                      this.$store.commit('card/changeCardBoardSort', {
                        cardId: card.card_id,
                        boardSort: ind,
                      })
                    }
                  })
                  this.autoUpdateCardBoardSorting(v.ids, evt.to.getAttribute("data-card-status-id"), id)
                } else {
                  _.forEach(v.ids, (i, ind) => {
                    let card = _.find(this.cardsAll, (c) => {
                      return Number(c.card_id) == Number(i)
                    })
                    if (card) {
                      this.$store.commit('card/changeCardBoardSort', {
                        cardId: card.card_id,
                        boardSort: ind,
                      })
                    }
                  })

                  _.forEach(this.$refs?.[ref]?.getIds(evt.to.getAttribute("data-card-status-id")), (i, ind) => {
                    let card = _.find(this.cardsAll, (c) => {
                      return Number(c.card_id) == Number(i)
                    })
                    if (card) {
                      this.$store.commit('card/changeCardBoardSort', {
                        cardId: card.card_id,
                        boardSort: ind,
                      })
                    }
                  })
                  // this.autoUpdateCardStatus(card_info);
                  this.autoUpdateCardBoardSorting(this.$refs?.[ref]?.getIds(evt.to.getAttribute("data-card-status-id")), evt.to.getAttribute("data-card-status-id"), id )
                }
                this.updateStatusForBoardView();
                //  this.getRef();

            },
            isDraggedOpen(evt) {
                let card_info = this.cardsOpen.filter(
                    (e) => Number(e.card_id) === Number(evt.item.getAttribute("data-itemArray"))
                );
                card_info[0].card_status = evt.to.getAttribute("data-card-status-id");
                card_info[0].status_name = evt.to.getAttribute("data-status-name");
                this.updateStatusForBoardView();
                // this.getRef();
                this.autoUpdateCardStatus(card_info);
            },
            isDraggedActive(evt) {
                let card_info = this.cardsActive.filter(
                    (e) => Number(e.card_id) === Number(evt.item.getAttribute("data-itemArray"))
                );
                card_info[0].card_status = evt.to.getAttribute("data-card-status-id");
                card_info[0].status_name = evt.to.getAttribute("data-status-name");
                this.updateStatusForBoardView();
                // this.getRef();
                this.autoUpdateCardStatus(card_info);
            },
            isDraggedClosed(evt) {
                let card_info = this.cardsClosed.filter(
                    (e) => Number(e.card_id) === Number(evt.item.getAttribute("data-itemArray"))
                );
                card_info[0].card_status = evt.to.getAttribute("data-card-status-id");
                card_info[0].status_name = evt.to.getAttribute("data-status-name");
                this.updateStatusForBoardView();
                //  this.getRef();
                this.autoUpdateCardStatus(card_info);
            },
            autoUpdateCardBoardSorting(cardIds, statusId, cardId) {
               Axios.post('/fe/cards/updates', {
                 boardSort: cardIds,
                 status:{
                   statusId: statusId,
                   cardId: cardId
                 }
               })
              .finally(() => {
                this.debounceChangeUpdateStatus()
              })
            },
          changeUpdateStatus() {
            this.$store.commit('changeUpdateStatus', true)
          },
          autoUpdateCardStatus(card_data) {
                //let uid = Math.random().toString(36).substr(2, 9) + Date.now();
                if (!this.updateData.uid) {
                    this.updateData.uid = {};
                }
                this.updateData.uid.card_status = card_data[0].card_status;
                this.card_drag_id = card_data[0].card_id;
                this.isAutoSave = true;
                this.beforeDebouncedAutoSave();

                // this.updateCardProgressBar();
            },
            beforeDebouncedAutoSave: function () {
                if (!this.isAutoSave) {
              //    console.log('not isAutoSave in beforeDebouncedAutoSave')
                    return;
                }
                this.debouncedAutoSave();
            },
            autoSave() {
                if (!this.isAutoSave) {
                //  console.log('not isAutoSave')
                    return;
                }
                let card_id = this.$route.params.card_id || this.card_drag_id;
                Axios({
                    method: "post",
                    responseType: "json",
                    headers: {Autosave: true},
                    url: `/fe/card/${card_id}`,
                    validateStatus: function (status) {
                        return status < 500;
                    },
                    data: this.updateData,
                })
                    .then((response) => {
                        if (response.status === 200) {
                        } else {
                        }
                        this.isAutoSave = true;
                    })
                    .catch((error) => {
                        this.isAutoSave = true;
                    });
            },
            boardSingleSearchFun(e, key) {
                return;
            },
            setCardPriority(cardId, priority) {
              // console.log('setCardPriority', cardId, priority )
                this.closeTableContext();
                const index = this.cardsAll.findIndex(card => {
                    return Number(card.card_id) === Number(cardId);
                });
                if (index !== -1) {
                    const card = this.cardsAll[index];
                    //card.priority = {};
                    //card.priority_class = _.toLower(priority);
                    //let words = priority.split('');
                    //words[0] = words[0].toUpperCase();
                    //words = words.join('');
                    card.priority = priority;

                  let update = {};
                  update.priority = priority;
                  this.isAutoSave = true;
                  this.card_drag_id = cardId;
                  this.setUpdateData(update);
                }else{
                  console.log('Not Found CARD in setPriority')
                }
            },
            setCardAssignee({cardId, assignee}) {
                const {id, name} = assignee;

                const index = this.cardsAll.findIndex(card => {
                    return card.card_id === cardId;
                });

                if (index !== -1) {
                    const card = this.cardsAll[index];
                    if (assignee.id === -1) {
                        card.assignedTo = null;
                    } else {
                        card.assignedTo = assignee;
                    }
                }
            },
            deleteCard(cardId) {
                NProgress.start();
                const index = this.cardsAll.findIndex(card => {
                    return card.card_id === cardId;
                });
                if (index !== -1) {
                    //
                    let update = {};
                    let self = this;
                    let uid = Math.random().toString(36).substr(2, 9) + Date.now();
                    update[uid] = {};
                    update[uid].delete = cardId;
                    Axios({
                        method: "post",
                        responseType: "json",
                        headers: {Autosave: true},
                        url: `/fe/card/${cardId}`,
                        validateStatus: function (status) {
                            return status < 500;
                        },
                        data: update,
                    })
                        .then((response) => {
                            self.cardsAll.splice(index, 1);
                            self.updateStatusForBoardView();
                            NProgress.done();
                        })
                        .catch((error) => {
                        });
                }
                NProgress.done();
            },
            toggleEmailNotification(cardId) {
                const index = this.cardsAll.findIndex(card => {
                    return card.card_id === cardId;
                });
                // console.log(cardId, index)
                if (index !== -1) {
                    if (this.cardsAll[index].email_notification) {
                        this.cardsAll[index].email_notification = !this.cardsAll[index].email_notification;
                    } else {
                        this.cardsAll[index].email_notification = true;
                    }
                }
            }
        },
        created() {
          this.loadCategories()
            this.debouncedAutoSave = _.debounce(this.autoSave, 1000);
            this.debounceChangeUpdateStatus = _.debounce(this.changeUpdateStatus, 1000);
            this.$storage.setOptions({
                prefix: "repairshop_",
                driver: "local",
                ttl: 30 * 60 * 60 * 24 * 1000, // 30 days
            });
            this.order_status = this.$refs;
            // console.log("created", this.order_status);
          if (this.$route.query.trackerFilter) {
            this.$store.commit('setCardFilter', this.$route.query.trackerFilter);
          }
        },
        mounted() {
            if (this.$route.params && this.$route.params.filter) {
                this.filter = this.$route.params.filter;
            }

          this.loadCards(1);
            this.order_status = this.$refs.order_status;
            this.activeTableHash = "";
            if (
                this.$storage.get("views.cards.defaultView") &&
                this.$storage.get("views.cards.defaultView") != ""
            ) {
                this.currentViewActive = this.$storage.get("views.cards.defaultView");
                this.setTitle()
            }

            //console.log("mounted", this.order_status);
            let self = this;
            this.intervalUpdateCards = setInterval(() => {
                // if (true || self.cardsAll.length !== self.getterAllCards.length) {

                   // self.cardsAll = self.getterAllCards;
                    self.filteredCardsAllRowsCountValue = self.cardsAll.length;
              let filters = self.$route.params.filters ? self.$route.params.filters : self.$route.query.filters;
              let trackerFilter = self.$route.params.trackerFilter ? self.$route.params.trackerFilter : self.$route.query.trackerFilter
              if (self.isTableViewActive && trackerFilter && self.filter == trackerFilter && !_.isEmpty(filters) && self.cardsAllFiltered.length !== filters?.length) {
                this.$refs.cardsAll.refresh()
              }
                    //        console.log('set1');
                // }
                if (true || self.cardsOpen.length !== self.getterOpenCards.length) {
                    self.cardsOpen = self.getterOpenCards;
                    self.filteredCardsOpenRowsCountValue = self.cardsOpen.length;
                    // console.log('set2');
                }
                if (true || self.cardsClosed.length !== self.getterClosedCards.length) {
                    self.cardsClosed = self.getterClosedCards;
                    self.filteredCardsClosedRowsCountValue = self.cardsClosed.length;
                    //   console.log('set3');
                }
                if (true || self.cardsActive.length !== self.getterActiveCards.length) {
                    self.cardsActive = self.getterActiveCards;
                    self.filteredCardsActiveRowsCountValue = self.cardsActive.length;
                    //   console.log('set4');
                }
            }, 3000);

          if (this.$route.params && !_.isEmpty(this.$route.params.filters)) {
            setTimeout(() => {
              this.$nextTick(() => {
                this.filtersFromRoute = this.$route.params.filters;
              })
            }, 0)
          }
          if (this.$route.query && !_.isEmpty(this.$route.query.filters)) {
            setTimeout(() => {
              this.$nextTick(() => {
                this.filtersFromRoute = _.isArray(this.$route.query.filters) ? this.$route.query.filters : [this.$route.query.filters];
              })
            }, 0)
          }
          this.setTitle()

          if (!this.isCanSearchBySomeFields && this.getterTokens.length == 8) {
            this.$store.commit('card/setTokensNew',
                    {
                  0: [],
                  1: [],
                  2: [],
                  3: [],
                  4: [],
                  5: [],
                }
                )
          }
        },
        destroyed: function () {

        },
      beforeRouteLeave(to, from, next) {
        if (this.filter == this.$route.params.trackerFilter || this.filter == this.$route.query.trackerFilter) {
          this.$store.commit('setCardFilter', '');
        }
        if (this.filter == this.$route.params.filter) {
          this.$store.commit('setCardFilter', '');
        }
        next();
      },
        beforeDestroy() {
            clearInterval(this.intervalUpdateCards);
          this.setTitle(true)
        },
      watch: {
        filter: {
          handler () {
            this.countOfFilterChange += 1
          },
          immediate: true
        },
      },
        components: {
            Paginate,
            VueContext,
            draggable,
            Vue2Storage,
            NotificationCell,
            CardActions,
            NumberFormatter,
            Multiselect,
            BlockPagination,
            CardsBoard,
            SearchFilter2
        },
    };
</script>

<template>
    <div class="ex-cards-view cards-view cards-page">
        <div class="page-header">
            <h4>View Cards</h4>
            <ol class="breadcrumbs">
                <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                </li>
                <li class="breadcrumb-item active">View Cards</li>
            </ol>
            <h4 v-if="isShowBusinessName" class="header-display-name">{{ getterBusinessName }}</h4>
            <div
                    v-if="(isShopManagerUser || isProductionManagerUser || isEstimatorUser || isPDRTechnicianUser || isCustomerUser) && isStatusActive"
                    class="page-header-nav-btn">
                <router-link to="/cards/new" class="btn btn-primary">
                    <i class="ti-plus"></i> New Card
                </router-link>
            </div>
        </div>
        <div class="box-block bg-white cards" id="table-view" v-if="isTableViewActive">
            <tabs
                    ref="cards-table"
                    cache-lifetime="0"
                    @changed="tableTabChanged"
                    :options="{useUrlFragment: false, defaultTabHash: computedDefaultCardTableTabHash }"
            >
                <tab name="All" id="cards-all" :class-name="'tab-content-table'">
                    <div style="overflow-x: auto;" @contextmenu.prevent="testClick($event)" class="pagination-block-bottom">
                        <b-table
                                class="rs-scroll rs-scroll--y noselect"
                                responsive
                                hover
                                :items="computedCardsAllForTable"
                                ref="cardsAll"
                                :fields="fields"
                                :filter="filter"
                                :filter-function="filterForTable"
                                :sort-by="computedSortByForCardsAll"
                                :sort-desc="computedSortDescForCardsAll"
                                @sort-changed="setSortByAndSortDescForCardsAll"
                                :current-page="cardsAllCurrentPage"
                                :per-page="cardsAllPerPage"
                                @filtered="onAllCardsFiltered"
                                @row-clicked="onCardsRowClick"
                                @row-contextmenu="openMenuCards2"
                        >
                            <template v-slot:cell(insurance)="data">
                              <div v-if="data.item.insurance" :style="getInsurerNameStyle(data.item.insurerCardColorId)">
                                {{ data.item.insurance }}
                              </div>
                            </template>
                            <template v-slot:cell(assignedTo)="data">
                                <div>
                                    {{ data.item.assignedTo && data.item.assignedTo.id ? data.item.assignedTo.name : '&nbsp' }}
                                </div>
                            </template>
                            <template v-slot:cell(notifications)="data">
                                <notification-cell v-if="activitiesGetted" class="row no-gutters text-center" :item="data.item" :name="`All-${data.item.card_id}`"/>
                            </template>
                            <template v-slot:cell(priority)="data">
                                <span v-if="data.item.priority === 'High'" style="color: #FF5E5E">
                                  High
                                </span>
                                <span v-else-if="data.item.priority === 'Medium'" style="color: #FFDB00">
                                  Medium
                                </span>
                                <span v-else-if="data.item.priority === 'Low'" style="color: #18D06B">
                                  Low
                                </span>
                                <span v-else-if="data.item.priority === 'OnHold'" style="color: #419abe">
                                  On Hold
                                </span>

                            </template>
                            <template v-slot:cell(status_name)="data">
                                <p
                                        style="margin-bottom:3px;font-weight:bold;padding-left:20px;"
                                >{{ data.item.status_name }}</p>
                                <p style="margin-bottom:3px;font-size:11px;padding-left:20px;">
                                    <!--                                    <span>{{ data.item.ceStatus }} to {{ ceStatuses.length }}</span>-->
                                    <span>{{ statusPosition(data.item) }} to {{ ceStatuses.length }}</span>
                                </p>
                                <div class="progress">
                                    <!--                                    <div class="bar" :style="{'width':`${data.item.ceStatusPercentage}%`}"></div>-->
                                    <div class="bar" :style="{'width':`${statusPositionByPercentage(data.item)}%`}"></div>
                                </div>
                            </template>
                        </b-table>
                    </div>
                    <block-pagination
                            :role="'cards'"
                            :arrayOfSomeone="cardsAll"
                            :arrayOfSomeoneFiltered="filter ? cardsAllFiltered2 : computedCardsAllForTable"
                            :currentPage="cardsAllCurrentPage"
                            @changedCurrentPage="value => setCardsAllCurrentPage(value)"
                            :perPage="cardsAllPerPage"
                            :perPageForMultipleTables="computedPerPage"
                            @changedPerPage="value => setCardsAllPerPage(value)"
                    >
                    </block-pagination>
                </tab>
                <tab name="Open" id="cards-open" :class-name="'tab-content-table'">
                    <div style="overflow-x: auto;" @contextmenu.prevent="testClick($event)" class="pagination-block-bottom">
                        <b-table
                                class="rs-scroll rs-scroll--y noselect"
                                responsive
                                hover
                                :items="computedCardsOpenForTable"
                                :fields="fields"
                                :filter="filter"
                                :sort-by="computedSortByForCardsOpen"
                                :sort-desc="computedSortDescForCardsOpen"
                                @sort-changed="setSortByAndSortDescForCardsOpen"
                                :filter-function="filterForTable"
                                :current-page="cardsOpenCurrentPage"
                                :per-page="cardsAllPerPage"
                                @filtered="onOpenCardsFiltered"
                                @row-clicked="onCardsRowClick"
                                @row-contextmenu="openMenuCards2"
                        >
                            <template v-slot:cell(insurance)="data">
                              <div v-if="data.item.insurance" :style="getInsurerNameStyle(data.item.insurerCardColorId)">
                                {{ data.item.insurance }}
                              </div>
                            </template>
                            <template v-slot:cell(assignedTo)="data">
                                <div>
                                    {{ data.item.assignedTo && data.item.assignedTo.id ? data.item.assignedTo.name : '&nbsp' }}
                                </div>
                            </template>
                            <template v-slot:cell(notifications)="data">
                                <notification-cell v-if="activitiesGetted" class="row no-gutters text-center" :item="data.item" :name="`Open-${data.item.card_id}`"/>
                            </template>
                            <template v-slot:cell(priority)="data">
                                  <span v-if="data.item.priority === 'High'" style="color: #FF5E5E">
                                    High
                                  </span>
                              <span v-else-if="data.item.priority === 'Medium'" style="color: #FFDB00">
                                    Medium
                                  </span>
                              <span v-else-if="data.item.priority === 'Low'" style="color: #18D06B">
                                    Low
                                  </span>
                              <span v-else-if="data.item.priority === 'OnHold'" style="color: #419abe">
                                    On Hold
                                  </span>

                            </template>
                            <template v-slot:cell(status_name)="data">
                                <p
                                        style="margin-bottom:3px;font-weight:bold;padding-left:20px;"
                                >{{ data.item.status_name }}</p>
                                <p style="margin-bottom:3px;font-size:11px;padding-left:20px;">
                                    <span>{{ statusPosition(data.item) }} to {{ ceStatuses.length }}</span>
                                </p>
                                <div class="progress">
                                    <div class="bar" :style="{'width':`${statusPositionByPercentage(data.item)}%`}"></div>
                                </div>
                            </template>
                        </b-table>
                    </div>
                    <block-pagination
                            :role="'cards'"
                            :arrayOfSomeone="cardsOpen"
                            :arrayOfSomeoneFiltered="filter ? cardsOpenFiltered2 : computedCardsOpenForTable"
                            :currentPage="cardsOpenCurrentPage"
                            @changedCurrentPage="value => setCardsOpenCurrentPage(value)"
                            :perPage="cardsAllPerPage"
                            :perPageForMultipleTables="computedPerPage"
                            @changedPerPage="value =>setCardsAllPerPage(value)"
                    >
                    </block-pagination>
                    <!-- <data-tables id="cards-table" :tableColumns="columns" :tableData="cardsOpen" :tableOrder="[]" @onRowClick="navigateToCardPage" class="table table-striped table-bordered dataTable"></data-tables> -->
                </tab>
                <tab name="Active" id="cards-active" :class-name="'tab-content-table'">
                    <div style="overflow-x: auto;" class="pagination-block-bottom">
                        <b-table
                                class="rs-scroll rs-scroll--y noselect"
                                responsive
                                hover
                                :items="computedCardsActiveForTable"
                                :fields="fields"
                                :filter="filter"
                                :filter-function="filterForTable"
                                :sort-by="computedSortByForCardsActive"
                                :sort-desc="computedSortDescForCardsActive"
                                @sort-changed="setSortByAndSortDescForCardsActive"
                                :current-page="cardsActiveCurrentPage"
                                :per-page="cardsAllPerPage"
                                @filtered="onActiveCardsFiltered"
                                @row-clicked="onCardsRowClick"
                                @row-contextmenu="openMenuCards2"
                        >
                            <template v-slot:cell(insurance)="data">
                              <div v-if="data.item.insurance" :style="getInsurerNameStyle(data.item.insurerCardColorId)">
                                {{ data.item.insurance }}
                              </div>
                            </template>
                            <template v-slot:cell(assignedTo)="data">
                                <div>
                                    {{ data.item.assignedTo && data.item.assignedTo.id ? data.item.assignedTo.name : '&nbsp' }}
                                </div>
                            </template>
                            <template v-slot:cell(notifications)="data">
                                <notification-cell v-if="activitiesGetted" class="row no-gutters text-center" :item="data.item" :name="`Active-${data.item.card_id}`"/>
                            </template>
                            <template v-slot:cell(priority)="data">
                                  <span v-if="data.item.priority === 'High'" style="color: #FF5E5E">
                                    High
                                  </span>
                              <span v-else-if="data.item.priority === 'Medium'" style="color: #FFDB00">
                                    Medium
                                  </span>
                              <span v-else-if="data.item.priority === 'Low'" style="color: #18D06B">
                                    Low
                                  </span>
                              <span v-else-if="data.item.priority === 'OnHold'" style="color: #419abe">
                                    On Hold
                                  </span>

                            </template>
                            <template v-slot:cell(status_name)="data">
                                <p
                                        style="margin-bottom:3px;font-weight:bold;padding-left:20px;"
                                >{{ data.item.status_name }}</p>
                                <p style="margin-bottom:3px;font-size:11px;padding-left:20px;">
                                    <span>{{ statusPosition(data.item) }} to {{ ceStatuses.length }}</span>
                                </p>
                                <div class="progress">
                                    <div class="bar" :style="{'width':`${statusPositionByPercentage(data.item)}%`}"></div>
                                </div>
                            </template>
                        </b-table>
                    </div>
                    <block-pagination
                            :role="'cards'"
                            :arrayOfSomeone="cardsActive"
                            :arrayOfSomeoneFiltered="filter ? cardsActiveFiltered2 : computedCardsActiveForTable"
                            :currentPage="cardsActiveCurrentPage"
                            @changedCurrentPage="value => setCardsActiveCurrentPage(value)"
                            :perPage="cardsAllPerPage"
                            :perPageForMultipleTables="computedPerPage"
                            @changedPerPage="value => setCardsAllPerPage(value)"
                    >
                    </block-pagination>
                    <!-- <data-tables id="cards-table" :tableColumns="columns" :tableData="cardsActive" :tableOrder="[]" @onRowClick="navigateToCardPage" class="table table-striped table-bordered dataTable"></data-tables> -->
                </tab>
                <tab name="Closed" id="cards-closed" :class-name="'tab-content-table'">
                    <div class="pagination-block-bottom">
                        <b-table
                                class="rs-scroll rs-scroll--y noselect"
                                responsive
                                hover
                                :items="computedCardsClosedForTable"
                                :fields="fields"
                                :filter="filter"
                                :filter-function="filterForTable"
                                :sort-by="computedSortByForCardsClosed"
                                :sort-desc="computedSortDescForCardsClosed"
                                @sort-changed="setSortByAndSortDescForCardsClosed"
                                :current-page="cardsClosedCurrentPage"
                                :per-page="cardsAllPerPage"
                                @filtered="onClosedCardsFiltered"
                                @row-clicked="onCardsRowClick"
                                @row-contextmenu="openMenuCards2"
                        >
                            <template v-slot:cell(insurance)="data">
                              <div v-if="data.item.insurance" :style="getInsurerNameStyle(data.item.insurerCardColorId)">
                                {{ data.item.insurance }}
                              </div>
                            </template>
                            <template v-slot:cell(assignedTo)="data">
                                <div>
                                    {{ data.item.assignedTo && data.item.assignedTo.id ? data.item.assignedTo.name : '&nbsp' }}
                                </div>
                            </template>
                            <template v-slot:cell(notifications)="data">
                                <notification-cell v-if="activitiesGetted" class="row no-gutters text-center" :item="data.item" :name="`Closed-${data.item.card_id}`"/>
                            </template>
                            <template v-slot:cell(priority)="data">
                                  <span v-if="data.item.priority === 'High'" style="color: #FF5E5E">
                                    High
                                  </span>
                              <span v-else-if="data.item.priority === 'Medium'" style="color: #FFDB00">
                                    Medium
                                  </span>
                              <span v-else-if="data.item.priority === 'Low'" style="color: #18D06B">
                                    Low
                                  </span>
                              <span v-else-if="data.item.priority === 'OnHold'" style="color: #419abe">
                                    On Hold
                                  </span>

                            </template>
                            <template v-slot:cell(status_name)="data">
                                <p
                                        style="margin-bottom:3px;font-weight:bold;padding-left:20px;"
                                >{{ data.item.status_name }}</p>
                                <p style="margin-bottom:3px;font-size:11px;padding-left:20px;">
                                    <span>{{ statusPosition(data.item) }} to {{ ceStatuses.length }}</span>
                                </p>
                                <div class="progress">
                                    <div class="bar" :style="{'width':`${statusPositionByPercentage(data.item)}%`}"></div>
                                </div>
                            </template>
                        </b-table>
                    </div>
                    <block-pagination
                            :role="'cards'"
                            :arrayOfSomeone="cardsClosed"
                            :arrayOfSomeoneFiltered="filter ? cardsClosedFiltered2 : computedCardsClosedForTable"
                            :currentPage="cardsClosedCurrentPage"
                            @changedCurrentPage="value => setCardsClosedCurrentPage(value)"
                            :perPage="cardsAllPerPage"
                            :perPageForMultipleTables="computedPerPage"
                            @changedPerPage="value => setCardsAllPerPage(value)"
                    >
                    </block-pagination>
                    <!-- <data-tables id="cards-table" :tableColumns="columns" :tableData="cardsClosed" :tableOrder="[]" @onRowClick="navigateToCardPage" class="table table-striped table-bordered dataTable"></data-tables> -->
                </tab>
                <template slot="nav-item-center"
                          v-if="!isDisplayBoardUser && !isMobileOnly && (isShopManagerUser || isProductionManagerUser || isEstimatorUser || isPDRTechnicianUser || isCustomerUser)">
                    <div class="btn-group cards-view-button" role="group">
                        <button v-if="!isDisplayBoardUser" type="button" class="btn btn-primary active">Table</button>
                        <button v-if="!isDisplayBoardUser" @click="setViewBoard" type="button" class="btn">Board</button>
                    </div>
                </template>
                <template slot="nav-item-right">
                    <div
                            v-if="isStripperFitterUser || isPanelTechnicianUser || isPaintTechnicianUser || isEstimatorUser"
                            style="margin-right: 15px;"
                            class="tabs-nav-right-btn">
                        <button @click.prevent="clickFilterTableForMine" class="btn" :class="{'btn-primary': isJustSeeMine, 'btn-secondary':!isJustSeeMine}">
                            Just See Mine
                        </button>
                    </div>
                    <div class="tabs-nav-right-search tabs-nav-right-search-font-weight">
<!--                        <b-form-input-->
<!--                                v-model="filter"-->
<!--                                type="text"-->
<!--                                class="form-control search-input"-->
<!--                                placeholder="Search or Filter results"-->
<!--                                debounce="300"-->
<!--                        ></b-form-input>-->

                      <search-filter2
                          type="estimates"
                          v-model="searchFilter"
                          class="search-filter"
                          :filters="computedSearchFilters"
                          :filterValue="filter"
                          :limit-length="50"
                          :isShowSearIcon="true"
                          @change="onSearchFilterChange"
                          @updateToken="value => $store.commit('card/setToken', value)"
                          @updateTokens="value => $store.commit('card/setTokens', value)"
                          :tokenCopy="computedToken"
                          :tokensCopy="getterTokens"
                          height="41px"
                          aria-expanded="false"
                          data-toggle="dropdown"
                          :isMyltipleOptions="true"
                          :isMyltiple="true"
                          :optionsLength="isCanSearchBySomeFields ? 8 : 6"
                      ></search-filter2>
                    </div>
                    <div
                            v-if="(isShopManagerUser || isProductionManagerUser || isEstimatorUser || isPDRTechnicianUser || isCustomerUser) && isStatusActive"
                            class="tabs-nav-right-btn"
                            style="position: relative; bottom: 8px">
                        <router-link to="/cards/new" class="btn btn-primary">
                            <i class="ti-plus"></i> New Card
                        </router-link>
                    </div>
                </template>
            </tabs>
            <vue-context ref="rightClickMenuForCards" @open="contextOpen" @close="contextClose">
                <ul slot-scope="scope" ref="contextMenuForCards">
                  <li>
                      Card Mode
                      <i class="icon-right bx bx-chevron-right"></i>
                      <ul type="none" class="subContext assign-to-context">
                        <li v-if="scope && scope.data && scope.data.item && scope.data.item.state != 0"
                            @click="onRightClickByMenu(0, scope.data)"
                        >
                          Mark As Open
                        </li>
                        <li v-if="scope && scope.data && scope.data.item && scope.data.item.state != 1"
                            @click="onRightClickByMenu(1, scope.data)"
                        >
                          Mark As Active
                        </li>
                        <li v-if="scope && scope.data && scope.data.item && scope.data.item.state != 3"
                            @click="onRightClickByMenu(3, scope.data)"
                        >
                          Mark As Closed
                        </li>
                        <li v-if="scope && scope.data && scope.data.item && scope.data.item.activity
                                && (
                                    (scope.data.item.activity.Assignment && scope.data.item.activity.Assignment.from)
                                    || (scope.data.item.activity.External && scope.data.item.activity.External.date)
                                    || (scope.data.item.activity.Email && scope.data.item.activity.Email.date)
                                    || (scope.data.item.activity.Message && scope.data.item.activity.Message.from)
                                )"
                            @click="markAsRead(scope.data.item.card_id)"
                        >
                          Mark As Read
                        </li>
                      </ul>

                    </li>

                  <li>
                      Open
                    <i class="icon-right bx bx-chevron-right"></i>
                    <ul type="none" class="subContext assign-to-context">
                      <li @click="openCardInNewTab(scope.data.item.card_id)">
                        in New Tab
                      </li>
                      <li @click="openCardInNewWindow(scope.data.item.card_id)">
                        in New Window
                      </li>
                    </ul>

                  </li>

                  <li>
                  Add to
                  <i class="icon-right bx bx-chevron-right"></i>
                  <ul type="none" class="subContext assign-to-context">
                    <li @click="addToVehicleInBoard(scope.data.item.card_id, scope.data.item.card_number)">
                      Vehicle In Board
                    </li>
                    <li @click="addToDepartureBoard(scope.data.item.card_id, scope.data.item.card_number)">
                      Departure Board
                    </li>
                  </ul>

                  </li>

                  <li v-if="true" @click="acceptORMJob">
                    Accept ORM JOB
                  </li>

                  <li v-if="true" @click="$refs.declineORMJob.show()">
                    Decline ORM Job
                  </li>

                  <li>
                    Set Priority
                    <i class="icon-right bx bx-chevron-right"></i>
                    <ul type="none" class="subContext assign-to-context">
                      <li @click="setCardPriority(scope.data.item.card_id, 'High')" class="d-flex">
                        <span class="status-color high-color" style="margin-right: 5px; margin-top: 1px"></span>High Priority
                      </li>
                      <li @click="setCardPriority(scope.data.item.card_id, 'Medium')" class="d-flex">
                        <span class="status-color medium-color" style="margin-right: 5px; margin-top: 1px"></span>Medium Priority
                      </li>
                      <li @click="setCardPriority(scope.data.item.card_id, 'Low')" class="d-flex">
                        <span class="status-color low-color" style="margin-right: 5px; margin-top: 1px"></span>Low Priority
                      </li>
                      <li @click="setCardPriority(scope.data.item.card_id, 'OnHold')" class="d-flex">
                        <span class="status-color on-hold-color" style="margin-right: 5px; margin-top: 1px"></span> On Hold
                      </li>
                      <li v-if="scope.data && scope.data.item && isHasPriority(scope.data.item.priority)" @click="setCardPriority(scope.data.item.card_id, 'Undo')" class="d-flex">
                        <span class="status-color undo-priority-color" style="margin-right: 5px; margin-top: 1px"></span> Undo Priority
                      </li>

                    </ul>

                  </li>

                  <li>
                        Assign to
                        <i class="icon-right bx bx-chevron-right"></i>
                        <ul type="none" class="subContext assign-to-context">
                            <input class="input-assign-to" type="text" placeholder="Search" v-model="filterAssignTo">
                            <li @click="assignedToPersonOnTable(scope.data, user.id)" v-for="user in computedActiveUsers">{{ user.name }}
                            </li>
                        </ul>

                    </li>

                  <li v-if="scope && scope.data && scope.data.item "
                        @click='copyCardModal(scope.data.item.card_id, scope.data.item.card_number)'
                    >
                      Copy Card
                    </li>
                </ul>
            </vue-context>
            <modal-wizard
                    ref="markAsModal"
                    cancelButtonText="Close"
                    finishButtonText="Ok"
                    @on-complete="completeMarkAs"
            >
                <modal-wizard-tab
                        :title="modal.title"
                        description
                        title-icon-class="ti-align-right"
                        :tabData="modal"
                >
                    <template slot-scope="props">
                        <div>{{ props.text }}</div>
                        <div class="mark-as">
                            <div class="row">
                                <div class="col-md-4 estimate-number">Estimate Number:</div>
                                <div class="col-md-5">{{ props.estimate_number }}</div>
                            </div>
                            <div class="row" v-if="props.supplement_numbers != ''">
                                <div class="col-md-4 estimate-number">Supplement Number(s):</div>
                                <div class="col-md-5">{{ props.supplement_numbers }}</div>
                            </div>
                        </div>
                    </template>
                </modal-wizard-tab>
            </modal-wizard>
        </div>
        <!--Board view-->
        <div v-if="isBoardViewActive && !isMobileOnly"
             class="box box-block bg-white cards"
             id="board-view"
        >
            <tabs
                    ref="cards-board"
                    cache-lifetime="0"
                    @changed="boardTabChanged"
                    :options="{useUrlFragment: false, defaultTabHash: computedDefaultCardBoardTabHash }"
            >
                <tab name="All" id="cards-all-board">
                  <cards-board
                      ref="allBoard"
                      :ceStatuses="ceStatuses"
                      :categories="categories"
                      :hiddenStatuses="hiddenStatuses"
                      :cards="computedCardsAll"
                      @isDraggedAll="v => isDraggedAll(v, 'allBoard')"
                      :mainFilterCount="countOfFilterChange"
                      @openMenuForBoardCards="openMenuForBoardCards"
                      @setCardPriority="setCardPriority"
                      @deleteCard="deleteCard"
                      @assignedToPerson="assignedToPerson"
                      @openDeclineORMJobModal="openDeclineORMJobModal"
                      @addToVehicleInBoard="addToVehicleInBoard"
                      @addToDepartureBoard="addToDepartureBoard"
                      @onRightClickByMenu="onRightClickByMenu"
                      @acceptORMJob="acceptORMJob"
                      @markAsRead="markAsRead"
                  ></cards-board>
                </tab>
                <tab v-if="!isDisplayBoardUser" name="Open" id="cards-open-board">
                  <cards-board
                      ref="openBoard"
                      :ceStatuses="ceStatuses"
                      :categories="categories"
                      :hiddenStatuses="hiddenStatuses"
                      :cards="computedOpenCards"
                      :mainFilterCount="countOfFilterChange"
                      @isDraggedAll="v => isDraggedAll(v, 'openBoard')"
                      @openMenuForBoardCards="openMenuForBoardCards"
                      @setCardPriority="setCardPriority"
                      @deleteCard="deleteCard"
                      @assignedToPerson="assignedToPerson"
                      @openDeclineORMJobModal="openDeclineORMJobModal"
                      @addToVehicleInBoard="addToVehicleInBoard"
                      @addToDepartureBoard="addToDepartureBoard"
                      @onRightClickByMenu="onRightClickByMenu"
                      @acceptORMJob="acceptORMJob"
                      @markAsRead="markAsRead"
                  ></cards-board>
                </tab>
                <tab v-if="!isDisplayBoardUser" name="Active" id="cards-active-board">
                  <cards-board
                      ref="activeBoard"
                      :ceStatuses="ceStatuses"
                      :hiddenStatuses="hiddenStatuses"
                      :categories="categories"
                      :cards="computedActiveCards"
                      @isDraggedAll="v => isDraggedAll(v, 'activeBoard')"
                      @openMenuForBoardCards="openMenuForBoardCards"
                      :mainFilterCount="countOfFilterChange"
                      @setCardPriority="setCardPriority"
                      @deleteCard="deleteCard"
                      @assignedToPerson="assignedToPerson"
                      @openDeclineORMJobModal="openDeclineORMJobModal"
                      @addToVehicleInBoard="addToVehicleInBoard"
                      @addToDepartureBoard="addToDepartureBoard"
                      @onRightClickByMenu="onRightClickByMenu"
                      @acceptORMJob="acceptORMJob"
                      @markAsRead="markAsRead"
                  ></cards-board>
                </tab>

                <tab v-if="!isDisplayBoardUser" name="Closed" id="cards-closed-board">
                  <cards-board
                      ref="closedBoard"
                      :ceStatuses="ceStatuses"
                      :hiddenStatuses="hiddenStatuses"
                      :categories="categories"
                      :cards="computedClosedCards"
                      @isDraggedAll="v => isDraggedAll(v, 'closedBoard')"
                      :mainFilterCount="countOfFilterChange"
                      @openMenuForBoardCards="openMenuForBoardCards"
                      @setCardPriority="setCardPriority"
                      @deleteCard="deleteCard"
                      @assignedToPerson="assignedToPerson"
                      @openDeclineORMJobModal="openDeclineORMJobModal"
                      @addToVehicleInBoard="addToVehicleInBoard"
                      @addToDepartureBoard="addToDepartureBoard"
                      @onRightClickByMenu="onRightClickByMenu"
                      @acceptORMJob="acceptORMJob"
                      @markAsRead="markAsRead"
                  ></cards-board>
                </tab>
                <template
                        v-if="!isDisplayBoardUser && (isShopManagerUser || isProductionManagerUser || isEstimatorUser || isPDRTechnicianUser || isCustomerUser)"
                        slot="nav-item-center">
                    <div class="btn-group cards-view-button" role="group">
                        <button v-if="!isDisplayBoardUser" @click="setViewTable" type="button" class="btn">Table</button>
                        <button v-if="!isDisplayBoardUser" @click="setViewBoard" type="button" class="btn btn-primary active">Board</button>
                    </div>
                </template>
                <template slot="nav-item-right">
                    <div class="tabs-nav-right-search tabs-nav-right-search-font-weight">
<!--                        <b-form-input-->
<!--                                v-model="filter"-->
<!--                                type="text"-->
<!--                                class="form-control search-input"-->
<!--                                placeholder="Search or Filter results"-->
<!--                                debounce="300"-->
<!--                        ></b-form-input>-->
                      <search-filter2
                          type="estimates"
                          v-model="searchFilter"
                          :filters="computedSearchFilters"
                          :filterValue="filter"
                          :limit-length="50"
                          :isShowSearIcon="true"
                          @change="onSearchFilterChange"
                          @updateToken="value => $store.commit('card/setToken', value)"
                          @updateTokens="value => $store.commit('card/setTokens', value)"
                          :tokenCopy="computedToken"
                          :tokensCopy="getterTokens"
                          height="41px"
                          aria-expanded="false"
                          data-toggle="dropdown"
                          :isMyltipleOptions="true"
                          :isMyltiple="true"
                          :optionsLength="7"
                      ></search-filter2>
                    </div>
                    <div
                            v-if="(isShopManagerUser || isProductionManagerUser || isEstimatorUser || isPDRTechnicianUser || isCustomerUser) && isStatusActive"
                            class="tabs-nav-right-btn">
                        <router-link to="/cards/new" class="btn btn-primary">
                            <i class="ti-plus"></i> New Card
                        </router-link>
                    </div>
                    <div class="navbar-nav nav dropdown dropdown-options  show inline" style="margin-left: 15px;" v-if="namesOfHiddenStatuses.length !== 0 && isStatusActive">
                        <button type="button"
                                class="btn waves-effect waves-light ex-options-button"
                                id="dropdownMenuLink"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="true">
                            Options
                            <span class="dropdown-arrow pull-right" style="box-sizing: border-box; width: 32px; border-left: 1px solid rgba(27,30,56,0.25);">
                                    <i class="fa fa-chevron-down pull-right"></i>
                                </span>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right animated fadeInUp"
                             aria-labelledby="dropdownMenuLink">

                            <a class="dropdown-item text-primary p-1 clickable has-child">
                                <span>Un-Hide Status</span>
                                <div class="dropdown-menu-child" style="padding: 0px; top: -18px; right: 146px;">
                                    <a v-for="status in namesOfHiddenStatuses" :key="status.id" @click="showStatus(status.id)" class="dropdown-item text-primary p-1 clickable">{{status.name}}</a>
                                </div>
                            </a>
                        </div>
                    </div>
                </template>
            </tabs>
            <vue-context @close='closeContextBoard' ref="rightClickMenuForBoardResult">
                <ul slot-scope="item">
                    <li @click="openNewTabCard">Open in New Tab</li>
                    <li @click="openNewBrowserCard">Open in New Window</li>
                    <li @click='copyCardModal(item.data.cardId, item.data.cardNumber)'>Copy Card</li>
                </ul>
            </vue-context>
            <modal-wizard
                    ref="markAsModal"
                    cancelButtonText="Close"
                    finishButtonText="Ok"
                    @on-complete="completeMarkAs"
            >
                <modal-wizard-tab
                        :title="modal.title"
                        description
                        title-icon-class="ti-align-right"
                        :tabData="modal"
                >
                    <template slot-scope="props">
                        <div>{{ props.text }}</div>
                        <div class="mark-as">
                            <div class="row">
                                <div class="col-md-4 estimate-number">Estimate Number:</div>
                                <div class="col-md-5">{{ props.estimate_number }}</div>
                            </div>
                            <div class="row" v-if="props.supplement_numbers != ''">
                                <div class="col-md-4 estimate-number">Supplement Number(s):</div>
                                <div class="col-md-5">{{ props.supplement_numbers }}</div>
                            </div>
                        </div>
                    </template>
                </modal-wizard-tab>
            </modal-wizard>

        </div>

        <modal-wizard
            id="declineORMJob"
            ref="declineORMJob"
            cancelButtonText="Cancel"
            finishButtonText="Ok"
            :finishButtonTooltip="isDisabledModalFinishButton ? 'Please fill in all fields' : ''"
            :isDisabledFinishButton="isDisabledModalFinishButton"
            @on-close="locationForDecline = ''; reasonForDeclineORM = '';"
            @on-complete="declineORMJob">
        <modal-wizard-tab title="Decline ORM Job" description="" title-icon-class="ti-align-right" class="pr-2">
          <template slot-scope="props">
            <div class="pt-2">Are you sure you want Decline this Job?</div>
            <div class="form-group row">
              <label class="col-form-label"></label>
              <div class="col-12">
                <textarea v-model="reasonForDeclineORM" style="height: 67px; resize: none" class="form-control" placeholder="Please add a reason for declining the repair"/>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label"></label>
              <div class="col-12">
                <multiselect
                    :options="['With me the Repairer', 'Returned to the Customer', 'RMC']"
                    :showLabels="false"
                    v-model="locationForDecline"
                    :option-height="29"
                    :max-height="203"
                    :close-on-select="true"
                    placeholder="Please confirm the current location of the vehicle">
                </multiselect>
              </div>
            </div>
          </template>
        </modal-wizard-tab>
      </modal-wizard>

        <modal-wizard ref="copyCardModal" cancelButtonText="Cancel" finishButtonText="Create"
                      @on-complete="completeCopyCard" class="copy-card-modal">
            <modal-wizard-tab title="Copy Card" description="" title-icon-class="ti-align-right">
                <div class="copy-card-modal-text">Do you want to Copy this Card {{cardNumberForCopy}} and all of its details?
                </div>
                <div class="copy-card-modal-block">
                    <div class="copy-card-modal-block-top">
                        <div class="col-md-6">
                            <span class="">Create a New Card & Estimate</span>
                        </div>
                        <div class="col-md-4">
                            <label class="form-check-inline checkbox checkbox-v">
                                <input v-model="copyCard.isNew" class="form-check-input" type="checkbox"/>
                                <span class="icon"><i class='bx bx-check'></i></span>
                            </label>
                        </div>

                    </div>
                    <span class="copy-card-modal-text-middle">or</span>
                    <div class="copy-card-modal-block-top">
                        <div class="col-md-6">
                            <span class="">Select an existing Card & Estimate to Copy {{cardNumberForCopy}} into</span>
                        </div>
                        <div class="col-md-5">
                            <multiselect
                                    v-model="copyCard.copyToCard"
                                    :options="computedCardsForCopyCard"
                                    :showLabels="false"
                                    :option-height="29"
                                    :max-height="203"
                                    :close-on-select="true"
                                    placeholder="Select Card Number"
                                    track-by="card_id"
                                    label="card_number"
                                    :disabled="copyCard.isNew"
                            />
                        </div>
                    </div>
                    <span class="copy-card-modal-text-middle">Optional</span>
                    <div class="copy-card-modal-block-top">
                        <div class="col-md-6 copy-card-modal-block copy-card-text-align">
                            <span class="">Copy Images</span>
                        </div>
                        <div class="col-md-4 copy-card-modal-block">
                            <label class="form-check-inline checkbox checkbox-v">
                                <input v-model="copyCard.isCopyImages" class="form-check-input" type="checkbox"/>
                                <span class="icon"><i class='bx bx-check'></i></span>
                            </label>
                        </div>
                    </div>
                    <div class="copy-card-modal-block-top">
                        <div class="col-md-6 copy-card-modal-block copy-card-text-align">
                            <span class="">Copy Files</span>
                        </div>
                        <div class="col-md-4 copy-card-modal-block">
                            <label class="form-check-inline checkbox checkbox-v">
                                <input v-model="copyCard.isCopyFiles" class="form-check-input" type="checkbox"/>
                                <span class="icon"><i class='bx bx-check'></i></span>
                            </label>
                        </div>
                    </div>
                </div>
            </modal-wizard-tab>
        </modal-wizard>
    </div>
</template>

<style scoped>
    .tabs-nav-right-search .search-filter >>> .form-control:focus{
      outline: 0 !important;
    }
    .tabs-nav-right-search .search-filter >>> .search-filter__input{
      font-size: 13px !important;
      font-weight: 800;
      color: black;
    }
    .tabs-nav-right-search .search-filter >>> .search-filter__box{
      border: 3px solid #5e77fd;
      border-radius: 7px;
    }
    .cards >>> .board-item-holder .board-item{
      border: 2px solid #bebebe;
    }
    .cards >>> .board-item-holder .board-item:hover{
      border-color: #5e77fc;
    }
    .tabs-nav-right-search .search-filter >>> .form-control:focus{
      outline: 0 !important;
    }
    .tabs-nav-right-search .search-filter >>> .search-filter__input{
      font-size: 13px !important;
      font-weight: 800;
      color: black;
    }
    .tabs-nav-right-search .search-filter >>> .search-filter__box{
      border: 3px solid #5e77fd;
      border-radius: 7px;
    }
    .search-filter >>> input::placeholder{
      color: #bbbbc2;
    }
    .search-filter >>> .search-filter__box__focused{
      border: 3px solid rgba(94, 121, 255, 0.5) !important;
      box-shadow: 0 0 0 0.2rem rgb(94 121 255 / 25%) !important;
    }
    .search-filter >>> .search-filter__li--input{
      border-bottom: 0;
    }
    .search-filter >>> .search-filter__li{
      border-bottom: 0 !important;
    }
    .search-filter >>> .search-filter__box{
      border: 2px solid rgba(28, 31, 57, 0.25);
    }
    .tabs-nav-right-search-font-weight >>> input{
      font-weight: 700;
    }
    .tabs-nav-right-search-font-weight >>> input::placeholder{
      font-weight: 600;
    }
    .page-header{
      touch-action: none;
    }
    .cards >>> .tabs-component{
      touch-action: none;
    }
    .input-assign-to {
        border: 1px solid buttonface;
        padding: 2px 7px;
        margin: 5px 7px;
        width: 90%;
        border-radius: 5px;
    }

    .assign-to-context {
        height: auto;
        max-height: 350px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    * {
        box-sizing: border-box;
    }

    .row.no-gutters {
        margin-right: 0;
        margin-left: 0;
    }

    .progress {
        margin: 0px auto;
        padding: 0;
        width: 150px;
        height: 7px;
        overflow: hidden;
        background: #e3e4e7;
        border-radius: 6px;
    }

    .bar {
        position: relative;
        float: left;
        min-width: 1%;
        height: 100%;
        background: linear-gradient(135deg, #7267E7 0%, #6259EF 49.12%, #4E45EE 100%);
    }

    .row.no-gutters > [class^="col-"],
    .row.no-gutters > [class*=" col-"] {
        padding-right: 0;
        padding-left: 0;
    }

    tbody tr {
        cursor: pointer;
    }

    .action-icon i {
        font-size: 14px;
        color: #BFBBBA;
    }

    .action-icon.active i {
        color: #142742;
    }

    .action-icon.active[tooltip] {
        position: relative;
        display: inline-block;
        transition: 0.3s;
    }

    .action-icon.active[tooltip]::before {
        content: "";
        position: absolute;
        bottom: 25px;
        left: 50%;
        width: 0;
        height: 0;
        border: 5px solid transparent;
        border-top-color: #333333;
        border-bottom: 0;
        margin-left: -5px;
        margin-bottom: -5px;
        opacity: 0;
        transition: 0.3s;
    }

    .action-icon.active[tooltip]:hover::before {
        opacity: 1;
    }

    .action-icon.active[tooltip-position="left"]::before {
        left: 0%;
        top: 50%;
        margin-left: -12px;
        transform: translatey(-50%) rotate(-90deg);
    }

    .action-icon.active[tooltip-position="top"]::before {
        left: 50%;
    }

    .action-icon.active[tooltip-position="buttom"]::before {
        top: 100%;
        margin-top: 8px;
        transform: translateX(-50%) translatey(-100%) rotate(-180deg);
    }

    .action-icon.active[tooltip-position="right"]::before {
        left: 100%;
        top: 50%;
        margin-left: 1px;
        transform: translatey(-50%) rotate(90deg);
    }

    .action-icon.active[tooltip]::after {
        content: attr(tooltip);
        position: absolute;
        left: 50%;
        top: -6px;
        transform: translateX(-50%) translateY(-100%);
        background: #333333;
        text-align: center;
        color: #FFFFFF;
        padding: 4px 2px;
        font-size: 10px;
        font-weight: 600;
        min-width: 155px;
        border-radius: 0px;
        pointer-events: none;
        z-index: 99;
        opacity: 0;
        transition: 0.3s;
    }

    .action-icon.active[tooltip-position="left"]::after {
        left: 0%;
        top: 50%;
        margin-left: -8px;
        transform: translateX(-100%) translateY(-50%);
    }

    .action-icon.active[tooltip-position="top"]::after {
        left: 50%;
    }

    .action-icon.active[tooltip-position="buttom"]::after {
        top: 100%;
        margin-top: 8px;
        transform: translateX(-50%) translateY(0%);
    }

    .action-icon.active[tooltip-position="right"]::after {
        left: 100%;
        top: 50%;
        margin-left: 8px;
        transform: translateX(0%) translateY(-50%);
    }

    .action-icon.active[tooltip]:hover::after,
    [tooltip]:hover::before {
        opacity: 1;
    }


    .list-complete-enter,
    .list-complete-leave-active {
        opacity: 0.5;
    }



    .hasSubContext:hover .subContext {
        opacity: 1;
        visibility: visible;
        transform: translateY(0px);
    }

    .position_sub_context_left {
        right: calc(100% + 1px);
        left: auto;
    }

    .hasSubContext {
        position: relative;
        padding-right: 5px !important;
    }

    .icon-right {
        font-size: 21px !important;
        transform: translate(100%, -3px) !important;
    }

    /*.hasSubContext:after {*/
    /*    content: "";*/
    /*    display: block;*/
    /*    position: absolute;*/
    /*    top: 0;*/
    /*    bottom: 0;*/
    /*    margin: auto;*/
    /*    left: calc(100% - 12px);*/
    /*    width: 0;*/
    /*    height: 0;*/
    /*    border-color: transparent transparent transparent #5d6471;*/
    /*    border-style: solid;*/
    /*    border-width: 5px;*/
    /*}*/

    .subContext {
        position: absolute;
        left: calc(100% + 1px);
        background: #FFFFFF;
        border: 1px solid #BDBDBD;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
        0 1px 5px 0 rgba(0, 0, 0, 0.12);
        min-width: 100%;
        width: auto;

        top: 0;
        transition: 0.4s;
        visibility: hidden;
        opacity: 0;
        right: auto;
        transform: translateY(-10px);
    }

    .hasSubContext ul > li {
        height: auto;
    }

    @media screen and (max-width: 767px) {
        .hasSubContext:hover:after {
            transform: rotate(90deg);
        }

        .subContext {
            left: 0 !important;
            right: auto !important;
            top: 100% !important;
            width: 100% !important;
        }
        .tabs-nav-right-search.tabs-nav-right-search-font-weight .search-filter{
          padding-left: 0px !important;
        }
    }

    @media screen and (max-width: 768px) {
        .header-display-name {
            display: none;
        }
        .search-filter{
          padding-left: 15px !important;
          padding-right: 15px;
        }
        .box-block >>> .tab-header.nav.nav-tabs {
          padding: 0 !important;
          margin-left: 15px;
          margin-right: 15px;
          border-bottom: 5px solid #e1e2e5 !important;
        }
    }
    @media screen and (max-width: 460px) {
      .cards >>> .tabs-component {
        top: 135px;
      }
    }
</style>

<style>
    .board-header-title {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        height: 23px;
    }

    .board-header-title .card-menu-button {
        margin-left: 5px;
    }

    .board-header-title .title-count {
        display: flex;
        align-items: center;
    }

    .board-header-title .count-box {
        display: flex;
        width: 28px;
        height: 28px;
        flex-shrink: 0;
        background: #FFFFFF;
        border-radius: 50%;
        color: #5e77fc;
        font-size: 12px;
        line-height: 14px;
        font-weight: 900;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
    }

    .board-header-title .show-more {
        font-size: 30px;
        color: rgba(28, 31, 57, 0.5);
    }

    .card-menu-popup * {
        list-style: none;
    }

    .card-menu-popup {
        width: 140px;
        text-align: left;
        font-size: 11px;
        line-height: 15px;
        font-weight: bold;
        border-radius: 3px;
        color: #1C1F39;
        background-color: #FFFFFF;
        box-shadow: 0 2px 4px 0 rgba(27, 30, 56, 0.15);
        padding: 0;
        position: relative;
        z-index: 9999;
    }

    .card-menu-popup ul {
        padding: 0;
        margin: 0;
    }

    .card-menu-popup li {
        width: 140px;
        border-radius: 3px 3px 0 0;
        padding: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        cursor: pointer;
    }

    .card-menu-popup > li.parent {
        position: relative;
    }

    .card-menu-popup > li.parent:hover {
        background-color: rgba(94, 121, 255, 0.1);
        color: #5E79FF;
    }

    .card-menu-popup > li.parent:hover .sub-menu {
        display: block;
        position: absolute;

        padding-left: 2px;
        left: 100%;
        top: 0;
        z-index: 2;
    }

    .card-menu-popup.card-menu-popup--left > li.parent:hover .sub-menu {
        padding-left: unset;
        left: unset;
        padding-right: 2px;
        right: 100%;
    }

    .card-menu-popup.card-menu-popup--bottom > li.parent:hover .sub-menu {
        top: unset;
        bottom: 0;
      /*top:0;*/
      /*bottom:unset;*/
    }

    .card-menu-popup > li.parent:hover .sub-menu-inner {
        background: #FFFFFF;
        color: initial;
        border-radius: 3px;
        box-shadow: 0 2px 4px 0 rgba(27, 30, 56, 0.15);
    }

    .card-menu-popup > li.parent:hover .sub-menu-inner li:hover {
        background-color: rgba(94, 121, 255, 0.1);
        color: #5E79FF;
    }

    .card-menu-popup > li.parent:hover .sub-menu .bx {
        font-size: 1.618em;
    }

    .card-menu-popup li > .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .card-menu-popup.card-menu-popup.card-menu-popup--left li > .title {
        justify-content: flex-start;
    }

    .card-menu-popup li > .title i {
        font-size: 21px;
        color: initial;
    }

    .card-menu-popup .sub-menu {
        display: none;
    }

    .card-menu-popup .sub-menu li {
        display: flex;
        align-items: center;
        height: 31px;
    }

    .card-menu-popup .sub-menu li .status-color {
        margin-right: 5px;
    }

    .status-color {
        display: block;
        height: 11px;
        width: 11px;
        background: #FF5E5E;
        border-radius: 3px;
    }

    .status-color.medium-color {
        background: #FFDB00;
    }

    .status-color.low-color {
        background: #18D06B;
    }
    .status-color.on-hold-color {
      background: #419abe;
    }
    .status-color.undo-priority-color {
      background: black;
    }

</style>

<!-- Table style-->
<style>
    .cards-view #declineORMJob .modal-content {
      margin-left: 20%;
      width: 60%;
    }

    .cards-view #declineORMJob .tab-content {
      background: none !important;
      overflow-x: inherit !important;
    }

    .V3 .cards-page .tab-content-table table th {
        font-size: 13px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 18px;
    }

    .V3 .cards-page .tab-content-table table td {
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 16px;
    }

    .V3 .cards-page .no-gutters {
        display: flex;
        justify-content: space-between;
    }

    .V3 .notf-icon .tooltip-text {
        width: 140px;
        /* margin-left: -68px; */
    }

    .V3 .notf-icon .tooltip-text.pos-top {
        margin-left: -68px;
    }

    .V3 .notf-icon .tooltip-text.pos-top.left {
        margin-left: -20px;
    }

    .V3 .notf-icon .tooltip-text.pos-top.left:after {
        left: 17%;
    }
</style>

<style>
    .cards-page .copy-card-modal #finishModalWizardButton {
        width: 68px;
    }

    .cards-page .copy-card-modal #cancelModalWizardButton {
        width: 68px;
    }

    .cards-page .copy-card-modal-text {
        padding-bottom: 20px;
    }

    .cards-page .copy-card-modal .tab-content {
        background: none;
    }
</style>
