<template>
  <div class="planner-page" v-if="!isMobileOnly()">
    <div @click="hideDropdown" class="page-header d-flex">
      <div>
        <h4>Bookings Board</h4>
        <ol class="breadcrumbs">
          <li class="breadcrumb-item">
            <router-link to="/">Home</router-link>
          </li>
          <li class="breadcrumb-item active">View Boards</li>
          <li class="breadcrumb-item active">Bookings Board</li>
        </ol>
      </div>
      <template>
        <div class="btn-group cards-view-button cards-view-button-center" role="group">
          <button type="button" @click="showSnapshot(0)" :class="{'btn-primary': isCalendarDisplaying}" class="btn">Bookings</button>
          <button type="button" @click="showSnapshot(1)" :class="{'btn-primary': isPlannerDisplaying}" class="btn">Planner</button>
          <button type="button" @click="showSnapshot(2)" :class="{'btn-primary': isBookingsSnapshot}" class="btn">Tracker Snapshot</button>
        </div>
      </template>
    </div>
    <booking-snapshot
        v-show="isBookingsSnapshot"
        :ceStatuses="ceStatuses"
        :attempts="attempts"
        :reasons="reasons"
    ></booking-snapshot>
    <planner
        v-show="isPlannerDisplaying"
        :settings="settings"
        :ceStatuses="ceStatuses"
        class="planner-tab"
        :events="events"
        @changeDate="checkDate"
      ></planner>
    <tabs v-show="isCalendarDisplaying" :options="{useUrlFragment: false }">
      <div class="header no-gutter planner-header">
        <div @click="hideDropdown" class="d-flex planer-date-period">
          <tab name="All" id="planner-all" :class-name="'tab-content-table'">
            <div class="d-flex" style="margin-right: 20px">
              <div class="weekly-target">
                <div style="margin-bottom: 5px; font-weight: 800">Weekly Totals Target: {{
                    settings.targets.job | formatMoney
                  }}
                </div>
                <div style="font-weight: 600">Current Totals: {{ getCurrentWeekTotal() | formatMoney }}</div>
              </div>
              <div class="d-flex">
                <div style="width: 70px">
                  <i class="bx bx-chevron-left planner-icon" @click="$refs.calendar.prev()"></i>
                  <i class="bx bx-chevron-right planner-icon" @click="$refs.calendar.next()"></i>
                </div>
                <div class="planer-date-period-text-block">
                  <span class="planer-date-period-text">{{ computedTitle }}</span>
                </div>
              </div>
            </div>
          </tab>
        </div>

        <div class="planner-header-menu-btn-block">
          <div @click="hideDropdown" class="planer-period-menu">
            <div class="dropdown dropdown-options show inline">
              <button type="button"
                      class="btn waves-effect waves-light ex-options-button"
                      id="dropdownMenuLink1"
                      data-toggle="dropdown"
                      style="width: 164px"
                      aria-haspopup="true"
                      aria-expanded="true">
                {{ computedType }}
                <span class="dropdown-arrow pull-right"
                      style="box-sizing: border-box; width: 32px; border-left: 1px solid rgba(27,30,56,0.25);">
                    <i class="fa fa-chevron-down pull-right"></i>
                    </span>
              </button>
              <div style="z-index: 9999" class="dropdown-menu dropdown-menu-right animated fadeInUp"
                   aria-labelledby="dropdownMenuLink">
                <a v-if="getterPlannerBoardType !== 'day'" class="dropdown-item text-primary p-1 clickable" @click="setType('day')">Day</a>
                <a v-if="getterPlannerBoardType !== 'week'" class="dropdown-item text-primary p-1 clickable" @click="setType('week')">Week</a>
                <!--                <a class="dropdown-item text-primary p-1 clickable" @click="setType('weekStarting')">Week (Starting)</a>-->
                <a v-if="getterPlannerBoardType !== 'month' && !isFullScreen" class="dropdown-item text-primary p-1 clickable"
                   @click="setType('month'); isEstimateBooking = false">Month</a>
              </div>
            </div>
          </div>

          <div class="planer-categories-menu">
            <div class="dropdown dropdown-options show inline">
              <button type="button"
                      class="btn waves-effect waves-light ex-categories-button"
                      id="dropdownMenuLink3"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="true"
                      @click="toggleDropdown">
                Categories
                <span class="dropdown-arrow pull-right"
                      style="box-sizing: border-box; width: 32px; border-left: 1px solid rgba(27,30,56,0.25);">
                      <i class="fa fa-chevron-down pull-right"></i>
                      </span>
              </button>
              <div ref="dropdown" style="z-index: 9999; width: 270px;"
                   class="dropdown-menu dropdown-menu-right animated fadeInUp" aria-labelledby="dropdownMenuLink3">
                  <span class="text-category-menu">
                    Manage your categories. Create new, name and edit the colour's. To save the changed category name, press enter.
                  </span>
                <input @input="filteringCategories" v-model="filterCategoryName"
                       style="margin: 8px 0px 2px 16px; width: 236px" placeholder="Name" type="text"
                       class="form-control"/>
                <!--                <h6 style="padding-bottom: 5px; margin: 10px 15px; border-bottom: #e0e0e0 1px solid;">Name</h6>-->


                <a v-for="gr in filteredGroups" :key="gr.id"
                   class="dropdown-item text-primary p-1 clickable d-flex has-child">
                  <span class="categories-menu-color-icon" :style="{backgroundColor: gr.color}"></span>
                  <input placeholder="Name of Category" :ref="'group-' + gr.id"
                         @keypress.enter="updateGroup(gr.id, gr.name, gr.color)" type="text" class="category-input"
                         v-model="gr.name">
                  <div class="dropdown-menu-child">
                    <ul type="none" style="margin-top: 0;">
                      <li>
                        <a @click="addEmptyNewGroup" href="#">Add New</a>
                      </li>
                      <li>
                        <a @click="focusOnCategory(gr.id)" href="#">Rename</a>
                      </li>
                      <li>
                        <a @click="removeGroup(gr.id)" href="#">Delete</a>
                      </li>
                      <li @mouseover="showColorPicker(gr)"
                          @mouseleave="gr.isOpenColorPicker = false; gr.isSetColor = false;">
                        <a href="#">Colour</a>

                        <div v-if="gr.isOpenColorPicker" class="category-color-picker">
                          <div style="position: relative">
                            <v-app>
                              <v-color-picker
                                  dot-size="20"
                                  :canvasHeight="100"
                                  :width="250"
                                  swatches-max-height="200"
                                  v-model="currentColor"
                              ></v-color-picker>
                              <button @click="updateGroup(gr.id, gr.name, currentColor)" class="choose-button">Select
                              </button>
                            </v-app>
                          </div>
                        </div>

                      </li>
                    </ul>
                  </div>
                </a>

                <!--                <div class="form-group row planner-categories-btn">-->
                <!--                  <button class="btn btn-secondary ml-1" style="width: 100px;">Cancel</button>-->
                <!--                  <button class="btn btn-primary" style="width: 100px; color: white;">Save</button>-->
                <!--                </div>-->
              </div>
            </div>
          </div>


          <div @click="hideDropdown" class="planer-options-menu">
            <div @click="hideDropdown" class="dropdown dropdown-options show inline">
              <button type="button"
                      class="btn waves-effect waves-light ex-options-button"
                      id="dropdownMenuLink2"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="true">
                Options
                <span class="dropdown-arrow pull-right"
                      style="box-sizing: border-box; width: 32px; border-left: 1px solid rgba(27,30,56,0.25);">
                      <i class="fa fa-chevron-down pull-right"></i>
                      </span>
              </button>
              <div style="z-index: 9999; width: 150px;" class="dropdown-menu dropdown-menu-right animated fadeInUp"
                   aria-labelledby="dropdownMenuLink2">
                <!--                  <a class="dropdown-item text-primary p-1 clickable" @click="$refs.bookingEventModal.show()">Add Vehicle Booking</a>-->
                <a class="dropdown-item text-primary p-1 clickable" @click="showAddVehicleBookingMenu">Add Vehicle
                  Booking</a>
                <a class="dropdown-item text-primary p-1 clickable"
                   @click="newGeneralEvent.createdBy = userInfo.full_name; $refs.generalEventModal.show()">Add General Event</a>
                  <a class="dropdown-item text-primary p-1 clickable" @click="newGeneralEvent.createdBy = userInfo.full_name; newGeneralEvent.isAllDayEvent = true; $refs.generalEventModal.show()">Add All Day Event</a>
                  <a v-if="getterPlannerBoardType !== 'month'" class="dropdown-item text-primary p-1 clickable" @click="showOnSiteMenu(false)">Add On-Site Estimate Booking</a>
                  <template v-if="getterPlannerBoardType !== 'day'">
                    <a v-if="!isShowOpenTimeslots" class="dropdown-item text-primary p-1 clickable" @click="showOpenEstimateTimeslots">Show Open Estimate timeslots</a>
                    <a v-else class="dropdown-item text-primary p-1 clickable" @click="hideOpenEstimateTimeslots">Hide Open Estimate timeslots</a>
                  </template>
                </div>
            </div>
          </div>
        </div>
      </div>

      <div class="calendar-area" @click="hideDropdown">
        <div class="d-flex">
          <div style="width: 100%">
            <v-app>
              <v-calendar
                  ref="calendar"
                  v-model="focus"
                  color="primary"
                  :events="computedEvents"
                  :event-color="getEventColor"
                  :type="computedCalendarType"
                  :short-weekdays="false"
                  @click:more="viewDay"
                  @click:date="viewDay"
                  :weekdays="computedWeekDays"
                  :event-ripple="false"
                  @change="changedPlanner"
                  :interval-height="intervalHeight"
                  @mousedown:event="startDrag"
                  @mousedown:time="startTime"
                  @mousemove:time="mouseMove"
                  @mouseup:time="endDrag"
                  @click:event="showEvent"
                  @mouseleave.native="cancelDrag"
                  :class="{'full-screen-grid': isFullScreen}"
                  style="width: 100%; margin: 0 auto; margin-top: 15px !important;">


                <template v-slot:day="{date}">
                  <div
                      v-if="getterPlannerBoardType == 'month' && getCurrentDayBooked(date)"
                      style="position: absolute; top: 13px; left: 10px; font-weight: bold"
                  >
                    Booked: {{ getCurrentDayBooked(date) }}
                  </div>
                </template>

                <template v-slot:day-header="{date}">
                  <template v-if="getterPlannerBoardType !== 'month'">
                    <div class="subtitle-day-week" :style="{'height': isHasEvents(date) ? getSubtitleHeight() : '100%', 'minHeight': isHasEvents(date) ? getSubtitleHeight() : '100%'}" v-if="isOpenDay(date)">
                      <div v-if="isOverbookedByTotal(date)" style="color: red; font-weight: 900; font-size: 18px">Overbooked</div>
                      <div class="subtitle-day-week-item" style="font-weight: 600">Daily Target:
                        {{ computedDayTotal |formatMoney }}
                      </div>
                      <div class="subtitle-day-week-item">Current Daily: {{ getCurrentDayTotal(date) | formatMoney }}</div>
                      <div class="subtitle-day-week-item">Current LBR Daily: {{ getCurrentDayTotalLbr(date) | formatMoney }}</div>
                      <div
                          v-if="getCurrentDayBooked(date)"
                          :style="{'height': getSubtitleHeight() == '110px' && !isOverbookedByTotal(date) ? '47px': 'auto'}"
                          style="
                          font-weight: bold;
                          text-align: left;
                          display: flex;
                          justify-content: end;
                          flex-direction:column;">
                        Booked: {{getCurrentDayBooked(date)}}</div>
                    </div>
                    <div v-else style="height: 100%"></div>
                  </template>
                </template>

                <template v-slot:event="{ event, timed, eventSummary }">
                  <div
                      :id="event.id"
                      :data-order="getCategoryIndex(event)"
                      :data-type="event.type"
                      :data-id="event.id"
                      class="noselect event-name-template"
                      :class="{'all-day-event': event.isAllDayEvent, 'holiday-event': event.isAllDayEvent && !event.isWorkDay, 'text-to-end': event.type == 'vehicle' && isTextToEnd(event), 'text-to-center': event.type == 'vehicle' && isTextToCenter(event)}"
                      style="padding-left: 10px; height: 100%">
                    {{ event.name }}<span v-if="computedEvtsAfterLimit.includes(event.id)"> * </span>
                  </div>

                  <!--              <div-->
                  <!--                  class="v-event-draggable"-->
                  <!--                  v-html="eventSummary()"-->
                  <!--              >{{event}}</div>-->
                  <div
                      v-if="timed"
                      class="v-event-drag-bottom"
                      @mousedown.stop="extendBottom(event)"
                  ></div>
                </template>

                <template v-slot:day-body="{ date, week }">
                  <div
                      class="v-current-time"
                      style="  scroll-margin-top: 3rem"
                      :class="{ first: date === 5 }"
                      :style="{ top: nowY }"
                  ></div>
                </template>
              </v-calendar>
              <v-menu
                  v-model="selectedOpen"
                  :close-on-content-click="false"
                  :activator="selectedElement"
                  ref="menu"
                  max-width="300px"
                  :nudge-left="reactiveSelectedEvent.type == 'on-site' ? -100 : 200"
              >
                <v-card
                    v-if="reactiveSelectedEvent.type == 'on-site'"
                    color="lighten-4"
                    flat
                >

                  <v-card-text
                      class="context-menu-calendar"
                        style="padding: 0; min-width: 270px"
                  >
                    <div class="calendar-card-details-context-menu clickable">
                      <span
                          @click="isUpdatedFromUpdater = true; isEstimateBooking = true; selectCard(reactiveSelectedEvent.card.id, true); $refs.menu.save()">View Details</span>
                      </div>
                      <div class="calendar-card-details-context-menu clickable"  @click="deleteEvent(reactiveSelectedEvent.id)">
                        <span>Remove from Timeslot</span>
                      </div>
                      <div class="calendar-card-details-context-menu clickable">
                        <span>Send Email Booking Confirmation</span>
                      </div>
                      <div class="calendar-card-details-context-menu clickable">
                        <span>Send SMS  Booking Confirmation</span>
                      </div>
                      <div class="calendar-card-details-context-menu clickable">
                        <span>Send Email Re-Booking message</span>
                      </div>
                      <div class="calendar-card-details-context-menu clickable">
                        <span>Send SMS Re-Booking message</span>
                      </div>

                  </v-card-text>
                </v-card>
                <v-card
                    v-else
                    color="lighten-4"
                    min-width="300px"
                    min-height="280px"
                    flat
                >
                  <v-toolbar
                      :color="reactiveSelectedEvent.color"
                      dark
                  >
                    <v-toolbar-title v-html="reactiveSelectedEvent.name"></v-toolbar-title>
                    <!--            <v-spacer></v-spacer>-->

                    <v-menu :ref="'eventMenu-' + reactiveSelectedEvent.id" offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            class="planer-btn-menu"
                            text
                            v-bind="attrs"
                            v-on="on"
                        >
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="editEvent(reactiveSelectedEvent.id)">
                          <v-list-item-title>Edit Event</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="deleteEvent(reactiveSelectedEvent.id)">
                          <v-list-item-title>Remove Event</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            v-if="reactiveSelectedEvent && reactiveSelectedEvent.card && reactiveSelectedEvent.card.id"
                            @click="openNewTabCard(reactiveSelectedEvent.card.id)">
                          <v-list-item-title>View Card</v-list-item-title>
                        </v-list-item>
                      </v-list>

                    </v-menu>

                  </v-toolbar>
                  <v-card-text
                      v-if="reactiveSelectedEvent && !(reactiveSelectedEvent.type == 'general')">
                    <div class="calendar-card-details">
                      <span>Date</span>
                      <span>{{ getDateTitle(reactiveSelectedEvent.dateStart, reactiveSelectedEvent.dateEnd) }}</span>
                    </div>
                    <div class="calendar-card-details" v-if="reactiveSelectedEvent.type !== 'vehicle'">
                      <span>Time Start</span>
                      <span>{{ reactiveSelectedEvent.timeStart }}</span>
                    </div>
                    <div class="calendar-card-details" v-if="reactiveSelectedEvent.type !== 'vehicle'">
                      <span>Time End</span>
                      <span>{{ reactiveSelectedEvent.timeEnd }}</span>
                    </div>
                    <div class="calendar-card-details">
                      <span>Note</span>
                      <span v-if="reactiveSelectedEvent.note">{{ reactiveSelectedEvent.note }}</span>
                      <span v-else> - </span>
                    </div>
                  </v-card-text>

                  <v-card-text
                      v-if="reactiveSelectedEvent && reactiveSelectedEvent.card && reactiveSelectedEvent.card.id">
                    <div class="calendar-card-details">
                      <span>Rego</span>
                      <span
                          v-if="reactiveSelectedEvent && reactiveSelectedEvent.card && reactiveSelectedEvent.card.vehicleRego">{{
                          reactiveSelectedEvent.card.vehicleRego
                        }}</span>
                      <span v-else>-</span>
                    </div>
                    <div class="calendar-card-details">
                      <span>Make</span>
                      <span
                          v-if="reactiveSelectedEvent && reactiveSelectedEvent.card && reactiveSelectedEvent.card.vehicleMake">{{
                          reactiveSelectedEvent.card.vehicleMake
                        }}</span>
                      <span v-else>-</span>
                    </div>
                    <div class="calendar-card-details">
                      <span>Model</span>
                      <span
                          v-if="reactiveSelectedEvent && reactiveSelectedEvent.card && reactiveSelectedEvent.card.vehicleModel">{{
                          reactiveSelectedEvent.card.vehicleModel
                        }}</span>
                      <span v-else>-</span>
                    </div>
                    <div class="calendar-card-details">
                      <span>Assigned To</span>
                      <span
                          v-if="reactiveSelectedEvent && reactiveSelectedEvent.card && reactiveSelectedEvent.card.assignedTo">{{
                          reactiveSelectedEvent.card.assignedTo
                        }}</span>
                      <span v-else>-</span>
                    </div>
                    <div class="calendar-card-details">
                      <span>Insurer</span>
                      <span
                          v-if="reactiveSelectedEvent && reactiveSelectedEvent.card && reactiveSelectedEvent.card.insurer">{{
                          reactiveSelectedEvent.card.insurer
                        }}</span>
                      <span v-else>-</span>
                    </div>
                    <div class="calendar-card-details">
                      <span>Parts</span>
                      <span
                          v-if="reactiveSelectedEvent && reactiveSelectedEvent.card && reactiveSelectedEvent.card.progress &&
                            reactiveSelectedEvent.card.progress.parts && Number(reactiveSelectedEvent.card.progress.parts) != 0">{{ reactiveSelectedEvent.card.progress.parts }}%</span>
                      <span v-else>-</span>
                    </div>
                    <div class="calendar-card-details">
                      <span>RR</span>
                      <span
                          v-if="reactiveSelectedEvent && reactiveSelectedEvent.card && reactiveSelectedEvent.card.progress &&
                            reactiveSelectedEvent.card.progress.rr && Number(reactiveSelectedEvent.card.progress.rr) != 0">{{ reactiveSelectedEvent.card.progress.rr }}%</span>
                      <span v-else>-</span>
                    </div>
                    <div class="calendar-card-details">
                      <span>Repair</span>
                      <span
                          v-if="reactiveSelectedEvent && reactiveSelectedEvent.card && reactiveSelectedEvent.card.progress &&
                            reactiveSelectedEvent.card.progress.repair && Number(reactiveSelectedEvent.card.progress.repair) != 0">{{ reactiveSelectedEvent.card.progress.repair }}%</span>
                      <span v-else>-</span>
                    </div>
                    <div class="calendar-card-details">
                      <span>Paint</span>
                      <span
                          v-if="reactiveSelectedEvent && reactiveSelectedEvent.card && reactiveSelectedEvent.card.progress &&
                            reactiveSelectedEvent.card.progress.paint && Number(reactiveSelectedEvent.card.progress.paint) != 0">{{ reactiveSelectedEvent.card.progress.paint }}%</span>
                      <span v-else>-</span>
                    </div>
                    <div class="calendar-card-details">
                      <span>Sublet</span>
                      <span
                          v-if="reactiveSelectedEvent && reactiveSelectedEvent.card && reactiveSelectedEvent.card.progress &&
                            reactiveSelectedEvent.card.progress.sublet && Number(reactiveSelectedEvent.card.progress.sublet) != 0">{{ reactiveSelectedEvent.card.progress.sublet }}%</span>
                      <span v-else>-</span>
                    </div>
                    <div class="calendar-card-details">
                      <span>Other</span>
                      <span
                          v-if="reactiveSelectedEvent && reactiveSelectedEvent.card && reactiveSelectedEvent.card.progress &&
                            reactiveSelectedEvent.card.progress.other && Number(reactiveSelectedEvent.card.progress.other) != 0">{{ reactiveSelectedEvent.card.progress.other }}%</span>
                      <span v-else>-</span>
                    </div>
                    <div class="calendar-card-details">
                      <span>Total Hrs</span>
                      <span v-if="reactiveSelectedEvent && reactiveSelectedEvent.card &&
                         reactiveSelectedEvent.card.totalHrs && Number(reactiveSelectedEvent.card.totalHrs) != 0">{{ reactiveSelectedEvent.card.totalHrs }}</span>
                      <span v-else>-</span>
                    </div>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-app>
          </div>

          <vehicle-booking
              ref="vehicleBooking"
              v-if="isVehicleBooking && isEstimateBooking == false"
              :ceStatuses="ceStatuses"
              :groups="groups"
              @close="isVehicleBooking = false"
              :calendarValue="computedCalendarValue ? computedCalendarValue : null"
              :events="events"
              :settings="settings"
              :planner="planner"
              @add="addVehicleBookingEventNew"
              @loadEvents="loadEvents"
          >

          </vehicle-booking>
          <div v-if="isEstimateBooking && isVehicleBooking == false" class="estimate-booking"
               :class="{'estimate-booking-display-block': isEstimateBooking}" style="position: static">
            <div class="estimate-booking-header d-flex">
              <div class="estimate-booking-header-title">Request for Quote Booking</div>
              <div class="estimate-booking-header-close"><i class='bx bx-x clickable'
                                                            @click="isEstimateBooking = false; showMenuSettingsOnSiteEstimateBooking = false"></i>
              </div>
            </div>
            <div class="estimate-booking-body">
              <div class="estimate-booking-body-settings d-flex">
                <div class="estimate-booking-body-settings-name d-flex">
                  <div class="">Estimates To Book</div>
                  <button @click="addNewCard" type="button" class="btn btn-primary" style="color: white; margin-left: 20px; transform: translateY(-8px)"><i class="ti-plus"></i> New Card</button>
                </div>
                <div class="estimate-booking-body-settings-icon"><i class='bx bx-cog'
                                                                    @click="showMenuSettingsOnSiteEstimateBooking = !showMenuSettingsOnSiteEstimateBooking"></i>
                  <div class="estimate-booking-settings" v-if="showMenuSettingsOnSiteEstimateBooking">
                    <div class="estimate-booking-header">
                      <div class="estimate-booking-header-title">Settings</div>
                    </div>
                    <div class="estimate-booking-settings-body">
                      <div class="estimate-booking-settings-body-main">
                        <div class="form-group row no-gutter">
                          <label for="card_number_length"
                                 class="col-lg-5 col-form-label">Status to incl</label>
                          <div class="col-lg-7 booking-status-select">
                            <multiselect
                                v-model="planner.statuses"
                                :options="computedCeStatuses"
                                :showLabels="false"
                                :option-height="29"
                                :max-height="203"
                                :close-on-select="true"
                                :placeholder="''"
                                :multiple="true"
                                @input="debouncedSavePlannerBoard"
                                style="opacity: 1">

                              <template #tag="{ option }">
                                {{ getStatusName(option) }}<span
                                  v-if="planner.statuses.indexOf(option) + 1 !== planner.statuses.length">, </span>
                              </template>
                              <template #option="{ option }">
                                {{ getStatusName(option) }}
                              </template>

                            </multiselect>
                          </div>
                        </div>
                        <div class="form-group row no-gutter">
                          <label for="card_number_length"
                                 class="col-lg-5 col-form-label">Default time slot duration</label>
                          <div class="col-lg-7">
                            <multiselect
                                v-model="planner.timeDuration"
                                :options="[15, 30, 45]"
                                :showLabels="false"
                                :option-height="29"
                                :max-height="203"
                                @input="debouncedSavePlannerBoard"
                                :close-on-select="true"
                                style="opacity: 1">
                              <template #singleLabel="{ option }">
                                {{ option }} Mins
                              </template>
                              <template #option="{ option }">
                                {{option}} Mins
                              </template>
                            </multiselect>
                          </div>
                        </div>
                        <div class="form-group row no-gutter">
                          <label for="card_number_length"
                                 class="col-lg-5 col-form-label">Max Nbr of Booking per timeslot</label>
                          <div class="col-lg-7">
                            <multiselect
                                v-model="planner.maxNbrPerSlot"
                                :options="[1,2,3,4,5]"
                                :showLabels="false"
                                :option-height="29"
                                @input="debouncedSavePlannerBoard"
                                :max-height="203"
                                :close-on-select="true"
                                style="opacity: 1">
                            </multiselect>
                          </div>
                        </div>
                      </div>
                      <div class="estimate-booking-settings-body-table">
                        <div class="estimate-booking-settings-body-table-title d-flex">
                          <div class="estimate-booking-settings-body-table-title-item" style="margin-right: 10px">Booking Timeslots</div>
                          <div class="estimate-booking-settings-body-table-title-item">Start</div>
                          <div class="estimate-booking-settings-body-table-title-item">End</div>
                        </div>
                        <div class="estimate-booking-settings-body-table-main">
                          <div class="estimate-booking-settings-body-table-main-item d-flex justify-content-between" style="margin-top: 10px">
                            <div class="estimate-booking-settings-body-table-main-item-width estimate-booking-settings-body-table-main-item-day">Monday</div>
                            <div class="d-flex" style="flex-direction: column">
                              <div class="d-flex justify-content-between">
                                <div class="estimate-booking-settings-body-table-main-item-width estimate-booking-settings-body-table-main-item-start">
                                  <the-time-picker :value="planner.weeks[1].morning.startTime"  @input="debouncedSavePlannerBoard" @changed="v => planner.weeks[1].morning.startTime = v"
                                  ></the-time-picker>
                                </div>
                                <div class="estimate-booking-settings-body-table-main-item-width estimate-booking-settings-body-table-main-item-end">
                                  <the-time-picker :value="planner.weeks[1].morning.endTime"  @input="debouncedSavePlannerBoard" @changed="v => planner.weeks[1].morning.endTime = v"
                                  ></the-time-picker>
                                </div>
                              </div>
                              <div class="d-flex justify-content-between">
                                <div class="estimate-booking-settings-body-table-main-item-width estimate-booking-settings-body-table-main-item-start">
                                  <the-time-picker :value="planner.weeks[1].afternoon.startTime" @input="debouncedSavePlannerBoard"
                                                   @changed="v => planner.weeks[1].afternoon.startTime = v"
                                  ></the-time-picker>
                                </div>
                                <div class="estimate-booking-settings-body-table-main-item-width estimate-booking-settings-body-table-main-item-end">
                                  <the-time-picker :value="planner.weeks[1].afternoon.endTime" @input="debouncedSavePlannerBoard"
                                                   @changed="v => planner.weeks[1].afternoon.endTime = v"
                                  ></the-time-picker>
                                </div></div>
                            </div>
                          </div>
                        </div>
                        <div class="estimate-booking-settings-body-table-main">
                          <div class="estimate-booking-settings-body-table-main-item d-flex justify-content-between" style="margin-top: 10px">
                            <div class="estimate-booking-settings-body-table-main-item-width estimate-booking-settings-body-table-main-item-day">Tuesday</div>
                            <div class="d-flex" style="flex-direction: column">
                              <div class="d-flex justify-content-between">
                                <div class="estimate-booking-settings-body-table-main-item-width estimate-booking-settings-body-table-main-item-start">
                                  <the-time-picker :value="planner.weeks[2].morning.startTime" @input="debouncedSavePlannerBoard"
                                                   @changed="v => planner.weeks[2].morning.startTime = v"
                                  ></the-time-picker>
                                </div>
                                <div class="estimate-booking-settings-body-table-main-item-width estimate-booking-settings-body-table-main-item-end">
                                  <the-time-picker :value="planner.weeks[2].morning.endTime" @input="debouncedSavePlannerBoard"
                                                   @changed="v => planner.weeks[2].morning.endTime = v"
                                  ></the-time-picker>
                                </div>
                              </div>
                              <div class="d-flex justify-content-between">
                                <div class="estimate-booking-settings-body-table-main-item-width estimate-booking-settings-body-table-main-item-start">
                                  <the-time-picker :value="planner.weeks[2].afternoon.startTime"  @input="debouncedSavePlannerBoard" @changed="v => planner.weeks[2].afternoon.startTime = v"
                                  ></the-time-picker>
                                </div>
                                <div class="estimate-booking-settings-body-table-main-item-width estimate-booking-settings-body-table-main-item-end">
                                  <the-time-picker :value="planner.weeks[2].afternoon.endTime"  @input="debouncedSavePlannerBoard" @changed="v => planner.weeks[2].afternoon.endTime = v"
                                  ></the-time-picker>
                                </div></div>
                            </div>
                          </div>
                        </div>

                        <div class="estimate-booking-settings-body-table-main">
                          <div class="estimate-booking-settings-body-table-main-item d-flex justify-content-between" style="margin-top: 10px">
                            <div class="estimate-booking-settings-body-table-main-item-width estimate-booking-settings-body-table-main-item-day">Wednesday</div>
                            <div class="d-flex" style="flex-direction: column">
                              <div class="d-flex justify-content-between">
                                <div class="estimate-booking-settings-body-table-main-item-width estimate-booking-settings-body-table-main-item-start">
                                  <the-time-picker :value="planner.weeks[3].morning.startTime"  @input="debouncedSavePlannerBoard" @changed="v => planner.weeks[3].morning.startTime = v"
                                  ></the-time-picker>
                                </div>
                                <div class="estimate-booking-settings-body-table-main-item-width estimate-booking-settings-body-table-main-item-end">
                                  <the-time-picker :value="planner.weeks[3].morning.endTime"  @input="debouncedSavePlannerBoard" @changed="v => planner.weeks[3].morning.endTime = v"
                                  ></the-time-picker>
                                </div>
                              </div>
                              <div class="d-flex justify-content-between">
                                <div class="estimate-booking-settings-body-table-main-item-width estimate-booking-settings-body-table-main-item-start">
                                  <the-time-picker :value="planner.weeks[3].afternoon.startTime" @input="debouncedSavePlannerBoard"
                                                   @changed="v => planner.weeks[3].afternoon.startTime = v"
                                  ></the-time-picker>
                                </div>
                                <div class="estimate-booking-settings-body-table-main-item-width estimate-booking-settings-body-table-main-item-end">
                                  <the-time-picker :value="planner.weeks[3].afternoon.endTime" @input="debouncedSavePlannerBoard"
                                                   @changed="v => planner.weeks[3].afternoon.endTime = v"
                                  ></the-time-picker>
                                </div></div>
                            </div>
                          </div>
                        </div>

                        <div class="estimate-booking-settings-body-table-main">
                          <div class="estimate-booking-settings-body-table-main-item d-flex justify-content-between" style="margin-top: 10px">
                            <div class="estimate-booking-settings-body-table-main-item-width estimate-booking-settings-body-table-main-item-day">Thursday</div>
                            <div class="d-flex" style="flex-direction: column">
                              <div class="d-flex justify-content-between">
                                <div class="estimate-booking-settings-body-table-main-item-width estimate-booking-settings-body-table-main-item-start">
                                  <the-time-picker :value="planner.weeks[4].morning.startTime" @input="debouncedSavePlannerBoard"
                                                   @changed="v => planner.weeks[4].morning.startTime = v"
                                  ></the-time-picker>
                                </div>
                                <div class="estimate-booking-settings-body-table-main-item-width estimate-booking-settings-body-table-main-item-end">
                                  <the-time-picker :value="planner.weeks[4].morning.endTime" @input="debouncedSavePlannerBoard"
                                                   @changed="v => planner.weeks[4].morning.endTime = v"
                                  ></the-time-picker>
                                </div>
                              </div>
                              <div class="d-flex justify-content-between">
                                <div class="estimate-booking-settings-body-table-main-item-width estimate-booking-settings-body-table-main-item-start">
                                  <the-time-picker :value="planner.weeks[4].afternoon.startTime"  @input="debouncedSavePlannerBoard" @changed="v => planner.weeks[4].afternoon.startTime = v"
                                  ></the-time-picker>
                                </div>
                                <div class="estimate-booking-settings-body-table-main-item-width estimate-booking-settings-body-table-main-item-end">
                                  <the-time-picker :value="planner.weeks[4].afternoon.endTime"  @input="debouncedSavePlannerBoard" @changed="v => planner.weeks[4].afternoon.endTime = v"
                                  ></the-time-picker>
                                </div></div>
                            </div>
                          </div>
                        </div>

                        <div class="estimate-booking-settings-body-table-main">
                          <div class="estimate-booking-settings-body-table-main-item d-flex justify-content-between" style="margin-top: 10px">
                            <div class="estimate-booking-settings-body-table-main-item-width estimate-booking-settings-body-table-main-item-day">Friday</div>
                            <div class="d-flex" style="flex-direction: column">
                              <div class="d-flex justify-content-between">
                                <div class="estimate-booking-settings-body-table-main-item-width estimate-booking-settings-body-table-main-item-start">
                                  <the-time-picker :value="planner.weeks[5].morning.startTime"  @input="debouncedSavePlannerBoard" @changed="v => planner.weeks[5].morning.startTime = v"
                                  ></the-time-picker>
                                </div>
                                <div class="estimate-booking-settings-body-table-main-item-width estimate-booking-settings-body-table-main-item-end">
                                  <the-time-picker :value="planner.weeks[5].morning.endTime"  @input="debouncedSavePlannerBoard" @changed="v => planner.weeks[5].morning.endTime = v"
                                  ></the-time-picker>
                                </div>
                              </div>
                              <div class="d-flex justify-content-between">
                                <div class="estimate-booking-settings-body-table-main-item-width estimate-booking-settings-body-table-main-item-start">
                                  <the-time-picker :value="planner.weeks[5].afternoon.startTime" @input="debouncedSavePlannerBoard"
                                                   @changed="v => planner.weeks[5].afternoon.startTime = v"
                                  ></the-time-picker>
                                </div>
                                <div class="estimate-booking-settings-body-table-main-item-width estimate-booking-settings-body-table-main-item-end">
                                  <the-time-picker :value="planner.weeks[5].afternoon.endTime" @input="debouncedSavePlannerBoard"
                                                   @changed="v => planner.weeks[5].afternoon.endTime = v"
                                  ></the-time-picker>
                                </div></div>
                            </div>
                          </div>
                        </div>

                        <div class="estimate-booking-settings-body-table-main">
                          <div class="estimate-booking-settings-body-table-main-item d-flex justify-content-between" style="margin-top: 10px">
                            <div class="estimate-booking-settings-body-table-main-item-width estimate-booking-settings-body-table-main-item-day">Saturday</div>
                            <div class="d-flex" style="flex-direction: column">
                              <div class="d-flex justify-content-between">
                                <div class="estimate-booking-settings-body-table-main-item-width estimate-booking-settings-body-table-main-item-start">
                                  <the-time-picker :value="planner.weeks[6].morning.startTime" @input="debouncedSavePlannerBoard"
                                                   @changed="v => planner.weeks[6].morning.startTime = v"
                                  ></the-time-picker>
                                </div>
                                <div class="estimate-booking-settings-body-table-main-item-width estimate-booking-settings-body-table-main-item-end">
                                  <the-time-picker :value="planner.weeks[6].morning.endTime" @input="debouncedSavePlannerBoard"
                                                   @changed="v => planner.weeks[6].morning.endTime = v"
                                  ></the-time-picker>
                                </div>
                              </div>
                              <div class="d-flex justify-content-between">
                                <div class="estimate-booking-settings-body-table-main-item-width estimate-booking-settings-body-table-main-item-start">
                                  <the-time-picker :value="planner.weeks[6].afternoon.startTime"  @input="debouncedSavePlannerBoard" @changed="v => planner.weeks[6].afternoon.startTime = v"
                                  ></the-time-picker>
                                </div>
                                <div class="estimate-booking-settings-body-table-main-item-width estimate-booking-settings-body-table-main-item-end">
                                  <the-time-picker :value="planner.weeks[6].afternoon.endTime"  @input="debouncedSavePlannerBoard" @changed="v => planner.weeks[6].afternoon.endTime = v"
                                  ></the-time-picker>
                                </div></div>
                            </div>
                          </div>
                        </div>

                        <div class="estimate-booking-settings-body-table-main">
                          <div class="estimate-booking-settings-body-table-main-item d-flex justify-content-between" style="margin-top: 10px">
                            <div class="estimate-booking-settings-body-table-main-item-width estimate-booking-settings-body-table-main-item-day">Sunday</div>
                            <div class="d-flex" style="flex-direction: column">
                              <div class="d-flex justify-content-between">
                                <div class="estimate-booking-settings-body-table-main-item-width estimate-booking-settings-body-table-main-item-start">
                                  <the-time-picker :value="planner.weeks[0].morning.startTime" @input="debouncedSavePlannerBoard"
                                                   @changed="v => planner.weeks[0].morning.startTime = v"
                                  ></the-time-picker>
                                </div>
                                <div class="estimate-booking-settings-body-table-main-item-width estimate-booking-settings-body-table-main-item-end">
                                  <the-time-picker :value="planner.weeks[0].morning.endTime" @input="debouncedSavePlannerBoard"
                                                   @changed="v => planner.weeks[0].morning.endTime = v"
                                  ></the-time-picker>
                                </div>
                              </div>
                              <div class="d-flex justify-content-between">
                                <div class="estimate-booking-settings-body-table-main-item-width estimate-booking-settings-body-table-main-item-start">
                                  <the-time-picker :value="planner.weeks[0].afternoon.startTime"  @input="debouncedSavePlannerBoard" @changed="v => planner.weeks[0].afternoon.startTime = v"
                                  ></the-time-picker>
                                </div>
                                <div class="estimate-booking-settings-body-table-main-item-width estimate-booking-settings-body-table-main-item-end">
                                  <the-time-picker :value="planner.weeks[0].afternoon.endTime"  @input="debouncedSavePlannerBoard" @changed="v => planner.weeks[0].afternoon.endTime = v"
                                  ></the-time-picker>
                                </div></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="estimate-booking-header-close"><i class='bx bx-x clickable'
                                                            @click="isEstimateBooking = false; showMenuSettingsOnSiteEstimateBooking = false"></i>
              </div>
            </div>
            <div class="estimate-booking-body">
              <div class="estimate-booking-body-search">
                <b-form-input
                    v-model="filter"
                    type="text"
                    class="form-control search-input"
                    placeholder="Search or Filter results"
                    debounce="300"
                ></b-form-input>
              </div>
              <div class="estimate-booking-body-main d-flex">
                <div class="estimate-booking-body-main-left" @scroll="removeAllDraggableElements">
                  <template v-for="card in computedCardsForBooking">
                    <div class="estimate-booking-body-main-item noselect"
                         :id="'card-' + card.card_id"
                         :class="{'estimate-booking-body-main-item-active': activeCard == card.card_id, 'blocked-estimate-booking-item': isAlreadyAddedOnSiteEvent(card.card_id)}"
                         @mousemove="onMouseMove($event, 'card-' + card.card_id)"
                         @mousedown="onMouseDown('card-' + card.card_id)"
                         @mouseup="onMouseUp($event, 'card-' + card.card_id)"
                         @click="selectCard(card.card_id)"
                    >
                      <div class="estimate-booking-body-main-item-title">{{ card.card_number }}
                        {{ card.customer_name }}
                      </div>
                      <div class="estimate-booking-body-main-item-info d-flex">
                        <div v-if="card.rego_number" class="estimate-booking-body-main-item-info-num">
                          {{ card.rego_number }}
                        </div>
                        <div :class="{'estimate-booking-body-main-item-info-name': !!card.rego_number}"> {{ card.make }}
                          {{ card.model }}
                        </div>
                      </div>
                    </div>
                  </template>

                </div>
                <div class="estimate-booking-body-main-right"
                     :class="{'estimate-booking-body-main-right-active': activeCard}"
                     v-if="getCardById(activeCard)"
                >
                  <div class="estimate-booking-body-main-right-title"><span style="cursor: pointer" @click="redirectToCard(activeCard)">{{ getCardById(activeCard).card_number }}
                   </span> {{ getCardById(activeCard).customer_name }}
                  </div>
                  <div class="estimate-booking-body-main-item-info estimate-booking-body-main-right-item d-flex">
                    <div v-if="getCardById(activeCard).rego_number" class="estimate-booking-body-main-item-info-num">
                      {{ getCardById(activeCard).rego_number }}
                    </div>
                    <div :class="{'estimate-booking-body-main-item-info-name': !!getCardById(activeCard).rego_number}">
                      {{ getCardById(activeCard).make }} {{ getCardById(activeCard).model }}
                    </div>
                  </div>
                  <div v-if="getCardById(activeCard).insurance" class="estimate-booking-body-main-item-info estimate-booking-body-main-right-item d-flex">
                      <div class="estimate-booking-body-main-item-info-num">{{getCardById(activeCard).insurance}}</div>
                    </div>
                    <div class="estimate-booking-body-main-right-conflicts estimate-booking-body-main-right-item">
                      <span v-if="isConflict" style="color: red">Conflicts</span>
                      <span v-else style="color: green">No-Conflicts</span>
                    </div>
                    <div class="estimate-booking-body-main-right-item">
                      <div class="estimate-booking-body-main-right-item-title">Start</div>
                      <div class="estimate-booking-body-main-right-item-date-time">
                        <div style="margin-bottom: 5px">
                          <date-picker
                              v-model="activeCardEvent.dateStart"
                              @onChange="onOnSiteDateChange"
                              place-holder-text="Date"
                              :class="{'red-blinking-time': isOnSiteDateError}"
                          ></date-picker>
                        </div>
                        <div>
                          <the-time-picker-2
                            :value="activeCardEvent.timeStart ? activeCardEvent.timeStart : ''"
                            @input="onOnSiteTimeChange"
                              @changed="v => activeCardEvent.timeStart = v"
                              :class="{'red-blinking-time': isOnSiteTimeError}"
                          ></the-time-picker-2>
                      </div>

                    </div>
                  </div>
                  <div class="estimate-booking-body-main-right-item">
                    <div class="estimate-booking-body-main-right-item-title">Duration</div>
                    <multiselect
                        v-model="activeCardEvent.timeDuration"
                        :options="[15, 30, 45]"
                        :showLabels="false"
                        :option-height="29"
                        @input="onOnSiteTimeChange"
                        :max-height="203"
                        :close-on-select="true"
                        style="opacity: 1">
                      <template #singleLabel="{ option }">
                        {{ option }} Mins
                      </template>
                      <template #option="{ option }">
                        {{ option }} Mins
                      </template>
                    </multiselect>
                  </div>
                  <div class="estimate-booking-body-main-right-item">
                    <div class="estimate-booking-body-main-right-item-title">Remind Me</div>
                    <multiselect
                        v-model="computedActiveCardEventReminder"
                        :options="reminderOptions"
                        :showLabels="false"
                        :option-height="29"
                        :max-height="203"
                        :close-on-select="true"
                        :disabled="!activeCardEvent"
                        @input="debouncedUpdateOnSiteEvent"
                        placeholder="Reminder"
                        track-by="key"
                        label="value"
                        @select="opt => activeCardEvent.reminder = opt.key"
                        style="opacity: 1">

                    </multiselect>
                  </div>
                  <div class="estimate-booking-body-main-right-item">
                    <div class="estimate-booking-body-main-right-item-title">Notes</div>
                    <textarea
                        v-model="activeCardEvent.note"
                        :disabled="!activeCardEvent"
                        @change="debouncedUpdateOnSiteEvent"
                        class="estimate-booking-body-main-right-item-textarea"
                    ></textarea>
                  </div>
                  <div class="estimate-booking-body-main-right-item">
                    <div class="estimate-booking-body-main-right-item-title">Assigned To</div>
                    <div>
                      <multiselect
                          ref="genEvtPeople"
                          class="multiselect-add-people"
                          v-model="activeCardEvent.people"
                          track-by="id"
                          label="name"
                          :options="computedUsers"
                          :max-height="203"
                          :close-on-select="true"
                          :multiple="true"
                          :selectLabel="''"
                          :deselectLabel="'Remove'"
                          :searchable="true"
                          :selectedLabel="''"
                          :limit="2"
                          :limit-text="limitText"
                          :disabled="!activeCardEvent"
                            @input="debouncedUpdateOnSiteEvent(true)"
                        />
                      </div>
                    </div>
                    <div class="estimate-booking-body-main-right-item">
                      <div class="estimate-booking-body-main-right-item-title">Attended Booking</div>
                      <div class="d-flex">
                        <div class="checkbox-p">
                          <label for="insurance_repair_y" class="form-check-inline checkbox d-flex">
                            <input class="form-check-input" type="checkbox" name="insurance_repair"
                                   id="insurance_repair_y"
                                   v-model="isAttendedBookingYes"
                                   @change="checkIsAttendYes(isAttendedBookingYes)"
                                   :disabled="!activeCardEvent">
                            <span class="icon"><i class='bx bx-check'></i></span><span class="text" style="margin-right: 10px;">Yes</span>
                          </label>
                        </div>
                        <div class="checkbox-p">
                          <label for="insurance_repair_n" class="form-check-inline checkbox d-flex">
                            <input class="form-check-input" type="checkbox" name="insurance_repair"
                                   id="insurance_repair_n"
                                   v-model="isAttendedBookingNo"
                                   @change="checkIsAttendNo(isAttendedBookingNo)"
                                   :disabled="!activeCardEvent" hidden>
                            <span class="icon"><i class='bx bx-check'></i></span><span class="text">No</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="estimate-booking-body-main-right-item">
                      <div class="estimate-booking-body-main-right-item-title">Non-attendance Reason</div>
                      <div>
                        <multiselect
                            class="multiselect-add-people"
                            v-model="activeCardEvent.reason"
                            :options="reasons"
                            :disabled="activeCardEvent.isAttendedBooking || !activeCardEvent"
                            :max-height="203"
                            :close-on-select="true"
                            :showLabels="false"
                            @input="debouncedUpdateOnSiteEvent(true)"
                        />
                      </div>
                    </div>
                    <div class="estimate-booking-body-main-right-item">
                      <div class="estimate-booking-body-main-right-item-title">Call Back Attempts</div>
                      <div>
                        <multiselect
                            class="multiselect-add-people"
                            v-model="activeCardEvent.attempt"
                            :disabled="activeCardEvent.isAttendedBooking || !activeCardEvent"
                            :options="attempts"
                            :max-height="203"
                            :showLabels="false"
                            :close-on-select="true"
                          @input="debouncedUpdateOnSiteEvent(true)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </tabs>

    <modal-wizard ref="bookingEventModal" @on-close="closeEditModal" @on-complete="addBookingEvent"
                  :hideFinishButton="true" :hideCancelButton="true">
      <modal-wizard-tab>
        <h4 v-if="isOpenEditVehicleBookingEvent">Edit Vehicle Booking</h4>
        <h4 v-else>Add Vehicle Booking</h4>
        <template>
          <div class="form-group row">
            <label class="col-sm-4 col-4 p-0 col-form-label">Date Booked (Start)</label>
            <div class="col-sm-8 col-8 p-0">
              <date-picker :options="dpOptions" :visibility="'focus'"
                           v-model="newBookingEvent.dateStart"
                           placeholder="Date Booked (Start)">
              </date-picker>
            </div>
          </div>

          <div class="form-group row time-picker-btn">
            <label class="col-sm-4 col-4 p-0 col-form-label">Time</label>
            <div class="col-sm-8 col-8 p-0">
              <the-time-picker-2  :value="newBookingEvent.timeStart"  @input="debouncedSavePlannerBoard" @changed="v => bookingEventTimeChanged(v)" />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-4 p-0 col-form-label">Date Completed</label>
            <div class="col-sm-8 col-8 p-0">
              <date-picker :options="dpOptions" :visibility="'focus'"
                           v-model="newBookingEvent.dateEnd"
                           placeholder="Date Completed">
              </date-picker>
            </div>
          </div>

          <div class="form-group row time-picker-btn">
            <label class="col-sm-4 col-4 p-0 col-form-label">Time</label>
            <div class="col-sm-8 col-8 p-0">
              <the-time-picker-2 :value="newBookingEvent.timeEnd" @input="debouncedSavePlannerBoard"
                               @changed="v => newBookingEvent.timeEnd = v"/>
              <!--              <vue-timepicker  v-model="newBookingEvent.timeEnd" drop-direction="up"/>-->
            </div>
          </div>

          <div class="form-group row reminder-multiselect">
            <label class="col-sm-4 col-4 p-0 col-form-label">Reminder</label>
            <div class="col-sm-8 col-8 p-0">
              <multiselect
                  v-model="computedBookingReminder"
                  :options="reminderOptions"
                  :showLabels="false"
                  :option-height="29"
                  :max-height="203"
                  :close-on-select="true"
                  placeholder="Reminder"
                  track-by="key"
                  label="value"
                  @select="opt => newBookingEvent.reminder = opt.key"
                  style="opacity: 1">

              </multiselect>
            </div>
          </div>

          <div class="form-group row reminder-multiselect"
               style="border-bottom: 1px solid rgba(27, 30, 56, 0.25); height: auto; min-height: 50px">
            <label class="col-sm-4 col-4 p-0 col-form-label">Category</label>
            <div class="col-sm-8 col-8 p-0">
              <multiselect
                  v-model="computedBookingCategory"
                  :options="categoryOptions"
                  :showLabels="false"
                  :option-height="29"
                  :max-height="203"
                  :close-on-select="true"
                  placeholder="Category"
                  track-by="id"
                  label="name"
                  style="opacity: 1">
                <template slot="singleLabel" slot-scope="props">
                  <span style="transform: translateY(3px);" class="categories-menu-color-icon"
                        :style="{backgroundColor: props.option.color}"></span> <span>{{ props.option.name }}</span>
                </template>
                <template slot="option" slot-scope="props">
                  <span style="transform: translateY(3px);" class="categories-menu-color-icon"
                        :style="{backgroundColor: props.option.color}"></span> <span>{{ props.option.name }}</span>
                </template>
              </multiselect>
            </div>
          </div>

          <div class="form-group row pt-1">
            <label class="col-sm-4 col-4 p-0 col-form-label">Rego</label>
            <div class="col-sm-8 col-8 p-0 adv-search-block">
              <advanced-search
                  v-model="newBookingEvent.vehicleRego"
                  :searchData="searchData"
                  :searchKey="'rego'"
                  :sortByName="'rego'"
                  @onSelect="onRegoSelect"
                  placeholder="Rego">
              </advanced-search>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-4 p-0 col-form-label">Make</label>
            <div class="col-sm-8 col-8 p-0">
              <input v-model="newBookingEvent.vehicleMake" id="make" type="text" class="form-control"
                     placeholder="Make">
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-4 p-0 col-form-label">Model</label>
            <div class="col-sm-8 col-8 p-0">
              <input v-model="newBookingEvent.vehicleModel" id="model" type="text" class="form-control"
                     placeholder="Model">
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-4 p-0 col-form-label">Vehicle Owner</label>
            <div class="col-sm-8 col-8 p-0">
              <input v-model="newBookingEvent.vehicleOwnerName" id="vehicle-owner" type="text" class="form-control"
                     placeholder="Vehicle Owner">
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-4 p-0 col-form-label">Card Nbr</label>
            <div class="col-sm-8 col-8 p-0 adv-search-block">
              <advanced-search
                  v-model="newBookingEvent.cardNumber"
                  :searchData="searchData"
                  :searchKey="'cardNumber'"
                  :sortByName="'cardNumber'"
                  @onSelect="onCardNumberSelect"
                  placeholder="Card Nbr">
              </advanced-search>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-4 p-0 col-form-label">Notes</label>
            <div class="col-sm-8 col-8 p-0">
              <textarea id="details" v-model="newBookingEvent.note" style="height: 100px !important;"
                        class="form-control" name="" cols="30" rows="10"></textarea>
            </div>
          </div>

          <div class="form-group row" style="padding-bottom: 70px;">
            <label class="col-sm-4 col-4 p-0 col-form-label">Boards</label>
            <div class="col-sm-8 col-8 p-0">
              <multiselect
                  v-model="newBookingEvent.boards"
                  :options="['Vehicle in Board', 'Departure Board']"
                  :max-height="203"
                  :multiple="true"
                  :selectLabel="''"
                  :deselectLabel="'Remove'"
                  :searchable="false"
                  :selectedLabel="''"
              />
            </div>
          </div>

          <div class="form-group row planner-modal-btn">
            <button @click="$refs.bookingEventModal.hide()" class="btn btn-secondary mr-1" style="width: 100px;">
              Cancel
            </button>
            <button @click="onClickSaveBookingEvent" class="btn btn-primary" style="width: 100px; color: white;">Save
            </button>
          </div>

          <div class="modal-padding-bottom" :style="{height: (modalPaddingBottom - 132) + 'px', }"></div>
        </template>
      </modal-wizard-tab>
    </modal-wizard>

    <modal-wizard ref="generalEventModal" @on-close="closeEditModal" @on-complete="addGeneralEvent"
                  :hideFinishButton="true" :hideCancelButton="true">
      <modal-wizard-tab>
        <h4 v-if="isOpenEditGeneralEvent && newGeneralEvent.isAllDayEvent">Edit All Day Event</h4>
        <h4 v-else-if="isOpenEditGeneralEvent">Edit General Event</h4>
        <h4 v-else-if="newGeneralEvent.isAllDayEvent">Add All Day Event</h4>
        <h4 v-else>Add General Event</h4>
        <template>
          <div class="form-group row">
            <label class="col-sm-4 col-4 p-0 col-form-label">Title</label>
            <div class="col-sm-8 col-8 p-0">
              <input
                  ref="genEvtTitle"
                  @keydown.down="onKeyPressDown('genEvtStartDate')"
                  @keydown.up="onKeyPressUp('genEvtPeople')"
                  id=""
                  v-model="newGeneralEvent.title"
                  type="text"
                  class="form-control"
                  placeholder="Title">
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-4 p-0 col-form-label">Start Date</label>
            <div class="col-sm-8 col-8 p-0">
              <date-picker
                  ref="genEvtStartDate"
                  :options="dpOptions"
                  @onKeyPressDown="onKeyPressDown('genEvtTimeStart')"
                  @onKeyPressUp="onKeyPressUp('genEvtTitle')"
                  @input="onInputGeneralStartDate"
                  v-model="newGeneralEvent.dateStart"
                  :visibility="'focus'"
                  placeholder="Start">
              </date-picker>
            </div>
          </div>

          <div class="form-group row time-picker-btn" v-if="!newGeneralEvent.isAllDayEvent">
            <label class="col-sm-4 col-4 p-0 col-form-label">Time</label>
            <div class="col-sm-8 col-8 p-0">
              <the-time-picker-2 :value="newGeneralEvent.timeStart" @input="debouncedSavePlannerBoard"
                               @changed="v => generalEventTimeChanged(v)"/>
              <!--              <vue-timepicker-->
              <!--                  ref="genEvtTimeStart"-->
              <!--                  @change="generalEventTimeChanged"-->
              <!--                  v-model="newGeneralEvent.timeStart"-->
              <!--                  drop-direction="up"/>-->
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-4 p-0 col-form-label">End Date</label>
            <div class="col-sm-8 col-8 p-0">
              <date-picker
                  ref="genEvtEndDate"
                  @onKeyPressDown="onKeyPressDown('genEvtTimeEnd')"
                  @onKeyPressUp="onKeyPressUp('genEvtTimeStart')"
                  :options="dpOptions"
                  v-model="newGeneralEvent.dateEnd"
                  :visibility="'focus'"
                  placeholder="End">
              </date-picker>
            </div>
          </div>

          <div class="form-group row time-picker-btn" v-if="!newGeneralEvent.isAllDayEvent">
            <label class="col-sm-4 col-4 p-0 col-form-label">Time</label>
            <div class="col-sm-8 col-8 p-0">
              <the-time-picker-2 :value="newGeneralEvent.timeEnd" @input="debouncedSavePlannerBoard"
                               @changed="v => newGeneralEvent.timeEnd = v"/>
              <!--              <vue-timepicker-->
              <!--                  ref="genEvtTimeEnd"-->
              <!--                  v-model="newGeneralEvent.timeEnd"-->
              <!--                  drop-direction="up"/>-->
            </div>
          </div>

          <div class="form-group row reminder-multiselect">
            <label class="col-sm-4 col-4 p-0 col-form-label">Reminder</label>
            <div class="col-sm-8 col-8 p-0">
              <multiselect
                  ref="genEvtReminder"
                  v-model="computedGeneralReminder"
                  :options="reminderOptions"
                  :showLabels="false"
                  :option-height="29"
                  :max-height="203"
                  :close-on-select="true"
                  placeholder="Reminder"
                  track-by="key"
                  label="value"
                  @select="opt => newGeneralEvent.reminder = opt.key"
                  style="opacity: 1">

              </multiselect>
            </div>
          </div>

          <div class="form-group row reminder-multiselect"
               :style="{borderBottom: newGeneralEvent.isAllDayEvent ? '' : '1px solid rgba(27, 30, 56, 0.25)'}"
               style="min-height: 50px; height: auto">
            <label class="col-sm-4 col-4 p-0 col-form-label">Category</label>
            <div class="col-sm-8 col-8 p-0">
              <multiselect
                  ref="genEvtCategory"
                  v-model="computedGeneralCategory"
                  :options="categoryOptions"
                  :showLabels="false"
                  :option-height="29"
                  :max-height="203"
                  :close-on-select="true"
                  placeholder="Category"
                  track-by="id"
                  label="name"
                  :hide-selected="true"
                  style="opacity: 1">
                <template slot="singleLabel" slot-scope="props">
                  <span style="transform: translateY(3px);" class="categories-menu-color-icon"
                        :style="{backgroundColor: props.option.color}"></span> <span>{{ props.option.name }}</span>
                </template>
                <template slot="option" slot-scope="props">
                  <span style="transform: translateY(3px);" class="categories-menu-color-icon"
                        :style="{backgroundColor: props.option.color}"></span> <span>{{ props.option.name }}</span>
                </template>
              </multiselect>
            </div>
          </div>

          <div class="form-group row " v-if="newGeneralEvent.isAllDayEvent"
               style="border-bottom: 1px solid rgba(27, 30, 56, 0.25); min-height: 50px; height: auto">
            <label class="col-sm-4 col-4 p-0 col-form-label">Work Day Event</label>
            <div class="col-sm-8 col-8 p-0 d-flex">
              <div class="checkbox-p">
                <label class="form-check-inline radio" style="width: 76px;">
                  <input class="form-check-input" type="radio" name="workDay" id="yes"
                         v-model="newGeneralEvent.isWorkDay"
                         :value="true">
                  <span class="icon"><i class='bx bx-check'></i></span><span class="text">Yes</span>
                </label>
              </div>
              <div class="checkbox-p">
                <label class="form-check-inline radio">
                  <input class="form-check-input" type="radio" name="workDay"  v-model="newGeneralEvent.isWorkDay" id="no"
                         :value="false">
                  <span class="icon"><i class='bx bx-check'></i></span><span class="text">No</span>
                </label>
              </div>
            </div>
          </div>

          <div class="form-group row pt-1">
            <label class="col-sm-4 col-4 p-0 col-form-label">Location</label>
            <div class="col-sm-8 col-8 p-0">
              <input
                  ref="genEvtLocation"
                  @keydown.down="onKeyPressDown('genEvtCreatedBy')"
                  @keydown.up="onKeyPressUp('genEvtCategory')"
                  v-model="newGeneralEvent.location"
                  id="location"
                  type="text"
                  class="form-control"
                  placeholder="Location">
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-4 p-0 col-form-label">Created By</label>
            <div class="col-sm-8 col-8 p-0">
              <input
                  ref="genEvtCreatedBy"
                  id="created-by"
                  @keydown.down="onKeyPressDown('genEvtCardNbr')"
                  @keydown.up="onKeyPressUp('genEvtLocation')"
                  v-model="newGeneralEvent.createdBy"
                  type="text"
                  class="form-control"
                  placeholder="Name"
                  readonly>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-4 p-0 col-form-label">Card Nbr</label>
            <div class="col-sm-8 col-8 p-0 adv-search-block">
              <advanced-search
                  ref="genEvtCardNbr"
                  v-model="newGeneralEvent.cardNumber"
                  :searchData="searchData"
                  :searchKey="'cardNumber'"
                  :sortByName="'cardNumber'"
                  @onSelect="value => newGeneralEvent.cardNumber = value.cardNumber"
                  placeholder="Card Nbr">
              </advanced-search>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-4 p-0 col-form-label">Notes</label>
            <div class="col-sm-8 col-8 p-0">
              <textarea
                  ref="genEvtNotes"
                  id="details"
                  @keydown.down="onKeyPressDown('genEvtPeople')"
                  @keydown.up="onKeyPressUp('genEvtCardNbr')"
                  v-model="newGeneralEvent.note"
                  style="height: 100px !important;"
                  class="form-control"
                  name=""
                  cols="30"
                  rows="10"></textarea>
            </div>
          </div>

          <div class="form-group row" style="padding-bottom: 70px;">
            <label class="col-sm-4 col-4 p-0 col-form-label">Add People</label>
            <div class="col-sm-8 col-8 p-0">
              <multiselect
                  ref="genEvtPeople"
                  class="multiselect-add-people"
                  v-model="newGeneralEvent.people"
                  track-by="id"
                  label="name"
                  :options="computedUsers"
                  :max-height="203"
                  :close-on-select="false"
                  :multiple="true"
                  :selectLabel="''"
                  :deselectLabel="'Remove'"
                  :searchable="true"
                  :selectedLabel="''"
                  :limit="2"
                  :limit-text="limitText"
              />
            </div>
          </div>

          <div class="form-group row planner-modal-btn">
            <button @click="$refs.generalEventModal.hide()" class="btn btn-secondary mr-1" style="width: 100px;">
              Cancel
            </button>
            <button @click="onClickSaveGeneralEvent" class="btn btn-primary" style="width: 100px; color: white;">Save
            </button>
          </div>

          <div class="modal-padding-bottom" :style="{height: (modalPaddingBottom + 28) + 'px', }"></div>
        </template>
      </modal-wizard-tab>
    </modal-wizard>
    <modal-wizard class="delete-supp-modal overbooked-modal" ref="overbookedModal" finishButtonText="Continue">
      <modal-wizard-tab title="Overbooked Confirmation">
        <div style="margin-bottom: 15px; margin-top:5px">
          Adding the job start on this day has exceed the target limit per / day and you well be <span
            style="font-weight: 800; color: red; text-transform: uppercase">overbooked.</span>
        </div>
        <div class="form-group row planner-modal-btn" style="border-top: none;">
          <button @click="$refs.overbookedModal.hide()" class="btn btn-secondary mr-1" style="width: 100px;">
            Cancel
          </button>
          <button @click="addVehicleBookingNewFromModal" class="btn btn-primary" style="width: 100px; color: white;">Continue
          </button>
        </div>
      </modal-wizard-tab>
    </modal-wizard>
    <b-tooltip
        :target="'test1'"
        triggers="hover"
        placement="bottomright"
        custom-class="ps-tooltip collapse-tooltip">
      Collapse/Expand Vehicles Bookings
    </b-tooltip>
  </div>
</template>

<script>
import DatePicker from '../utility/date-picker';
import TheTimePicker from '../utility/time-picker';
import TheTimePicker2 from '../utility/time-picker-2';
    import Axios from "axios";
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue';
import Multiselect from 'vue-multiselect';
import {mapGetters} from "vuex";
import _ from 'lodash';
import AdvancedSearch from '../utility/advanced-search';
import moment from 'moment';
import {appConfig} from "../../config";
import dayjs from 'dayjs'
    import BookingSnapshot from "./booking-snapshot/booking-snapshot";
import NumberFormatter from "@/components/utility/number-formatter.vue";

let isBetween = require("dayjs/plugin/isBetween");
dayjs.extend(isBetween)
import VehicleBooking from './planner-menu/vehicle-booking'
import {isMobileOnly} from "@/deviceDetect";
import Planner from "@/components/boards/planner/planner.vue";

export default {
  name: "board",
  data() {
    return {
      settings: {
        "definitions": {
          "categories": [],
          "numberOfDays": {
            "weld": null,
            "mechanical": null,
            "hail": null,
            "electrical": null,
            "ppf": null,
            "accessories": null
          }
        },
        "days": [
          false,
          true,
          true,
          true,
          true,
          true,
          false,
        ],
        "targets": {
          "job": null,
          "labour": null
        }
      },
      isVehicleBooking: false,
      planner: {
        statuses: [],
        timeDuration: 0,
        maxNbrPerSlot: 0,
        weeks: {
          0: {
            morning: {
              startTime: null,
              endTime: null,
            },
            afternoon: {
              startTime: null,
              endTime: null,
            }
          },
          1: {
            morning: {
              startTime: null,
              endTime: null,
            },
            afternoon: {
              startTime: null,
              endTime: null,
            }
          },
          2: {
            morning: {
              startTime: null,
              endTime: null,
            },
            afternoon: {
              startTime: null,
              endTime: null,
            }
          },
          3: {
            morning: {
              startTime: null,
              endTime: null,
            },
            afternoon: {
              startTime: null,
              endTime: null,
            }
          },
          4: {
            morning: {
              startTime: null,
              endTime: null,
            },
            afternoon: {
              startTime: null,
              endTime: null,
            }
          },
          5: {
            morning: {
              startTime: null,
              endTime: null,
            },
            afternoon: {
              startTime: null,
              endTime: null,
            }
          },
          6: {
            morning: {
              startTime: null,
              endTime: null,
            },
            afternoon: {
              startTime: null,
              endTime: null,
            }
          },
        }
      },
      activeCard: null,
      isAttendedBookingYes: false,
      isAttendedBookingNo: false,
      isUpdatedFromUpdater: false,
      isOnSiteDateError: false,
      isOnSiteTimeError: false,
      activeCardEvent: {},
      showMenuSettingsOnSiteEstimateBooking: false,
      groups: [],
      filteredGroups: [],
      filterCategoryName: '',
      currentColor: "",
      id: -11,
      isSetTimeByEditMode: false,
      isShowOpenTimeslots: false,
      isMouseDown: false,
      focus: new Date(),
      start: "2000-03-01",
      end: "2070-03-01",
      isShowDropdown: false,
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      shortMonths: ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May', 'June', 'July', 'Aug.', 'Sept.', 'Oct.', 'Nov.', 'Dec.'],
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1', '#2196F3', '#3F51B5', '#673AB7', '#00BCD4', '#4CAF50', '#FF9800', '#757575'],
      selectedOpen: false,
      selectedElement: null,
      value: [],
      weekday: [1, 2, 3, 4, 5, 6, 0],
      selectedEvent: {},
      isOpenEditVehicleBookingEvent: false,
      isOpenEditGeneralEvent: false,
      eventsBeforeDragg: [],
      events: [
        // {
        //   name: "test1",
        //   start: "2022-03-22 09:00",
        //   end: "2022-03-24 10:00",
        //   color: 'cyan',
        // },
        // {
        //   name: "test2",
        //   start: "2022-03-11",
        //   someInformation: {
        //     cardId: 'c3343'
        //   },
        //   color: 'indigo',
        // },
        // {
        //   name: "test3",
        //   start: "2022-03-05 12:30",
        //   end: "2022-03-05 12:30",
        //   color: 'blue',
        // },
      ],
      isEstimateBooking: false,
      isCollapsed: false,
      isFullScreen: false,
      newGeneralEvent: {
        "dateStart": "", // required
        "timeStart": "", // required
        "dateEnd": "", // required
        "timeEnd": "", // required
        "title": "", // required
        "note": "", // optional
        "color": "", // optional
        "cardNumber": "", // optional
        reminder: null,
        isAllDayEvent: false,
        isWorkDay: true,
        location: '',
        createdBy: '',
        category: null,
      },
      lastDraggedEvent: null,
      createdOnSiteCard: null,
      newBookingEvent: {
        "cardId": null, // required
        "dateStart": "", // required
        "timeStart": "", // required
        "dateEnd": "", // required
        "timeEnd": "", // required
        "title": "", // optional
        "note": "", // optional
        "color": "", // optional

        boards: [],
        cardNumber: '',
        vehicleMake: '',
        vehicleRego: '',
        vehicleModel: '',
        vehicleOwnerName: '',
        reminder: null,
        category: null,
      },
      reminderOptions: [
        {
          key: 300,
          value: '5 mins'
        },
        {
          key: 600,
          value: '10 mins'
        },
        {
          key: 900,
          value: '15 mins'
        },
        {
          key: 1800,
          value: '30 mins'
        },
        {
          key: 3600,
          value: '1 hour'
        },
        {
          key: 7200,
          value: '2 hours'
        },
        {
          key: 14400,
          value: '4 hours'
        },
        {
          key: 86400,
          value: '1 day'
        },
      ],

      intervalUpdateTitle: null,
      isCalendarDisplaying: false,
      isPlannerDisplaying: false,
      isBookingsSnapshot: false,
      ceStatuses: [],
      isOverbookedArray: [],
      mouseDownTime: 0,
      dialog: false,
      selectedId: null,
      mouseDownTimeInterval: null,

      dpOptions: {
        autoclose: true,
        todayHighlight: true,
        format: 'yyyy/mm/dd',
      },

      names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
      dragEvent: null,
      dragStart: null,
      createEvent: null,
      filter: '',
      createStart: null,
      extendOriginal: null,
      vehicleAddData: null,
      modalPaddingBottom: 0,
      callComputed:0,
          weekdays: [1, 2, 3, 4, 5],
          attempts: [],
          reasons: [],
          intervalHeight: 48,
        }
      },
      computed: {
        ...mapGetters({
          getterAllCards: 'card/getAllCards',
        userInfo: 'userInfo',
        getterBoardIn: 'board/getBoardIn',
          getterProduction: 'board/getProduction',
          getterAttempts: 'board/getAttempts',
          getterReasons: 'board/getReasons',
        getterBoardDeparture: 'board/getBoardDeparture',
        getBookingItems: 'board/getBookingItems',
        getterVisibleUsers: 'visibleUsers',
        getterPlannerBoardType: 'getPlannerBoardType',
        vendorInfo: 'vendorInfo',
          isLoadedPrev: 'board/isLoadedPrev',
      }),
        computedEvtsAfterLimit() {
          let days = []
          if (this.getterPlannerBoardType == 'week' && this.isCalendarDisplaying && this.cal) {
            days = [
              dayjs(this.$refs.calendar.value).isoWeekday(1).format('YYYY-MM-DD'),
              dayjs(this.$refs.calendar.value).isoWeekday(2).format('YYYY-MM-DD'),
              dayjs(this.$refs.calendar.value).isoWeekday(3).format('YYYY-MM-DD'),
              dayjs(this.$refs.calendar.value).isoWeekday(4).format('YYYY-MM-DD'),
              dayjs(this.$refs.calendar.value).isoWeekday(5).format('YYYY-MM-DD'),
              dayjs(this.$refs.calendar.value).isoWeekday(6).format('YYYY-MM-DD'),
              dayjs(this.$refs.calendar.value).isoWeekday(7).format('YYYY-MM-DD'),
            ]
            days = _.filter(days, (d) => {
              return this.settings.days[dayjs(d).day()]
            })
          }
          if (this.getterPlannerBoardType == 'day' && this.isCalendarDisplaying && this.cal) {
            days = [dayjs(this.$refs.calendar.value).format('YYYY-MM-DD'),]
          }

          let r = []
          _.forEach(days, (d) => {
            if (this.isOverbookedByTotal(d) && this.computedDayTotal) {
              let evts = _.filter(this.computedEvents, (event) => {
                return event?.card?.id && event.type == 'vehicle'
                    && dayjs(d).isBetween( dayjs(event.dateStart), dayjs(event.dateEnd), null, '[]')
              })
              evts = _.orderBy(evts, ['id'], ['asc'])
              let count = 0
              let arrayAfterLimit = [];
              _.forEach(evts, (evt) => {
                let estimate = evt.estimate
                if (count && count > this.computedDayTotal) {
                  arrayAfterLimit.push(evt)
                }
                if (estimate.amountJob && this.getDayAppr(evt)) {
                  count += Number(estimate.amountJob) / this.getDayAppr(evt)
                }

              })
              r = _.concat(r, arrayAfterLimit)
            }
          })

          return _.map(_.unionBy(r, 'id'), (itm) => {return itm.id})
        },
        computedCalendarValue() {
          let value = this.$refs?.calendar?.value;
          if (!value) {
            if (this.focus) {
              if (_.isDate(this.focus)) {
                return dayjs(value).format('YYYY-MM-DD')
              } else if (_.isString(this.focus)) {
                return this.focus
              }
            }
            return null
          }
          if (_.isDate(value)) {
            value = dayjs(value).format('YYYY-MM-DD')
          }
          return value
        },
        computedAllDayEventsPeriod() {
          if (this.getterPlannerBoardType == 'week' && this.isCalendarDisplaying && this.cal) {
            let days = [
              dayjs(this.$refs.calendar.value).isoWeekday(1).format('YYYY-MM-DD'),
              dayjs(this.$refs.calendar.value).isoWeekday(2).format('YYYY-MM-DD'),
              dayjs(this.$refs.calendar.value).isoWeekday(3).format('YYYY-MM-DD'),
              dayjs(this.$refs.calendar.value).isoWeekday(4).format('YYYY-MM-DD'),
              dayjs(this.$refs.calendar.value).isoWeekday(5).format('YYYY-MM-DD'),
              dayjs(this.$refs.calendar.value).isoWeekday(6).format('YYYY-MM-DD'),
              dayjs(this.$refs.calendar.value).isoWeekday(7).format('YYYY-MM-DD'),
            ]
            let events = _.filter(this.computedEvents, (e) => {
              return e.type == 'all-day' && !e.isWorkDay
            })

            return _.filter(days, (d) => {
              return _.some(events, (e) => {
                return dayjs(d).isBetween( e.dateStart, e.dateEnd, null, '[]')
              })
            })
          }
          return []
        },
        computedOnSitEventsIds() {
          let data = _.filter(_.cloneDeep(this.events), (evt) => {
            return evt?.card?.id && evt.type == 'on-site'
          })
          let result = _.map(data, (e) => {
            return e?.card?.id
          })
          result = _.uniq(result)
          return result
        },

        computedWeekDays() {
         this.callComputed
         let arr = [1, 2, 3, 4, 5, 6, 0]
         if (screen.orientation.type == 'portrait-primary') {
           arr = [1, 2, 3, 4, 5]
         }
         return _.filter(arr, (itm) => {
           return this.settings.days[itm]
         })
       },
    computedDayTotal() {
      let count = _.filter(this.settings.days, (d) => {
        return d
      }).length
      return Number(this.settings.targets.job) / count
    },
    isConflict() {
      if (!this.activeCardEvent) {
        return false
      }
      let originalEvent = _.find(this.events, (evt) => {
        return Number(evt.id) == Number(this.activeCardEvent.id)
      })
      if (originalEvent) {
        let timeDuration = null
        let difference = dayjs(originalEvent.dateEnd + ' ' + originalEvent.timeEnd).diff(originalEvent.dateStart + ' ' + originalEvent.timeStart, 'minute')
        if (difference == 15 || difference == 30 || difference == 45) {
          timeDuration = difference
        } else {
          timeDuration = this.planner.timeDuration
        }

        if (originalEvent.timeStart != this.activeCardEvent.timeStart || originalEvent.dateStart != this.convertDate(this.activeCardEvent.dateStart) || timeDuration != this.activeCardEvent.timeDuration) {
          let start = dayjs(new Date(new Date(this.convertDate(this.activeCardEvent.dateStart) + ' ' + this.activeCardEvent.timeStart)))
          let end = start.add(this.planner.timeDuration, 'minute').format('YYYY-MM-DD HH:mm:ss')
          start = start.format('YYYY-MM-DD HH:mm:ss')

          let countOfEvents = _.filter(this.events, (evt) => {
            return evt.type == 'on-site' && dayjs(evt.dateStart + ' ' + evt.timeStart).isBetween(start, end, null, '[]') && dayjs(evt.dateEnd + ' ' + evt.timeEnd).isBetween(start, end, null, '[]')
          })?.length

          if (countOfEvents >= this.planner.maxNbrPerSlot) {
            return true
          }
        }
      }
      return false
    },
    computedCeStatuses() {
      return this.ceStatuses.map((el => {
        return el.ce_status_id
      }))
    },
    computedCardsForBooking() {
      return _.filter(this.getterAllCards, (c) => {
        let b = false;
        if (_.isEmpty(this.filter)) {
          b = true
        } else {
          let formattedFilter = _.trim(_.toLower(this.filter))

          if (c.card_number && _.includes(_.trim(_.toLower(c.card_number)), formattedFilter)) {
            b = true;
          }
          if (c.estimate_number && _.includes(_.trim(_.toLower(c.estimate_number)), formattedFilter)) {
            b = true;
          }
          if (c.make && _.includes(_.trim(_.toLower(c.make)), formattedFilter)) {
            b = true;
          }
          if (c.model && _.includes(_.trim(_.toLower(c.model)), formattedFilter)) {
            b = true;
          }
          if (c.customer_name && _.includes(_.trim(_.toLower(c.customer_name)), formattedFilter)) {
            b = true;
          }
          if (c.rego_number && _.includes(_.trim(_.toLower(c.rego_number)), formattedFilter)) {
            b = true;
          }
        }
        return _.includes(this.planner.statuses, c.card_status) && b
      })
    },
    computedUsers() {
      return _.orderBy(this.getterVisibleUsers, [(itm) => {
        return itm.name.toLowerCase()
      }], ['asc']);
    },
    categoryOptions() {
      let groups = this.groups.slice();
      let emptyCategoryIndex = _.findIndex(this.groups, (gr) => {
        return gr.id === null;
      })
      if (emptyCategoryIndex === -1) {
        groups.unshift({name: 'No Category', id: null, color: '#5f9fa0', isSetColor: false, isOpenColorPicker: false});
      }
      return groups;
    },
    computedBookingCategory: {
      get: function () {
        let vm = this;
        try {
          let item = _.find(this.categoryOptions, (opt) => {
            return opt.id === vm.newBookingEvent.category;
          });
          return item;
        } catch (e) {
          return {}
        }
      },
      set: function (item) {
        // this.newBookingEvent.groupId = item.id;
        this.newBookingEvent.category = item.id;
        console.log('item', item)
      }
    },
    computedGeneralCategory: {
      get: function () {
        let vm = this;
        try {
          let item = _.find(this.categoryOptions, (opt) => {
            return opt.id === vm.newGeneralEvent.category;
          });
          return item;
        } catch (e) {
          return {}
        }
      },
      set: function (item) {
        // this.newGeneralEvent.groupId = item.id;
        this.newGeneralEvent.category = item.id;
        console.log('item', item)
      }
    },
    computedBookingReminder: {
      get: function () {
        let vm = this;
        try {
          let item = _.find(this.reminderOptions, (opt) => {
            return opt.key === vm.newBookingEvent.reminder;
          });
          return item;
        } catch (e) {
          return {}
        }
      },
      set: function (item) {
        console.log('item', item)
      }
    },
    computedActiveCardEventReminder: {
      get: function () {
        let vm = this;
        try {
          let item = _.find(this.reminderOptions, (opt) => {
            return opt.key == vm.activeCardEvent.reminder;
          });
          return item;
        } catch (e) {
          return {}
        }
      },
      set: function (item) {
        console.log('item', item)
      }
    },
    computedGeneralReminder: {
      get: function () {
        let vm = this;
        try {
          let item = _.find(this.reminderOptions, (opt) => {
            return opt.key === vm.newGeneralEvent.reminder;
          });
          return item;
        } catch (e) {
          return {}
        }
      },
      set: function (item) {
        console.log('item', item)
      }
    },
    reactiveSelectedEvent() {
      if (!this.selectedEvent.id) {
        return {
          name: '',
          id: null,
          color: null,
        }
      }
      let item = _.find(this.computedEvents, e => {
        return e.id == this.selectedEvent.id;
      })
      if (item) {
        return item
      }
      return {
        name: '',
        id: null,
        color: null,
      }
    },
    searchData() {
      let result = [];
      _.forEach(this.getterAllCards, (card) => {
        result.push({
          rego: card.rego_number,
          make: card.make,
          model: card.model,
          vehicleOwner: card.customer_name,
          cardNumber: card.card_number,
          cardId: card.card_id,
        })
      });
      return result;
    },
        computedEventsForTotals() {
          let originalEvents = _.cloneDeep(this.events)

          if (this.cal && this.isCalendarDisplaying) {
            if (this.getterPlannerBoardType == 'day') {
              originalEvents = _.filter(originalEvents, (ev) => {
                return dayjs(this.computedCalendarValue).isBetween(ev.dateStart, ev.dateEnd, null, '[]')
              })
            } else if (this.getterPlannerBoardType == 'week') {
              originalEvents = _.filter(originalEvents, (ev) => {
                return dayjs(ev.dateStart).isBetween( dayjs(this.computedCalendarValue).isoWeekday(1).format('YYYY-MM-DD'),  dayjs(this.computedCalendarValue).isoWeekday(7).format('YYYY-MM-DD'), null, '[]')
                    || dayjs(ev.dateEnd).isBetween( dayjs(this.computedCalendarValue).isoWeekday(1).format('YYYY-MM-DD'),  dayjs(this.computedCalendarValue).isoWeekday(7).format('YYYY-MM-DD'), null, '[]')
                    || dayjs(dayjs(this.computedCalendarValue).isoWeekday(1).format('YYYY-MM-DD')).isBetween( ev.dateStart, ev.dateEnd, null, '[]')
              })
            } else if (this.getterPlannerBoardType == 'month') {
              originalEvents = _.filter(originalEvents, (ev) => {
                return dayjs(ev.dateStart).isBetween( dayjs(this.computedCalendarValue).startOf('month').format('YYYY-MM-DD'),  dayjs(this.computedCalendarValue).endOf('month').format('YYYY-MM-DD'), null, '[]')
                    || dayjs(ev.dateEnd).isBetween( dayjs(this.computedCalendarValue).startOf('month').format('YYYY-MM-DD'),  dayjs(this.computedCalendarValue).endOf('month').format('YYYY-MM-DD'), null, '[]')
                    || dayjs(dayjs(this.computedCalendarValue).startOf('month').format('YYYY-MM-DD')).isBetween( ev.dateStart, ev.dateEnd, null, '[]')
              })
            }
          }

          if (this.cal && this.isCalendarDisplaying && this.getterPlannerBoardType == 'day' && !this.isOpenDay(this.computedCalendarValue)) {
            originalEvents = _.filter(originalEvents, (evv) => {
              return evv.type !== 'vehicle'
            })
          }

          originalEvents = _.orderBy(originalEvents, ['id'], 'asc')

          if (this.getterPlannerBoardType === 'weekStarting') {
            let monday = this.getMonday(this.computedCalendarValue);
            originalEvents = _.filter(originalEvents, (e) => {
              return e.dateStart === monday
            })
          }
          let events = [];
          _.forEach(originalEvents, (e) => {
            let card = e?.card?.id && e?.estimate ? e.estimate : null
            if ((e.color === null || e.groupId === null) && !(card?.productionTime?.category)) {
              if (e.type == 'all-day' && !e.isWorkDay) {
                e.color = '#7030a0'
              } else {
                e.color = '#5f9fa0';
              }
            }
            e.name = e.title;
            if (card?.productionTime?.category) {
              e.color = this.getGroup(card?.productionTime?.category).color
            }

            if (e.type == 'vehicle' || e.type == 'all-day') {
              e.start = e.dateStart;
              e.end = e.dateEnd;
              e.timed = false;
            } else {
              e.start = this.toTimestamp(e.dateStart + ' ' + e.timeStart);
              e.end = this.toTimestamp(e.dateEnd + ' ' + e.timeEnd);
              e.timed = true;
            }

            events.push(e);
          });
          return events;
        },
    computedEvents() {
      let originalEvents = _.cloneDeep(this.events)

      if (this.cal && this.isCalendarDisplaying) {
        if (this.getterPlannerBoardType == 'day') {
          originalEvents = _.filter(originalEvents, (ev) => {
            return dayjs(this.computedCalendarValue).isBetween(ev.dateStart, ev.dateEnd, null, '[]')
          })
        } else if (this.getterPlannerBoardType == 'week') {
          originalEvents = _.filter(originalEvents, (ev) => {
            return dayjs(ev.dateStart).isBetween( dayjs(this.computedCalendarValue).isoWeekday(1).format('YYYY-MM-DD'),  dayjs(this.computedCalendarValue).isoWeekday(7).format('YYYY-MM-DD'), null, '[]')
                || dayjs(ev.dateEnd).isBetween( dayjs(this.computedCalendarValue).isoWeekday(1).format('YYYY-MM-DD'),  dayjs(this.computedCalendarValue).isoWeekday(7).format('YYYY-MM-DD'), null, '[]')
                || dayjs(dayjs(this.computedCalendarValue).isoWeekday(1).format('YYYY-MM-DD')).isBetween( ev.dateStart, ev.dateEnd, null, '[]')
          })
        } else if (this.getterPlannerBoardType == 'month') {
          originalEvents = _.filter(originalEvents, (ev) => {
            return dayjs(ev.dateStart).isBetween( dayjs(this.computedCalendarValue).startOf('month').format('YYYY-MM-DD'),  dayjs(this.computedCalendarValue).endOf('month').format('YYYY-MM-DD'), null, '[]')
                || dayjs(ev.dateEnd).isBetween( dayjs(this.computedCalendarValue).startOf('month').format('YYYY-MM-DD'),  dayjs(this.computedCalendarValue).endOf('month').format('YYYY-MM-DD'), null, '[]')
                || dayjs(dayjs(this.computedCalendarValue).startOf('month').format('YYYY-MM-DD')).isBetween( ev.dateStart, ev.dateEnd, null, '[]')
          })
        }
      }

      if (this.cal && this.isCalendarDisplaying && this.getterPlannerBoardType == 'day' && !this.isOpenDay(this.computedCalendarValue)) {
        originalEvents = _.filter(originalEvents, (evv) => {
          return evv.type !== 'vehicle'
        })
      }

      originalEvents = _.orderBy(originalEvents, ['id'], 'asc')

      if (this.getterPlannerBoardType === 'weekStarting') {
        let monday = this.getMonday(this.computedCalendarValue);
        originalEvents = _.filter(originalEvents, (e) => {
          return e.dateStart === monday
        })
      }
      let events = [];
      _.forEach(originalEvents, (e) => {
        let card = e?.card?.id && e?.estimate ? e.estimate : null
        if ((e.color === null || e.groupId === null) && !(card?.productionTime?.category)) {
          if (e.type == 'all-day' && !e.isWorkDay) {
            e.color = '#7030a0'
          } else {
            e.color = '#5f9fa0';
          }
        }
        e.name = e.title;
        if (card?.productionTime?.category) {
          e.color = this.getGroup(card?.productionTime?.category).color
        }

        if (e.type == 'vehicle' || e.type == 'all-day') {
          e.start = e.dateStart;
          e.end = e.dateEnd;
          e.timed = false;
        } else {
          e.start = this.toTimestamp(e.dateStart + ' ' + e.timeStart);
          e.end = this.toTimestamp(e.dateEnd + ' ' + e.timeEnd);
          e.timed = true;
        }

        events.push(e);
      });

          if (this.isCollapsed) {
            return _.filter(events, (ev) => {
              return ev.timed
            })
          }
          return events;
        },
        computedTitle() {
          if (this.isCalendarDisplaying && this.cal) {
            let lastStart = this.$refs.calendar.lastStart;
            let lastEnd = this.$refs.calendar.lastEnd;
            if (this.getterPlannerBoardType === 'month') {
              return this.months[lastStart.month - 1] + ' ' + lastStart.year;
            } else if (this.getterPlannerBoardType === 'week' || this.getterPlannerBoardType === 'weekStarting') {
              if (lastStart.year !== lastEnd.year) {
                return lastStart.day + ' ' + this.shortMonths[lastStart.month - 1] + ' ' + lastStart.year +  ' - ' + lastEnd.day + ' ' + this.shortMonths[lastEnd.month - 1] + ' ' + lastEnd.year;
              } else if (lastStart.month !== lastEnd.month) {
                return lastStart.day + ' ' + this.shortMonths[lastStart.month - 1] +  ' - ' + lastEnd.day + ' ' + this.shortMonths[lastEnd.month - 1] + ' ' + lastEnd.year;
              } else {
                return lastStart.day + ' - ' + lastEnd.day + ' ' + this.shortMonths[lastEnd.month - 1] + ' ' + lastEnd.year;
              }
            } else if (this.getterPlannerBoardType === 'day') {
              return lastStart.day + ' ' + this.shortMonths[lastStart.month - 1] + ' ' + lastStart.year;
            }
          }
        },
        computedCalendarType() {
          if (this.getterPlannerBoardType === 'weekStarting') {
            return 'week';
          }
          return this.getterPlannerBoardType;
        },
        computedType() {
          if (this.getterPlannerBoardType === 'weekStarting') {
            return 'Week (Starting)'
          }
          let type = this.getterPlannerBoardType[0].toUpperCase() + this.getterPlannerBoardType.slice(1);
          return type;
        },
        cal () {
          return this.isCalendarDisplaying ? this.$refs.calendar : null
        },
        nowY () {
          return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'
        },
      },
      watch: {
        events: {
          handler(v) {
            if (this.isShowOpenTimeslots) {
              this.hideOpenEstimateTimeslots()
              this.$nextTick(() => {
                this.showOpenEstimateTimeslots()
              })
            }
            this.updateActiveCard()
            this.$nextTick(() =>{
              console.log('events')
              this.setCategoryOrder()
            })
          },
          immediate: true,
          deep: true,
        },
        getBookingItems: {
          handler(v) {
            this.updateEvents(_.cloneDeep(v))
          },
          immediate: true,
          deep: true,
        },
        vendorInfo: {
          handler(v) {
            if (!_.isEqual(v.planner, this.planner)) {
              let b = !_.isEqual(v.planner.weeks, this.planner.weeks) && this.isShowOpenTimeslots
              this.$nextTick(() => {
                this.$set(this, 'planner', _.cloneDeep(v.planner))
              })
              if (b) {
                this.hideOpenEstimateTimeslots()
                this.$nextTick(() => {
                  this.showOpenEstimateTimeslots()
                })
              }
            }
          },
          deep: true
        },
        getCountBookingUpdate: {
          handler() {
            this.loadEvents()
          }
        },
      },
      methods: {
        addNewCard() {
          this.$router.push({
            name: 'CardsNew',
            params: {
              isFromPlanner: true,
              eventDate: this.focus
            }
          })
        },
        isTextToEnd(e) {
          if (this.getterPlannerBoardType !== 'week') {
            return false
          }
          if (!this.isCalendarDisplaying || !this.cal) {
            return false
          }
          return !_.isEmpty(this.computedAllDayEventsPeriod) && !_.includes(this.computedAllDayEventsPeriod, e.dateEnd) && (_.includes(this.computedAllDayEventsPeriod, e.dateStart) || new Date(dayjs(this.$refs.calendar.value).isoWeekday(1).format('YYYY-MM-DD')) > new Date(e.dateStart))
        },
        isTextToCenter(e) {
          if (this.getterPlannerBoardType !== 'week') {
            return false
          }
          if (!this.isCalendarDisplaying || !this.cal) {
            return false
          }
          return !_.isEmpty(this.computedAllDayEventsPeriod) && _.includes(this.computedAllDayEventsPeriod, e.dateEnd) && (_.includes(this.computedAllDayEventsPeriod, e.dateStart) || new Date(dayjs(this.$refs.calendar.value).isoWeekday(1).format('YYYY-MM-DD')) > new Date(e.dateStart))
        },
        getSubtitleHeight() {
          let days = [
            dayjs(this.$refs.calendar.value).isoWeekday(1).format('YYYY-MM-DD'),
            dayjs(this.$refs.calendar.value).isoWeekday(2).format('YYYY-MM-DD'),
            dayjs(this.$refs.calendar.value).isoWeekday(3).format('YYYY-MM-DD'),
            dayjs(this.$refs.calendar.value).isoWeekday(4).format('YYYY-MM-DD'),
            dayjs(this.$refs.calendar.value).isoWeekday(5).format('YYYY-MM-DD'),
            dayjs(this.$refs.calendar.value).isoWeekday(6).format('YYYY-MM-DD'),
            dayjs(this.$refs.calendar.value).isoWeekday(7).format('YYYY-MM-DD'),
          ]
          let isSomeDayOverbooked = _.some(days, (d) => {
            return this.isOverbookedByTotal(d)
          })
          if (isSomeDayOverbooked) {
            return '110px'
          }
          return '88px'
        },
        isMobileOnly() {
          return isMobileOnly
        },
        getMarginTop(e, parent) {
          if (this.getterPlannerBoardType == 'month') {
            return 0
          }
          let days = [
            dayjs(this.computedCalendarValue).isoWeekday(1).format('YYYY-MM-DD'),
            dayjs(this.computedCalendarValue).isoWeekday(2).format('YYYY-MM-DD'),
            dayjs(this.computedCalendarValue).isoWeekday(3).format('YYYY-MM-DD'),
            dayjs(this.computedCalendarValue).isoWeekday(4).format('YYYY-MM-DD'),
            dayjs(this.computedCalendarValue).isoWeekday(5).format('YYYY-MM-DD'),
            dayjs(this.computedCalendarValue).isoWeekday(6).format('YYYY-MM-DD'),
            dayjs(this.computedCalendarValue).isoWeekday(7).format('YYYY-MM-DD'),
          ]

          let ind = days.indexOf(e.dateStart)

          let documentEvents = _.map(_.orderBy(_.filter(parent.children, (el) => {return _.includes(el.classList, 'v-event')}),[itm => Number(itm?.children?.[0]?.getAttribute("data-order"))], ['asc']), (itm) => {return  Number(itm?.children?.[0]?.getAttribute("data-id"))})

          if (!(e.type == 'vehicle' || (e.type == 'all-day' && e.isWorkDay))) {
            return
          }
          let category = e.groupId;
          let categoryIndex = _.findIndex(this.groups, (g) => {
            return Number(g.id) == Number(category)
          })

          let events3 = _.filter(documentEvents, (id) => {
            let ev = _.find(this.computedEvents, (evennt) => {return Number(evennt.id) == Number(id)})
            return _.findIndex(this.groups, (g) => {
              return Number(g.id) == Number(ev.groupId)
            }) == categoryIndex
          })
          let indexOfEvent = _.findIndex(events3, (evvtt) => {
            return Number(evvtt) == Number(e.id)
          })
          let indexOfEvent2 = _.findIndex(documentEvents, (evvtt) => {
            return Number(evvtt) == Number(e.id)
          })
          if (indexOfEvent !== 0 && indexOfEvent !== -1) {
            return 0
          }

          let catIndexes = []
          for (let i = -1; i < Number(categoryIndex); i++) {
            catIndexes.push(i)
          }
          let events = _.filter(this.computedEvents, (ev) => {
            let b = _.includes(catIndexes,_.findIndex(this.groups, (g) => {
              return Number(g.id) == Number(ev.groupId)
            }))

            return (ev.type == 'vehicle' || (ev.type == 'all-day' && ev.isWorkDay)) && b
          })

          let result = 0;
          _.forEach(catIndexes, (inC) => {
            let firstElementOfCategory = _.find(documentEvents, (id) => {
              let event = _.find(this.computedEvents, (itm) => {
                return Number(itm.id) == Number(id)
              })
              if (event) {
                return inC == _.findIndex(this.groups, (g) => {
                  return Number(g.id) == Number(event.groupId)
                })
              }
            })
            if (firstElementOfCategory) {
              let element = document.getElementById(firstElementOfCategory)
              if (_.includes(element.parentElement.style.marginTop, 'px')) {
                result += Number(element.parentElement.style.marginTop.replace('px', ''))
              }
            }
          })

          let increaseResult = 0;
          if (ind !== 0 && !dayjs(days[0]).isBetween(e.dateStart, e.dateEnd, null, '[]')) {
            let eventsWithSameCatBefore = []
            let daysBefore = days.slice(0, ind)
            _.forEach(daysBefore, (dayBefore) => {
              eventsWithSameCatBefore = _.concat(eventsWithSameCatBefore, _.filter(this.computedEvents, (eventBefore) => {
                let iex = _.findIndex(this.groups, (g) => {
                  return Number(g.id) == Number(eventBefore.groupId)
                })
                return Number(eventBefore.id) !== Number(e.id) &&  iex == categoryIndex && (eventBefore.type == 'vehicle' || (eventBefore.type == 'all-day' && eventBefore.isWorkDay)) && dayjs(dayBefore).isBetween(eventBefore.dateStart, eventBefore.dateEnd, null, '[]')
              }))
            })
            eventsWithSameCatBefore = _.uniqBy(eventsWithSameCatBefore, 'id')
            increaseResult = eventsWithSameCatBefore.length * 21
          }
          if (this.getterPlannerBoardType == 'day') {
            increaseResult = 0
          }

          return (21 * (events.length - (indexOfEvent2  ) )) - result + increaseResult
        },
        getCount(e, categoryIndex) {
          let days = [
            dayjs(this.computedCalendarValue).isoWeekday(1).format('YYYY-MM-DD'),
            dayjs(this.computedCalendarValue).isoWeekday(2).format('YYYY-MM-DD'),
            dayjs(this.computedCalendarValue).isoWeekday(3).format('YYYY-MM-DD'),
            dayjs(this.computedCalendarValue).isoWeekday(4).format('YYYY-MM-DD'),
            dayjs(this.computedCalendarValue).isoWeekday(5).format('YYYY-MM-DD'),
            dayjs(this.computedCalendarValue).isoWeekday(6).format('YYYY-MM-DD'),
            dayjs(this.computedCalendarValue).isoWeekday(7).format('YYYY-MM-DD'),
          ]

          let events = []
          let count = 0
          let ind = days.indexOf(e.dateStart)
          if (ind == 0 || dayjs(days[0]).isBetween(e.dateStart, e.dateEnd, null, '[]')) {
            days = [dayjs(this.computedCalendarValue).isoWeekday(1).format('YYYY-MM-DD')]
            events = _.concat(events, _.filter(this.computedEvents, (ev) => {
              return ev?.card?.id && ev.type == 'vehicle' && dayjs(days[0]).isBetween(ev.dateStart, ev.dateEnd, null, '[]') && ev.dateStart !== days[0]
            }))
            return 0
          } else {
            days.splice(ind , days.length)
            _.forEach(days, (d) => {
              events = _.concat(events, _.filter(this.computedEvents, (ev) => {
                return ev?.card?.id && ev.type == 'vehicle' && dayjs(d).isBetween(ev.dateStart, ev.dateEnd, null, '[]')
              }))
            })
          }
          events = _.uniqBy(events, 'id')
          if (_.isEmpty(events)) {
            return 0
          }

          let catIndexes = []
          if (categoryIndex == 0) {
            catIndexes.push(0)
          } else {
            for (let i = 0; i <= Number(categoryIndex); i++) {
              catIndexes.push(i)
            }
          }
          let categoryEvents = _.filter(events, (ev) => {
            if (Number(ev.id) == Number(e.id)) {
              return false
            }
            return _.includes(catIndexes,_.findIndex(this.groups, (g) => {
              return Number(g.id) == Number(ev.groupId)
            }))
          })
          count += categoryEvents.length;
          return count * 21
        },
        setCategoryOrder() {
          setTimeout(() => {
            let events = document.getElementsByClassName('v-event');
            this.setWhiteTextForAllDays()
            this.setCatFunction(events);
            this.setCatFunction(events);
          }, 10)
        },
        setWhiteTextForAllDays() {
          let days = [
            dayjs(this.$refs.calendar.value).isoWeekday(1).format('YYYY-MM-DD'),
            dayjs(this.$refs.calendar.value).isoWeekday(2).format('YYYY-MM-DD'),
            dayjs(this.$refs.calendar.value).isoWeekday(3).format('YYYY-MM-DD'),
            dayjs(this.$refs.calendar.value).isoWeekday(4).format('YYYY-MM-DD'),
            dayjs(this.$refs.calendar.value).isoWeekday(5).format('YYYY-MM-DD'),
            dayjs(this.$refs.calendar.value).isoWeekday(6).format('YYYY-MM-DD'),
            dayjs(this.$refs.calendar.value).isoWeekday(7).format('YYYY-MM-DD'),
          ]
          let indexes = []
          _.forEach(days, (d, ind) => {
            if (this.settings.days[dayjs(d).day()]) {
              let isHasAllDay = _.some(this.computedEvents, (e) => {
                return e.type == 'all-day' && !e.isWorkDay && dayjs(d).isBetween( e.dateStart, e.dateEnd, null, '[]')
              })
              if (isHasAllDay) {
                indexes.push(ind)
              }
            }
          })

          let events = document.getElementsByClassName('v-calendar-daily_head-day');
          _.forEach(indexes, (ind) => {
            if (events[ind] && !_.includes(events[ind].classList, 'white-header')) {
              events[ind].classList.add('white-header')
            }
          })
        },
        setCatFunction(events) {
          if (!this.isCalendarDisplaying || !this.cal) {
            return
          }
          _.forEach(events, (ev) => {
            if (ev?.children?.[0]) {
              let order = ev?.children?.[0]?.getAttribute("data-order")
              if (order && ev.style.order !== order) {
                ev.style.order = order;
              }
              let event = _.find(this.computedEvents, (e) => {
                return Number(e.id) == Number(ev?.children?.[0]?.getAttribute("data-id"))
              })

              if (event) {
                ev.style.marginTop = this.getMarginTop(event, ev.parentElement ) + 'px'

                if (this.getterPlannerBoardType == 'day' || this.getterPlannerBoardType == 'week') {
                  let setForAllDay = false
                  let setForVehicle = false
                  if (this.getterPlannerBoardType == 'day') {
                    setForAllDay = event.type == 'all-day' && !event.isWorkDay
                    setForVehicle = !(event.type == 'all-day' && !event.isWorkDay) && event.dateStart == dayjs(this.$refs?.calendar?.value).format('YYYY-MM-DD')
                  } else {
                    if (event.type == 'all-day') {
                      setForAllDay = !event.isWorkDay
                      if (event.isWorkDay && event.dateStart == dayjs(this.$refs?.calendar?.value).isoWeekday(1).format('YYYY-MM-DD')) {
                        setForVehicle = true
                      }
                    } else {
                      if (_.includes(this.computedAllDayEventsPeriod, dayjs(event.dateStart).subtract(1, 'day').format('YYYY-MM-DD')) ||
                      event.dateStart == dayjs(this.$refs?.calendar?.value).isoWeekday(1).format('YYYY-MM-DD')) {
                        setForVehicle = true
                      }
                    }
                  }

                  if (setForAllDay){
                    ev.style.setProperty('height' ,'100%' ,'important');
                    ev.style.setProperty('min-height' ,'100%' ,'important');
                    ev.style.setProperty('z-index' ,'6' ,'important');
                    ev.style.setProperty('position' ,'absolute' ,'important');
                    ev.style.setProperty('width' ,'100%' ,'important');
                    ev.style.setProperty('min-width' ,'100%' ,'important');
                    ev.style.setProperty('max-width' ,'100%' ,'important');
                    ev.style.setProperty('margin-left' ,'0px' ,'important');
                    ev.style.setProperty('margin-top' ,'0px' ,'important');
                  }

                  if (setForVehicle) {
                    ev.style.setProperty('margin-left' ,'20px' ,'important')
                    if (!_.includes(ev.style.width, 'calc')) {
                      let width  = String(ev.style.width)
                      ev.style.setProperty('width' ,'calc(' + width + ' - 20px)' ,'important');
                      ev.style.setProperty('min-width' ,'calc(' + width + ' - 20px)' ,'important');
                      ev.style.setProperty('max-width' ,'calc(' + width + ' - 20px)' ,'important');
                    }
                  }

                }
              }
            }
          })
        },
        orientationChange() {
          this.callComputed++
        },
        getCategoryIndex(event) {
          if ((event.type == 'vehicle' || event.type == 'all-day')) {
            let order = event.groupId ? Number(event.groupId) : 0
            return order + 999
          }
          return null
        },
        getDayAppr(event) {
          if (event?.estimate?.productionTime?.daysCount) {
            return Number(event.estimate.productionTime.daysCount)
          }
          if (event?.card?.daysCount) {
            return Number(event.card.daysCount)
          }

          return 0
        },
        getCurrentWeekTotal() {
          if (!this.$refs.calendar) {
            return 0
          }
          return this.getCurrentDayTotal(dayjs(this.computedCalendarValue).isoWeekday(1).format('YYYY-MM-DD'))
              + this.getCurrentDayTotal(dayjs(this.computedCalendarValue).isoWeekday(2).format('YYYY-MM-DD'))
              + this.getCurrentDayTotal(dayjs(this.computedCalendarValue).isoWeekday(3).format('YYYY-MM-DD'))
              + this.getCurrentDayTotal(dayjs(this.computedCalendarValue).isoWeekday(4).format('YYYY-MM-DD'))
              + this.getCurrentDayTotal(dayjs(this.computedCalendarValue).isoWeekday(5).format('YYYY-MM-DD'))
              + this.getCurrentDayTotal(dayjs(this.computedCalendarValue).isoWeekday(6).format('YYYY-MM-DD'))
              + this.getCurrentDayTotal(dayjs(this.computedCalendarValue).isoWeekday(7).format('YYYY-MM-DD'))
        },
        isHasEvents(d) {
          if (!this.$refs.calendar) {
            return false
          }
          let evts = _.filter(this.computedEvents, (e) => {
            return ((e?.card?.id && e.type == 'vehicle') || (e.type == 'all-day' && e.isWorkDay))
                && dayjs(d).isBetween( dayjs(this.computedCalendarValue).isoWeekday(1).format('YYYY-MM-DD'),  dayjs(this.computedCalendarValue).isoWeekday(7).format('YYYY-MM-DD'), null, '[]')
                && dayjs(d).isBetween( dayjs(e.dateStart), dayjs(e.dateEnd), null, '[]')
          })
          return !!evts.length
        },
        getCurrentDayTotal(d) {
          if (!this.$refs.calendar) {
            return 0
          }
          let evts = _.filter(this.computedEventsForTotals, (e) => {
            return e?.card?.id && e.type == 'vehicle'
                && dayjs(d).isBetween( dayjs(this.computedCalendarValue).isoWeekday(1).format('YYYY-MM-DD'),  dayjs(this.computedCalendarValue).isoWeekday(7).format('YYYY-MM-DD'), null, '[]')
                && dayjs(d).isBetween( dayjs(e.dateStart), dayjs(e.dateEnd), null, '[]')
          })
          let a = 0
          _.forEach(evts, (evt) => {
            let estimate = evt.estimate
            if (estimate && estimate.amountJob && Number(estimate.amountJob) && this.getDayAppr(evt)) {
              a += Number(estimate.amountJob) / this.getDayAppr(evt)
            }
          })
          return a
        },
        getCurrentDayTotalLbr(d) {
          if (!this.$refs.calendar) {
            return 0
          }
          // console.log(d, dayjs(d).isBetween( dayjs(this.computedCalendarValue).isoWeekday(1).format('YYYY-MM-DD'),  dayjs(this.computedCalendarValue).isoWeekday(7).format('YYYY-MM-DD'))
          let evts = _.filter(this.computedEventsForTotals, (e) => {
            return e?.card?.id && e.type == 'vehicle'
                && dayjs(d).isBetween( dayjs(this.computedCalendarValue).isoWeekday(1).format('YYYY-MM-DD'),  dayjs(this.computedCalendarValue).isoWeekday(7).format('YYYY-MM-DD'), null, '[]')
                && dayjs(d).isBetween( dayjs(e.dateStart), dayjs(e.dateEnd), null, '[]')
          })
          let a = 0
          _.forEach(evts, (evt) => {
            let estimate = evt.estimate
            if (estimate && estimate.amountLabour && Number(estimate.amountLabour) && this.getDayAppr(evt)) {
              a += Number(estimate.amountLabour) / this.getDayAppr(evt)
            }
          })
          return a
        },
        getCurrentDayBooked(d) {
          if (!this.$refs.calendar) {
            return 0
          }
          return _.filter(this.computedEventsForTotals, (e) => {
            return e?.card?.id && e.type == 'vehicle' && e.dateStart == d
          }).length
        },
        getGroup(v) {
          let r = _.find(this.settings.definitions.categories, (c) => {
            return c.id == v ||_.toLower(c.name) == v
          })
          if (r) {
            let color = _.find(this.groups, (g) => {
              return Number(g.id) == Number(r.colorId)
            })
            return {
              color: color ? color.color : '',
              name: r.name,
              qty: r.qty,
              index: this.settings.definitions.categories.indexOf(r)
            }
          }
          return {
            color: '',
            name: '',
            qty: 0,
            index: -1
          }
        },
        isOpenDay(v) {
          return this.settings.days[dayjs(v).day()]
        },
        showSnapshot(v) {
          if (v == 1) {
            this.$set(this, 'isCalendarDisplaying', false)
            this.isBookingsSnapshot = false
            this.isPlannerDisplaying = true
            localStorage.setItem('bookingBoardType', 'planner')
          } else if (v == 2) {
            this.$set(this, 'isCalendarDisplaying', false)
            this.isBookingsSnapshot = true
            this.isPlannerDisplaying = false
            localStorage.setItem('bookingBoardType', 'snapshot')
            this.$set(this, 'isCalendarDisplaying', false)
          } else {
            this.$set(this, 'isCalendarDisplaying', true)
            this.isBookingsSnapshot = false
            this.isPlannerDisplaying = false
            localStorage.setItem('bookingBoardType', 'board')
            this.$nextTick(() => {
              this.$set(this, 'isCalendarDisplaying', true)
              this.setType(this.getterPlannerBoardType)
            })
          }
        },
        isConflictDayAvailable() {
          if (!this.activeCardEvent) {
            return false
          }

          if (!this.activeCardEvent.dateStart) {
            return false
          }

          let dateResult = this.convertDate(this.activeCardEvent.dateStart);
          let dateEnd = dayjs(this.convertDate(dateResult) + ' ' + this.activeCardEvent.timeStart).add(this.activeCardEvent.timeDuration, 'minute').format('YYYY-MM-DD');
          let timeEnd = dayjs(this.convertDate(dateResult) + ' ' + this.activeCardEvent.timeStart).add(this.activeCardEvent.timeDuration, 'minute').format('HH:mm:ss');
          let ind =  dayjs(dateResult).day()

          if (!dayjs(dateResult + ' ' + this.activeCardEvent.timeStart).isBetween(dateResult + ' ' + this.planner.weeks[ind].morning.startTime, dateResult + ' ' + this.planner.weeks[ind].morning.endTime, null, '[]')
              && !dayjs(dateResult + ' ' + this.activeCardEvent.timeStart).isBetween(dateResult + ' ' + this.planner.weeks[ind].afternoon.startTime, dateResult + ' ' + this.planner.weeks[ind].afternoon.endTime, null, '[]')) {
            return true
          }

          if (!dayjs(dateEnd + ' ' + timeEnd).isBetween(dateEnd + ' ' + this.planner.weeks[ind].morning.startTime, dateEnd + ' ' + this.planner.weeks[ind].morning.endTime, null, '[]')
              && !dayjs(dateEnd + ' ' + timeEnd).isBetween(dateEnd + ' ' + this.planner.weeks[ind].afternoon.startTime, dateEnd + ' ' + this.planner.weeks[ind].afternoon.endTime, null, '[]')) {
            return true
          }

          return false
        },
        onOnSiteDateChange(v) {
          console.log(v, 'value')
          let isRebooking = !!(v.prev && this.activeCardEvent.dateStart
              && v.prev !== this.activeCardEvent.dateStart && this.activeCardEvent.reason &&  this.activeCardEvent.attempt)

          if (isRebooking) {
            this.isAttendedBookingYes = false;
            this.isAttendedBookingNo = false;
            this.activeCardEvent.reason = null;
            this.activeCardEvent.attempt = null;
          }

          this.debouncedUpdateOnSiteEvent();
          this.updateOnSiteEventWithoutDebounce()
        },
        onOnSiteTimeChange() {
          this.debouncedUpdateOnSiteEvent(false, true);
          this.updateOnSiteEventWithoutDebounce()
        },
        redirectToCard(id) {
          this.$router.push({name: 'CardsEdit', params: {card_id: id}});
        },
        checkIsAttendYes(v) {
          if (v) {
            this.isAttendedBookingYes = v
            this.isAttendedBookingNo = false
            this.activeCardEvent.isAttendedBooking = v
          } else {
            this.isAttendedBookingYes = v
            this.isAttendedBookingNo = v
            this.activeCardEvent.isAttendedBooking = null
          }
          this.debouncedUpdateOnSiteEvent(true)
        },
        checkIsAttendNo(v) {
          if (v) {
            this.isAttendedBookingYes = false
            this.isAttendedBookingNo = v
            this.activeCardEvent.isAttendedBooking = false
          } else {
            this.isAttendedBookingYes = v
            this.isAttendedBookingNo = v
            this.activeCardEvent.isAttendedBooking = null
          }
          this.debouncedUpdateOnSiteEvent(true)
        },
        isBlinkBtn(indexOfDay) {
          if (!this.$refs.calendar) {
            return false
          }
          let date = null
          let index = indexOfDay
          if (this.getterPlannerBoardType == 'week') {
            date  = dayjs(this.computedCalendarValue).isoWeekday(indexOfDay)
          } else {
            date  = dayjs(this.computedCalendarValue).set('date', indexOfDay)
            index = date.day()
          }
          let dateResult =  dayjs(date).format('YYYY-MM-DD')
          let startT = this.planner.weeks[index].morning.startTime;
          let startT2 = this.planner.weeks[index].afternoon.startTime;
          let endT = this.planner.weeks[index].morning.endTime;
          let endT2 = this.planner.weeks[index].afternoon.endTime;
          if (!startT || !endT) {
            return false
          }

          let s = dateResult + ' ' + startT;
          let e = dateResult + ' ' + endT;
          let s2 = dateResult + ' ' + startT2;
          let e2 = dateResult + ' ' + endT2;
          let b = false
          let b2 = false
          let count = 0;
          let count2 = 0;

          let timeQty = Math.floor(dayjs(new Date(e)).diff(new Date(s), 'minute') / this.planner.timeDuration)
          for (let i = 1; i <= timeQty; i++) {
            let start =  dayjs(new Date(s)).add(this.planner.timeDuration * (i - 1), 'minute').format('YYYY-MM-DD HH:mm:ss')
            let end =  dayjs(new Date(s)).add(this.planner.timeDuration * i, 'minute').format('YYYY-MM-DD HH:mm:ss')

            let countOfEvents = _.filter(this.events, (evt) => {
              return evt.type == 'on-site' && dayjs(evt.dateStart + ' ' + evt.timeStart).isBetween(start, end, null, '[]') && dayjs(evt.dateEnd + ' ' + evt.timeEnd).isBetween(start, end, null, '[]')
            })?.length

            if (countOfEvents) {
              count++
            }
            if (countOfEvents < this.planner.maxNbrPerSlot) {
              b = true
            }
          }

          if (count == 0) {
            b = true
          }

          let timeQty2 = Math.floor(dayjs(new Date(e2)).diff(new Date(s2), 'minute') / this.planner.timeDuration)

          for (let i = 1; i <= timeQty2; i++) {
            let start =  dayjs(new Date(s2)).add(this.planner.timeDuration * (i - 1), 'minute').format('YYYY-MM-DD HH:mm:ss')
            let end =  dayjs(new Date(s2)).add(this.planner.timeDuration * i, 'minute').format('YYYY-MM-DD HH:mm:ss')

            let countOfEvents = _.filter(this.events, (evt) => {
              return evt.type == 'on-site' && dayjs(evt.dateStart + ' ' + evt.timeStart).isBetween(start, end, null, '[]') && dayjs(evt.dateEnd + ' ' + evt.timeEnd).isBetween(start, end, null, '[]')
            })?.length

            if (countOfEvents) {
              count2++
            }
            if (countOfEvents < this.planner.maxNbrPerSlot) {
              b2 = true
            }
          }
          if (count2 == 0) {
            b2 = true
          }

          return b || b2
        },
        setDayBlinking() {
          if (this.getterPlannerBoardType == 'week' || this.getterPlannerBoardType == 'month') {
            let btns = _.filter(document.getElementsByClassName('v-btn--fab'), (b) => {
              if (this.getterPlannerBoardType == 'month') {
                return !_.includes(b.parentElement.parentElement.classList, 'v-outside')
              } else {
                return true
              }
            })
            _.forEach(btns, (btn, ind) => {
              let isBlinking = this.isBlinkBtn(ind + 1)
              if (isBlinking && !_.includes(btn.classList, 'available-day-blinking')) {
                btn.classList.add('available-day-blinking')
              }
            })
          }
        },
        showOpenEstimateTimeslots() {

          if (!this.$refs.calendar) {
            return
          }


          this.isShowOpenTimeslots = true

          this.setDayBlinking()
          if (this.getterPlannerBoardType == 'week') {
            let days = document.getElementsByClassName('v-calendar-daily_head-day')
            _.forEach(days, (d, index) => {
              let cells = document.getElementsByClassName('v-calendar-daily__day-container')?.[0]?.children?.[index + 1]
              cells = _.filter(cells.children, (c) => {
                return _.includes(c.classList, 'v-calendar-daily__day-interval')
              });

              _.forEach(cells, (c, i) => {
                let dateResult = null
                let timeResult = null
                let timeForCalendar = null
                let indexOfDay = index + 1
                let time = i + ':00:00'

                let date  = dayjs(this.computedCalendarValue).isoWeekday(indexOfDay)
                timeResult = dayjs(dateResult + ' ' + i + ':00:00', 'HH:mm:ss')
                date.set({
                  hour:   timeResult.minute(),
                  minute: timeResult.hour(),
                  second: timeResult.second(),
                })

                dateResult =  dayjs(date).format('YYYY-MM-DD')
                timeForCalendar = time

                let ind = dayjs(dateResult).day()

                let date1 = new Date(dateResult + ' ' + timeForCalendar)
                let dateS = new Date(dateResult + ' ' + this.planner.weeks[ind].morning.startTime)
                let dateE = new Date(dateResult + ' ' + this.planner.weeks[ind].morning.endTime)
                let dateS2 = new Date(dateResult + ' ' + this.planner.weeks[ind].afternoon.startTime)
                let dateE2 = new Date(dateResult + ' ' + this.planner.weeks[ind].afternoon.endTime)
                if (date1.getHours() == dateS.getHours()) {
                  if (dateS.getMinutes() > 0) {
                    c.style.background = `linear-gradient(360deg, #00b050 ${100 - (dateS.getMinutes() * 100 / 60)}%, white 0%)`
                    return
                  }
                } else if (date1.getHours() == dateE.getHours()) {
                  if (dateE.getMinutes() > 0) {
                    c.style.background = `linear-gradient(180deg, #00b050 ${(dateE.getMinutes() * 100 / 60)}%, white 0%)`
                    return
                  }
                }

                if (date1.getHours() == dateS2.getHours()) {
                  if (dateS2.getMinutes() > 0) {
                    c.style.background = `linear-gradient(360deg, #00b050 ${100 - (dateS2.getMinutes() * 100 / 60)}%, white 0%)`
                    return
                  }
                } else if (date1.getHours() == dateE2.getHours()) {
                  if (dateE2.getMinutes() > 0) {
                    c.style.background = `linear-gradient(180deg, #00b050 ${(dateE2.getMinutes() * 100 / 60)}%, white 0%)`
                    return
                  }
                }


                if ((!this.planner.weeks[ind].morning.startTime || !this.planner.weeks[ind].morning.endTime) && (!this.planner.weeks[ind].afternoon.startTime || !this.planner.weeks[ind].afternoon.endTime)) {
                  return
                }

                if (!dayjs(dateResult + ' ' + timeForCalendar).isBetween(dateResult + ' ' + this.planner.weeks[ind].morning.startTime, dateResult + ' ' + this.planner.weeks[ind].morning.endTime, null, '[]')
                    && !dayjs(dateResult + ' ' + timeForCalendar).isBetween(dateResult + ' ' + this.planner.weeks[ind].afternoon.startTime, dateResult + ' ' + this.planner.weeks[ind].afternoon.endTime, null, '[]') ) {
                  return
                }
                if (!dayjs(dateResult + ' ' + dayjs(dateResult + ' ' + timeForCalendar).add(this.planner.timeDuration, 'minute').format('HH:mm:ss')).isBetween(dateResult + ' ' + this.planner.weeks[ind].morning.startTime, dateResult + ' ' + this.planner.weeks[ind].morning.endTime, null, '[]')
                    && !dayjs(dateResult + ' ' + dayjs(dateResult + ' ' + timeForCalendar).add(this.planner.timeDuration, 'minute').format('HH:mm:ss')).isBetween(dateResult + ' ' + this.planner.weeks[ind].afternoon.startTime, dateResult + ' ' + this.planner.weeks[ind].afternoon.endTime, null, '[]')) {
                  return
                }


                if (!_.includes(c.classList, 'available-planner-cell')) {
                  c.classList.add('available-planner-cell')
                }
              })
            })
          }
        },
        hideOpenEstimateTimeslots() {
          this.isShowOpenTimeslots = false

          let cells = document.getElementsByClassName('v-calendar-daily__day-interval')
          _.forEach(cells, (c) => {
            c.style.background = 'white'
            if (_.includes(c.classList, 'available-planner-cell')) {
              c.classList.remove('available-planner-cell')
            }
          })

          let btns = document.getElementsByClassName('v-btn--fab')
          _.forEach(btns, (btn) => {
            if (_.includes(btn.classList, 'available-day-blinking')) {
              btn.classList.remove('available-day-blinking')
            }
          })
        },
        setTitle (isNeedToClear = false) {
          let text = !isNeedToClear ? 'Bookings' : appConfig.appName
          let title = document.getElementsByTagName('title')
          if (title?.[0]) {
            title[0].innerText = text
          }
        },
        showAddVehicleBookingMenu() {
          if (this.isCollapsed) {
            this.collapseEvents()
          }
          this.isVehicleBooking = true
          this.isEstimateBooking = false
        },
        showOnSiteMenu(isFromNewCard = false) {
          this.isVehicleBooking = false
          this.isEstimateBooking = true
          if (this.getterPlannerBoardType !== 'day' && !isFromNewCard) {
            this.setType('day')
          }
        },
        updateActiveCard() {
          if (!this.activeCard || !this.isEstimateBooking) {
            return
          }

          let event = _.find(_.cloneDeep(this.events), (evt) => {
            return Number(this.activeCard) == Number(evt?.card?.id) && evt.type == 'on-site'
          })

          if (event) {
            if (this.activeCardEvent && this.convertDate(this.activeCardEvent.dateStart) != event.dateStart) {
              this.activeCardEvent.dateStart = event.dateStart
              this.isUpdatedFromUpdater = true
            }
            if (this.activeCardEvent && this.activeCardEvent.reason != event.reason) {
              this.activeCardEvent.reason = event.reason
              this.isUpdatedFromUpdater = true
            }
            if (this.activeCardEvent && this.activeCardEvent.attempt != event.attempt) {
              this.activeCardEvent.attempt = event.attempt
              this.isUpdatedFromUpdater = true
            }
            if (this.activeCardEvent && event.isAttendedBooking !== this.activeCardEvent.isAttendedBooking) {
              this.isAttendedBookingNo = false;
              this.isAttendedBookingYes = false;
              if (event.isAttendedBooking) {
                this.checkIsAttendYes(true)
              } else if (event.isAttendedBooking === false) {
                this.checkIsAttendNo(true)
              } else {
                this.activeCardEvent.isAttendedBooking = null
              }
              this.isUpdatedFromUpdater = true
            }
          }
        },

        selectCard(id, isFromMenu = false, isEventId = false, isFromNewCard = false) {
          if (!isFromMenu && this.mouseDownTime >= 100) {
            return
          }
          this.isOnSiteDateError = false
          this.isOnSiteTimeError = false
          if (this.activeCard == id && !isFromMenu) {
            this.activeCard = null
          } else {
            let event = _.find(_.cloneDeep(this.events), (evt) => {
              if (isEventId) {
                return Number(id) == Number(evt?.id) && evt.type == 'on-site'
              }
              return Number(id) == Number(evt?.card?.id) && evt.type == 'on-site'
            })

        this.activeCardEvent = event ? event : {}
        this.isAttendedBookingNo = false;
            this.isAttendedBookingYes = false;
            if (this.activeCardEvent.isAttendedBooking) {
              this.checkIsAttendYes(true)
            } else if (this.activeCardEvent.isAttendedBooking === false) {
              this.checkIsAttendNo(true)
            } else {
              this.activeCardEvent.isAttendedBooking = null
            }
            this.activeCardEvent.start = this.activeCardEvent.start ? this.activeCardEvent.start : null
            if (!this.activeCardEvent.timeDuration) {
              let difference = dayjs(this.activeCardEvent.dateEnd + ' ' + this.activeCardEvent.timeEnd).diff(this.activeCardEvent.dateStart + ' ' + this.activeCardEvent.timeStart, 'minute')
              if (difference == 15 || difference == 30 || difference == 45) {
                this.activeCardEvent.timeDuration = difference
              } else {
                this.activeCardEvent.timeDuration = this.planner.timeDuration
              }
            }
            this.activeCardEvent.people = this.getUsersByIds(this.activeCardEvent.people)
            if (isEventId && !isFromNewCard) {
              this.activeCard =  this.activeCardEvent?.card?.id ? this.activeCardEvent?.card?.id : null
            } else {
              this.activeCard = id
            }
            if (isFromMenu) {
              setTimeout(() => {
                let el = document.getElementById('card-' + this.activeCard)
                if (el) {
                  el.scrollIntoView({
                    // behavior: 'smooth',
                    // block: 'nearest',
                    // inline: "start",


                    behavior: 'smooth', block: 'center', inline: 'center'
                  });
                }
              }, 0)
            }
      }
    },
    isAlreadyAddedOnSiteEvent(id) {
      return _.includes(this.computedOnSitEventsIds, id)
    },
    getCardById(id) {
      return _.find(this.getterAllCards, (c) => {
        return Number(c.card_id) == Number(id)
      })
    },
    savePlannerBoard() {
      if (this.isShowOpenTimeslots) {
            this.hideOpenEstimateTimeslots()
            this.$nextTick(() => {
              this.showOpenEstimateTimeslots()
            })
          } else {
            this.hideOpenEstimateTimeslots()
          }
          Axios.post('fe/planner-board/settings', {
            planner: {
              statuses: this.planner.statuses,
              timeDuration:this.planner.timeDuration,
              maxNbrPerSlot:this.planner.maxNbrPerSlot,
              weeks: this.planner.weeks
            }
          }).finally(() => {
        this.$store.dispatch('loadVendorInfo')
      })
        },
        getStatusName(v) {
          return _.find(this.ceStatuses, (s) => {
            return Number(s.ce_status_id) == Number(v)
          })?.name
        },
        loadCEStatuses() {
          this.$store.dispatch("loadCEStatuses")
              .then((res) => {
                if (res.status === 200) {
                  this.ceStatuses = res.data;
                }
              })
              .catch((err) => {
                console.log(err);
              });
        },
        onMouseMove(e, id) {
          if (!this.selectedId) {
            return
          }
          if (this.selectedId) {
            this.removeAllDraggableElementsExceptCurrent(this.selectedId.replace('card-', ''))
          }
          if (this.selectedId && this.isAlreadyAddedOnSiteEvent(this.selectedId.replace('card-', ''))) {
            return
          }
          if (id && this.selectedId !== id) {
            return
          }
          if (this.computedCalendarType === 'week' || this.computedCalendarType == 'day'){
            if (this.isMouseDown) {
              let el = document.getElementById(this.selectedId)
              if (!el) {
                return
              }
              el.style.position = 'absolute'
              el.style.left = e.clientX  - 162 + 'px'
              el.style.top = e.clientY  - 25 + window.pageYOffset  + 'px'
              el.style.width = '324px'
              el.style.backgroundColor = 'white'
              this.setGrayColor()
            }
          }
        },
        onMouseDown(id) {
          this.mouseDownTime = 0;
          this.mouseDownTimeInterval = setInterval(() => {
            this.mouseDownTime +=1
          }, 1)
          if (this.isMouseDown || this.selectedId) {
            if (id && !this.selectedId) {
              this.selectedId = id
            }
            return
          }
          if (id && this.isAlreadyAddedOnSiteEvent(id.replace('card-', ''))) {
            return
          }
          if (this.computedCalendarType === 'week' || this.computedCalendarType == 'day'){
            if (id) {
              this.selectedId = id
              console.log('on mouse down', id)
            }
            this.isMouseDown = true
          }
        },
        setGrayColor() {
          if (!this.$refs.calendar) {
            return
          }

          if (this.computedCalendarType == 'day') {
            let cells = document.getElementsByClassName('v-calendar-daily__day-interval')
            _.forEach(cells, (c, index) => {

          let dateResult = dayjs(this.computedCalendarValue).format('YYYY-MM-DD')
          let timeForCalendar = index + ':00:00'
          let ind = dayjs(dateResult).day()

          let date1 = new Date(dateResult + ' ' + timeForCalendar)
          let dateS = new Date(dateResult + ' ' + this.planner.weeks[ind].morning.startTime)
          let dateE = new Date(dateResult + ' ' + this.planner.weeks[ind].morning.endTime)
              let dateS2 = new Date(dateResult + ' ' + this.planner.weeks[ind].afternoon.startTime)
              let dateE2 = new Date(dateResult + ' ' + this.planner.weeks[ind].afternoon.endTime)
          if (date1.getHours() == dateS.getHours()) {
            if (dateS.getMinutes() > 0) {
              c.style.background = `linear-gradient(180deg, #e4e4e4 ${dateS.getMinutes() * 100 / 60}%, white 0%)`
              return
            }
          } else if (date1.getHours() == dateE.getHours()) {
            if (dateE.getMinutes() > 0) {
              c.style.background = `linear-gradient(360deg, #e4e4e4 ${100 - (dateE.getMinutes() * 100 / 60)}%, white 0%)`
              return
            }
          }
          if (date1.getHours() == dateS2.getHours()) {
                if (dateS2.getMinutes() > 0) {
                  c.style.background = `linear-gradient(180deg, #e4e4e4 ${dateS2.getMinutes() * 100 / 60}%, white 0%)`
                  return
                }
              } else if (date1.getHours() == dateE2.getHours()) {
                if (dateE2.getMinutes() > 0) {
                  c.style.background = `linear-gradient(360deg, #e4e4e4 ${100 - (dateE2.getMinutes() * 100 / 60)}%, white 0%)`
                  return
                }
              }
              if (!dayjs(dateResult + ' ' + timeForCalendar).isBetween(dateResult + ' ' + this.planner.weeks[ind].morning.startTime, dateResult + ' ' + this.planner.weeks[ind].morning.endTime, null, '[]')
              && !dayjs(dateResult + ' ' + timeForCalendar).isBetween(dateResult + ' ' + this.planner.weeks[ind].afternoon.startTime, dateResult + ' ' + this.planner.weeks[ind].afternoon.endTime, null, '[]')) {
            if (!_.includes(c.classList, 'disabled-planner-cell')) {
              c.classList.add('disabled-planner-cell')
            }
          }
          if (!dayjs(dateResult + ' ' + dayjs(dateResult + ' ' + timeForCalendar).add(this.planner.timeDuration, 'minute').format('HH:mm:ss')).isBetween(dateResult + ' ' + this.planner.weeks[ind].morning.startTime, dateResult + ' ' + this.planner.weeks[ind].morning.endTime, null, '[]')
                  && !dayjs(dateResult + ' ' + dayjs(dateResult + ' ' + timeForCalendar).add(this.planner.timeDuration, 'minute').format('HH:mm:ss')).isBetween(dateResult + ' ' + this.planner.weeks[ind].afternoon.startTime, dateResult + ' ' + this.planner.weeks[ind].afternoon.endTime, null, '[]')) {
            if (!_.includes(c.classList, 'disabled-planner-cell')) {
              c.classList.add('disabled-planner-cell')
            }
          }
        })
      } else if (this.computedCalendarType == 'week') {
        let days = document.getElementsByClassName('v-calendar-daily_head-day')
        _.forEach(days, (d, index) => {
          let cells = document.getElementsByClassName('v-calendar-daily__day-container')?.[0]?.children?.[index + 1]
          cells = _.filter(cells.children, (c) => {
            return _.includes(c.classList, 'v-calendar-daily__day-interval')
          });

          _.forEach(cells, (c, i) => {
            let dateResult = null
            let timeResult = null
            let timeForCalendar = null
            let indexOfDay = index + 1
            let time = i + ':00:00'

            let date = dayjs(this.computedCalendarValue).isoWeekday(indexOfDay)
            timeResult = dayjs(dateResult + ' ' + i + ':00:00', 'HH:mm:ss')
            date.set({
              hour: timeResult.minute(),
              minute: timeResult.hour(),
              second: timeResult.second(),
            })

            dateResult = dayjs(date).format('YYYY-MM-DD')
            timeForCalendar = time

            let ind = dayjs(dateResult).day()

            let date1 = new Date(dateResult + ' ' + timeForCalendar)
            let dateS = new Date(dateResult + ' ' + this.planner.weeks[ind].morning.startTime)
            let dateE = new Date(dateResult + ' ' + this.planner.weeks[ind].morning.endTime)
                let dateS2 = new Date(dateResult + ' ' + this.planner.weeks[ind].afternoon.startTime)
                let dateE2 = new Date(dateResult + ' ' + this.planner.weeks[ind].afternoon.endTime)
            let color = this.isShowOpenTimeslots ? '#00b050' : 'white'
                if (date1.getHours() == dateS.getHours()) {
                  if (dateS.getMinutes() > 0) {
                    c.style.background = `linear-gradient(180deg, #e4e4e4 ${dateS.getMinutes() * 100 / 60}%, ${color} 0%)`
                return
              }
            } else if (date1.getHours() == dateE.getHours()) {
              if (dateE.getMinutes() > 0) {
                c.style.background = `linear-gradient(360deg, #e4e4e4 ${100 - (dateE.getMinutes() * 100 / 60)}%, ${color} 0%)`
                    return
                  }
                }
                if (date1.getHours() == dateS2.getHours()) {
                  if (dateS2.getMinutes() > 0) {
                    c.style.background = `linear-gradient(180deg, #e4e4e4 ${dateS2.getMinutes() * 100 / 60}%, ${color} 0%)`
                    return
                  }
                } else if (date1.getHours() == dateE2.getHours()) {
                  if (dateE2.getMinutes() > 0) {
                    c.style.background = `linear-gradient(360deg, #e4e4e4 ${100 - (dateE2.getMinutes() * 100 / 60)}%, ${color} 0%)`
                return
              }
            }

            if (!dayjs(dateResult + ' ' + timeForCalendar).isBetween(dateResult + ' ' + this.planner.weeks[ind].morning.startTime, dateResult + ' ' + this.planner.weeks[ind].morning.endTime, null, '[]')
                    && !dayjs(dateResult + ' ' + timeForCalendar).isBetween(dateResult + ' ' + this.planner.weeks[ind].afternoon.startTime, dateResult + ' ' + this.planner.weeks[ind].afternoon.endTime, null, '[]')) {
              if (!_.includes(c.classList, 'disabled-planner-cell')) {
                c.classList.add('disabled-planner-cell')
              }
            }
            if (!dayjs(dateResult + ' ' + dayjs(dateResult + ' ' + timeForCalendar).add(this.planner.timeDuration, 'minute').format('HH:mm:ss')).isBetween(dateResult + ' ' + this.planner.weeks[ind].morning.startTime, dateResult + ' ' + this.planner.weeks[ind].morning.endTime, null, '[]')
                && !dayjs(dateResult + ' ' + dayjs(dateResult + ' ' + timeForCalendar).add(this.planner.timeDuration, 'minute').format('HH:mm:ss')).isBetween(dateResult + ' ' + this.planner.weeks[ind].afternoon.startTime, dateResult + ' ' + this.planner.weeks[ind].afternoon.endTime, null, '[]')) {
              if (!_.includes(c.classList, 'disabled-planner-cell')) {
                c.classList.add('disabled-planner-cell')
              }
            }
          })
        })
      }
    },
    removeGrayColor() {
      // if (this.computedCalendarType == 'day') {
      let cells = document.getElementsByClassName('v-calendar-daily__day-interval')
      _.forEach(cells, (c) => {
        if (_.includes(c.style.background, 'rgb(228, 228, 228)')) {
                if (this.isShowOpenTimeslots) {
                  let st = c.style.background.replace('rgb(228, 228, 228)', 'white')
                  c.style.background = st

                } else {
                  c.style.background = 'white'
                }
              }
              if (_.includes(c.classList, 'disabled-planner-cell')) {
                c.classList.remove('disabled-planner-cell')
              }
            })
          // }
        },
        addMinutes(time, minsToAdd) {
          function D(J){ return (J<10? '0':'') + J;};
          var piece = time.split(':');
          var mins = piece[0]*60 + +piece[1] + +minsToAdd;

          return D(mins%(24*60)/60 | 0) + ':' + D(mins%60) + ':00';
        },
        onMouseUp(e, id) {
          if (!this.$refs.calendar) {
            return
          }
      clearInterval(this.mouseDownTimeInterval)
      this.removeGrayColor()
      if (!this.selectedId) {
        return
      }
      if (id && this.isAlreadyAddedOnSiteEvent(id.replace('card-', ''))) {
        return
      }
      if (!this.isMouseDown) {
        return
      }
      if (this.computedCalendarType === 'week' || this.computedCalendarType == 'day') {
        this.isMouseDown = false
        let el = document.getElementById(id)
        if (!el) {
          this.selectedId = null;
          this.removeAllDraggableElements()
          return
        }
        el.style.position = 'static'
        el.style.width = 'auto'
        let target = document.elementFromPoint(e.clientX, e.clientY)

        if (!_.includes(target.classList, 'v-calendar-daily__day-interval')) {
          let targets = document.elementsFromPoint(e.clientX, e.clientY)
          target = _.find(targets, (t) => {
            return _.includes(t?.classList, 'v-calendar-daily__day-interval')
          })
          if (!target) {
            this.selectedId = null;
            return;
          }
        }

        if (!_.includes(target.classList, 'v-calendar-daily__day-interval')) {
          this.selectedId = null;
          return
        }

        let dateResult = null
        let timeResult = null
        let timeForCalendar = null
        if (this.computedCalendarType === 'week') {
          let indexOfDay = [...target.parentElement.parentElement.children].indexOf(target.parentElement);

          if (this.getterPlannerBoardType === 'weekStarting' && indexOfDay !== 1) {
            return
          }
          let indexOfTime = [...target.parentElement.children].indexOf(target)
          let time = indexOfTime + ':00:00'

          let date = dayjs(this.computedCalendarValue).isoWeekday(indexOfDay)
          timeResult = dayjs(dateResult + ' ' + indexOfTime + ':00:00', 'HH:mm:ss')
          date.set({
            hour: timeResult.minute(),
            minute: timeResult.hour(),
            second: timeResult.second(),
          })

          dateResult = dayjs(date).format('YYYY-MM-DD')
          timeForCalendar = time
          // console.log(target, 'TARGET' ,  dayjs(date).format('YYYY-MM-DD'), dayjs(timeResult).format('HH:mm:ss'))

          let ind = dayjs(dateResult).day()
          let date1 = new Date(dateResult + ' ' + timeForCalendar)
          let dateS = new Date(dateResult + ' ' + this.planner.weeks[ind].morning.startTime)
          let dateE = new Date(dateResult + ' ' + this.planner.weeks[ind].morning.endTime)
              let dateS2 = new Date(dateResult + ' ' + this.planner.weeks[ind].afternoon.startTime)
              let dateE2 = new Date(dateResult + ' ' + this.planner.weeks[ind].afternoon.endTime)

          if (date1.getHours() == dateS.getHours()) {
            if (dateS.getMinutes() > 0) {
              // let percent = (e.clientY - target.getBoundingClientRect().top) * 100 / 48
                  // console.log(target, 'TARGET' ,percent, dateS.getMinutes() * 100 / 60)
                  // if (percent < dateS.getMinutes() * 100 / 60) {
                  //   this.selectedId = null;
                  //   return
                  // } else {
                  //   timeForCalendar = indexOfTime + ':' + (percent * 60 / 100).toFixed() + ':00'
                  // }
                  timeForCalendar = dateS
                }
              } else if (date1.getHours() == dateE.getHours()) {
                if (dateE.getMinutes() > 0) {
                  // let percent = (e.clientY - target.getBoundingClientRect().top) * 100 / 48
                  // console.log(target, 'TARGET2' , percent, dateE.getMinutes() * 100 / 60)
                  // if (percent > dateE.getMinutes() * 100 / 60) {
                  //   this.selectedId = null;
                  //   return
                  // } else {
                  //   timeForCalendar = indexOfTime + ':' + (percent * 60 / 100).toFixed() + ':00'
                  // }
                }
              }

              if (date1.getHours() == dateS2.getHours()) {
                if (dateS2.getMinutes() > 0) {
                  timeForCalendar = dateS2
                }
              }

        } else {
          dateResult = dayjs(this.computedCalendarValue).format('YYYY-MM-DD')
          let indexOfTime = [...target.parentElement.children].indexOf(target)
          timeResult = dayjs(dateResult + ' ' + indexOfTime + ':00:00', 'HH:mm:ss')
          timeForCalendar = indexOfTime + ':00:00'

          let ind = dayjs(dateResult).day()
          let date1 = new Date(dateResult + ' ' + timeForCalendar)
          let dateS = new Date(dateResult + ' ' + this.planner.weeks[ind].morning.startTime)
          let dateE = new Date(dateResult + ' ' + this.planner.weeks[ind].morning.endTime)
              let dateS2 = new Date(dateResult + ' ' + this.planner.weeks[ind].afternoon.startTime)
              let dateE2 = new Date(dateResult + ' ' + this.planner.weeks[ind].afternoon.endTime)

          if (date1.getHours() == dateS.getHours()) {
            if (dateS.getMinutes() > 0) {
              // let percent = (e.clientY - target.getBoundingClientRect().top) * 100 / 48
                  // console.log(target, 'TARGET' ,percent, dateS.getMinutes() * 100 / 60)
                  // if (percent < dateS.getMinutes() * 100 / 60) {
                  //   this.selectedId = null;
                  //   return
                  // } else {
                  //   timeForCalendar = indexOfTime + ':' + (percent * 60 / 100).toFixed() + ':00'
                  // }
                  timeForCalendar = dateS
                }
              } else if (date1.getHours() == dateE.getHours()) {
                if (dateE.getMinutes() > 0) {
                  // let percent = (e.clientY - target.getBoundingClientRect().top) * 100 / 48
                  // console.log(target, 'TARGET2' , percent, dateE.getMinutes() * 100 / 60)
                  // if (percent > dateE.getMinutes() * 100 / 60) {
                  //   this.selectedId = null;
                  //   return
                  // } else {
                  //   timeForCalendar = indexOfTime + ':' + (percent * 60 / 100).toFixed() + ':00'
                  // }
                }
              }

              if (date1.getHours() == dateS2.getHours()) {
                if (dateS2.getMinutes() > 0) {
                  timeForCalendar = dateS2
                }
              }
            }
            let ind = dayjs(dateResult).day()

            if (!dayjs(dateResult + ' ' + timeForCalendar).isBetween(dateResult + ' ' + this.planner.weeks[ind].morning.startTime, dateResult + ' ' + this.planner.weeks[ind].morning.endTime, null, '[]')
                && !dayjs(dateResult + ' ' + timeForCalendar).isBetween(dateResult + ' ' + this.planner.weeks[ind].afternoon.startTime, dateResult + ' ' + this.planner.weeks[ind].afternoon.endTime, null, '[]')) {
              this.selectedId = null;
              return
            }
            if (!dayjs(dateResult + ' ' + dayjs(dateResult + ' ' + timeForCalendar).add(this.planner.timeDuration, 'minute').format('HH:mm:ss')).isBetween(dateResult + ' ' + this.planner.weeks[ind].morning.startTime, dateResult + ' ' + this.planner.weeks[ind].morning.endTime, null, '[]')
                && !dayjs(dateResult + ' ' + dayjs(dateResult + ' ' + timeForCalendar).add(this.planner.timeDuration, 'minute').format('HH:mm:ss')).isBetween(dateResult + ' ' + this.planner.weeks[ind].afternoon.startTime, dateResult + ' ' + this.planner.weeks[ind].afternoon.endTime, null, '[]')) {
              this.selectedId = null;
              return
            }

            let countOfEvents = _.filter(this.events, (evt) => {
              let endSplot = dayjs(dateResult + ' ' + timeForCalendar).add(this.planner.timeDuration, 'minute').format('YYYY-MM-DD HH:mm:ss')
              return evt.type == 'on-site' && dayjs(evt.dateStart + ' ' + evt.timeStart).isBetween(dateResult + ' ' + timeForCalendar, endSplot, null, '[]') && dayjs(evt.dateEnd + ' ' + evt.timeEnd).isBetween(dateResult + ' ' + timeForCalendar, endSplot, null, '[]')
            })?.length

            let timeDuration = this.planner.timeDuration ? this.planner.timeDuration : 15
            if (countOfEvents >= this.planner.maxNbrPerSlot) {

              if (this.getterPlannerBoardType == 'day') {
                timeForCalendar = timeForCalendar.split(':')[0] + ':00:00'
              }

              let isFoundFreeSlot = false
              for (let i = 1; i < Math.round(Number(60/timeDuration)); i++) {
                if (!isFoundFreeSlot) {
                  let countOfEvents = _.filter(this.events, (evt) => {
                    let endSplot = dayjs(dateResult + ' ' + timeForCalendar).add(this.planner.timeDuration * (i + 1), 'minute').format('YYYY-MM-DD HH:mm:ss')
                    let startSplot = dayjs(dateResult + ' ' + timeForCalendar).add(this.planner.timeDuration * i, 'minute').format('YYYY-MM-DD HH:mm:ss')
                    return evt.type == 'on-site' && dayjs(evt.dateStart + ' ' + evt.timeStart).isBetween(startSplot, endSplot, null, '[]') && dayjs(evt.dateEnd + ' ' + evt.timeEnd).isBetween(startSplot, endSplot, null, '[]')
                  })?.length

                  if (countOfEvents >= this.planner.maxNbrPerSlot) {
                    isFoundFreeSlot = false
                  } else {
                    isFoundFreeSlot = true
                    timeForCalendar = this.addMinutes(timeForCalendar, this.planner.timeDuration * i)
                  }
                }
              }

              if (!isFoundFreeSlot) {
                this.selectedId = null;
                return
              }
              if (!dayjs(dateResult + ' ' + timeForCalendar).isBetween(dateResult + ' ' + this.planner.weeks[ind].morning.startTime, dateResult + ' ' + this.planner.weeks[ind].morning.endTime, null, '[]')
                  && !dayjs(dateResult + ' ' + timeForCalendar).isBetween(dateResult + ' ' + this.planner.weeks[ind].afternoon.startTime, dateResult + ' ' + this.planner.weeks[ind].afternoon.endTime, null, '[]')) {
                this.selectedId = null;
                return
              }
              if (!dayjs(dateResult + ' ' + dayjs(dateResult + ' ' + timeForCalendar).add(this.planner.timeDuration, 'minute').format('HH:mm:ss')).isBetween(dateResult + ' ' + this.planner.weeks[ind].morning.startTime, dateResult + ' ' + this.planner.weeks[ind].morning.endTime, null, '[]')
                  && !dayjs(dateResult + ' ' + dayjs(dateResult + ' ' + timeForCalendar).add(this.planner.timeDuration, 'minute').format('HH:mm:ss')).isBetween(dateResult + ' ' + this.planner.weeks[ind].afternoon.startTime, dateResult + ' ' + this.planner.weeks[ind].afternoon.endTime, null, '[]') ) {
                this.selectedId = null;
                return
              }
            }

        let data = {
          "cardId": this.selectedId.replace('card-', ''),
          "dateStart": dateResult,
          "timeStart": timeForCalendar,
          "dateEnd": dateResult,
          "timeEnd": dayjs(dateResult + ' ' + timeForCalendar).add(this.planner.timeDuration, 'minute').format('HH:mm:ss'),
          "title": this.getTitleByCardId(this.selectedId.replace('card-', '')),
          type: 'on-site'
        }


        this.saveOnSiteEvent(data)
        this.selectedId = null;
      }

    },
    saveOnSiteEvent(data) {

          Axios.post('/fe/planner-board/add', data)
              .then((response) => {
                if (response.data._status && response.data.event) {
                  this.$store.commit('board/addBookingItem', response.data.event)
                }
                this.createdOnSiteCard = data.cardId
              })
              .finally(() => {
              });
        },
        removeAllDraggableElements() {
          if (this.computedCalendarType === 'week' || this.computedCalendarType == 'day'){
            _.forEach(this.computedCardsForBooking, (card) => {
              let el = document.getElementById('card-' + card.card_id)
              if (el) {
                el.style.position = 'static'
                el.style.width = 'auto'
              }
            })
            this.isMouseDown = false
            this.selectedId = null;
          }
        },
        removeAllDraggableElementsExceptCurrent(id = null) {
          if (this.computedCalendarType === 'week' || this.computedCalendarType == 'day'){
            _.forEach(this.computedCardsForBooking, (card) => {
              let el = document.getElementById('card-' + card.card_id)
              if (el && Number(card.card_id) !== Number(id)) {
                el.style.position = 'static'
                el.style.width = 'auto'
              }
            })
          }
        },
        getTitleByCardId(id) {
          let r = ''
          let card = _.find(this.getterAllCards, (c) => {
            return Number(c.card_id) == Number(id)
          })
          if (card) {
            if (card.card_number) {
              r += card.card_number
            }
            if (card.customer_name) {
              if (card.card_number) {
                r += ' - '
              }
              r += card.customer_name
            }
            // if (card.rego_number) {
            //   if (card.card_number || card.customer_name) {
            //     r += ' - '
            //   }
            //   r += card.rego_number
            // }
          }
          return r
        },
        onKeyPressDown(ref) {
          if (ref === 'genEvtCreatedBy') {
            this.$refs[ref].focus();
          } else if (ref === 'genEvtCardNbr') {
            this.$refs[ref].focusOnInput();
          } else if (ref === 'genEvtPeople') {
            this.$refs[ref].$el.focus();
          } else {
            this.$refs[ref].$el.children[0].focus();
          }
        },
        onKeyPressUp(ref) {
          if (ref === 'genEvtCreatedBy' || ref === 'genEvtLocation' || ref === 'genEvtTitle') {
            this.$refs[ref].focus();
          } else if (ref === 'genEvtCardNbr') {
            this.$refs[ref].focusOnInput();
          } else if (ref === 'genEvtPeople' || ref === 'genEvtCategory') {
            this.$refs[ref].$el.focus();
          } else {
            this.$refs[ref].$el.children[0].focus();
          }
        },
        onInputGeneralStartDate(value) {
          // if (this.newGeneralEvent.isAllDayEvent) {
          //   return;
          // }
          this.newGeneralEvent.dateEnd = value;
        },
        getMonday(d) {
          d = new Date(d);
          let day = d.getDay();
          let diff = d.getDate() - day + (day == 0 ? -6:1);
          let value = new Date(d.setDate(diff));
          return dayjs(value).format('YYYY-MM-DD');
        },
        collapseEvents() {
          let btn = document.getElementById('test1')
          if (btn) {
            if (btn.classList.contains('active')) {
              btn.classList.remove('active')
              this.isCollapsed = false
              this.$nextTick(() =>{
                this.setCategoryOrder()
              })
            } else {
              btn.classList.add('active')
              this.isCollapsed = true
            }
            localStorage.setItem('isPlannerCollapsed', this.isCollapsed);
          }
        },
        fullScreenEvents(){
          let btn = document.getElementById('customGrid')
          if (btn) {
            if (btn.classList.contains('active')) {
              btn.classList.remove('active')
              this.isFullScreen = false
              this.$nextTick(() =>{
                this.setCategoryOrder()
              })
            } else {
              btn.classList.add('active')
              this.isFullScreen = true
            }
          }
          localStorage.setItem('isFullHeightCalendar', this.isFullScreen);
        },
        setType(type, isOnlySetHeader = false) {
          this.$nextTick(() => {
            if (type !== 'month') {
              setTimeout(() => {
                let header = document.getElementsByClassName('v-calendar-daily__intervals-head')[0]
                let prevEl = document.getElementById('test1')
                let prevEl2 = document.getElementById('customGrid')
                if (header && !prevEl) {
                  let element = document.createElement('i')
                  element.id = 'test1'
                  element.className = 'bx bx-collection'
                  header.appendChild(element)
                  element.addEventListener('click', this.collapseEvents)
                }
                if (header && !prevEl2) {
                  let elemIcon = document.createElement('i')
                  elemIcon.id = 'customGrid'
                  elemIcon.className = 'bx bxs-objects-horizontal-left'
                  header.appendChild(elemIcon)
                  elemIcon.addEventListener('click', this.fullScreenEvents)
                }
              }, 0)
            }
          })
          if (isOnlySetHeader) {
            return
          }
          if (type == 'day') {
            this.focus = dayjs(new Date()).format('YYYY-MM-DD')
          }
          this.$store.commit('setPlannerBoardType', type);
          setTimeout(() => {
            this.scrollToTime();
            if (this.isShowOpenTimeslots) {
              this.hideOpenEstimateTimeslots()
              this.$nextTick(() => {
                this.showOpenEstimateTimeslots()
              })
            }

            this.$nextTick(() =>{
              console.log('type')
              this.setCategoryOrder()
            })

            this.$set(this, 'intervalHeight', this.computedCalendarType !== 'month' ? 100 : 48)
          }, 100)
        },
        filteringCategories() {
          let groups = this.groups.slice();
          let self = this;
          let filteredGroups = _.filter(groups, (gr) => {
            if (gr.id <= -10) {
              return true;
            }
            return gr.name.toLowerCase().includes(self.filterCategoryName.toLowerCase());
          });
          this.filteredGroups.splice(0, this.filteredGroups.length);
          _.forEach(filteredGroups, (gr) =>  {
            this.filteredGroups.push(gr);
          })
        },
        toggleDropdown() {
          if (this.isShowDropdown) {
            this.hideDropdown();
          } else {
            this.showDropdown();
          }
        },
        showDropdown() {
          this.isShowDropdown = true;
          $(this.$refs.dropdown).dropdown().toggle(true);
        },
        hideDropdown() {
          this.isShowDropdown = false;
          $(this.$refs.dropdown).dropdown().toggle(false);
        },
        openNewTabCard(cardId) {
          if (this.$refs['eventMenu-' + this.reactiveSelectedEvent.id]) {
            setTimeout(() => {
              this.$refs['eventMenu-' + this.reactiveSelectedEvent.id].isActive = false;
            }, 0)
          }
          setTimeout(() => {
            window.open(
                window.location.origin + "/cards/view/" + cardId,
                "_blank"
            );
          }, 200)
        },
        bookingEventTimeChanged(v) {
          this.newBookingEvent.timeStart = v
          if (this.isSetTimeByEditMode) {
            this.isSetTimeByEditMode = false;
            return;
          }
          if (!this.newBookingEvent.timeStart.includes('HH') && !this.newBookingEvent.timeStart.includes('mm')) {
            let minsToAdd = 15;
            let time = this.newBookingEvent.timeStart;
            let newTime = new Date(new Date("1970/01/01 " + time).getTime() + minsToAdd * 60000).toLocaleTimeString('en-UK', {
              hour: '2-digit',
              minute: '2-digit',
              hour12: false
            });
            this.newBookingEvent.timeEnd = newTime;
          }
        },
        generalEventTimeChanged(v) {
          this.newGeneralEvent.timeStart = v
          if (this.isSetTimeByEditMode) {
            this.isSetTimeByEditMode = false;
            return;
          }
          if (!this.newGeneralEvent.timeStart.includes('HH') && !this.newGeneralEvent.timeStart.includes('mm')) {
            let minsToAdd = 15;
            let time = this.newGeneralEvent.timeStart;
            let newTime = new Date(new Date("1970/01/01 " + time).getTime() + minsToAdd * 60000).toLocaleTimeString('en-UK', {
              hour: '2-digit',
              minute: '2-digit',
              hour12: false
            });
            this.newGeneralEvent.timeEnd = newTime;
          }
        },
        getDateTitle(start, end) {
          if (!start || !end) {
            return '';
          }
          let [yearS, monthS, dayS] = start.split('-');
          let [yearE, monthE, dayE] = end.split('-');
          if (yearS !== yearE) {
            return dayS + ' ' + this.shortMonths[monthS - 1] + ' ' + yearS + ' - ' + dayE + ' ' + this.shortMonths[monthE - 1] + ' ' + yearE;
          } else if (monthE !== monthS) {
            return dayS + ' ' + this.shortMonths[monthS - 1] + ' - ' + dayE + ' ' + this.shortMonths[monthE - 1] + ' ' + yearE;
          } else if (dayS !== dayE) {
            return dayS + ' - ' + dayE + ' ' + this.shortMonths[monthE - 1] + ' ' + yearE;
          } else {
            return dayS + ' ' + this.shortMonths[monthE - 1] + ' ' + yearE;
          }
        },
        onRegoSelect(item) {
          this.newBookingEvent.cardNumber = item.cardNumber;
          this.newBookingEvent.vehicleRego = item.rego;
          this.newBookingEvent.vehicleMake = item.make;
          this.newBookingEvent.vehicleModel = item.model;
          this.newBookingEvent.vehicleOwnerName = item.vehicleOwner;
          this.newBookingEvent.boards = [];
          let vehicleItem = _.find(this.getterBoardIn, (itm) => {
            return Number(itm.cardId) === Number(item.cardId);
          });
          let departureItem = _.find(this.getterBoardDeparture, (itm) => {
            return Number(itm.cardId) === Number(item.cardId);
          })
          if (vehicleItem) {
            this.newBookingEvent.boards.push('Vehicle in Board');
          }
          if (departureItem) {
            this.newBookingEvent.boards.push('Departure Board')
          }
        },
        onCardNumberSelect(item) {
          this.newBookingEvent.cardNumber = item.cardNumber;
          this.newBookingEvent.vehicleRego = item.rego;
          this.newBookingEvent.vehicleMake = item.make;
          this.newBookingEvent.vehicleModel = item.model;
          this.newBookingEvent.vehicleOwnerName = item.vehicleOwner;
          this.newBookingEvent.boards = [];
          let vehicleItem = _.find(this.getterBoardIn, (itm) => {
            return Number(itm.cardId) === Number(item.cardId);
          });
          let departureItem = _.find(this.getterBoardDeparture, (itm) => {
            return Number(itm.cardId) === Number(item.cardId);
          });


      if (vehicleItem) {
        this.newBookingEvent.boards.push('Vehicle in Board');
      }
      if (departureItem) {
        this.newBookingEvent.boards.push('Departure Board')
      }
    },
    findCardId(name) {
      let card = _.find(this.getterAllCards, (card) => {
        return card?.card_number?.toLowerCase() === name?.toLowerCase();
      })
      if (card) {
        return card.card_id;
      }
      return null;
    },
    formatDate(date) {
      let d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear(),
          hours = '' + d.getHours(),
          minutes = '' + d.getMinutes();

      if (month.length < 2) {
        month = '0' + month;
      }
      if (day.length < 2) {
        day = '0' + day;
      }
      if (hours.length < 2) {
        hours = '0' + hours;
      }

      if (minutes.length < 2) {
        minutes = '0' + minutes
      }

      let date2 = [year, month, day].join('-');
      let time = [hours, minutes].join(':')
      return [date2, time].join(' ');
    },
    toDate(timestamp) {
      if (typeof (timestamp) !== 'number') {
        return timestamp
      }
      let date = new Date(timestamp);
      return this.formatDate(date);
    },
    toTimestamp(date) {
      if (typeof (date) !== 'string') {
        return date
      }
      // "2022-07-25 09:00"
      if (date.includes(':')) {
        let [date1, date2] = date.split(' ');
        let [year, month, day] = date1.split('-');
        let [hours, minutes] = date2.split(':');

        let datum = new Date(year + '-' + month + '-' + day + 'T' + hours + ':' + minutes + ':00');
        return datum.getTime();

      } else {
        let [year, month, day] = date.split('-');
        let datum = new Date(year + '-' + month + '-' + day + 'T00:00:00');
        return datum.getTime();
      }
    },
    startDrag({event, timed}) {
      this.eventsBeforeDragg = _.cloneDeep(this.events)
          if (event && timed) {
            this.dragEvent = event
            this.dragTime = null
            this.extendOriginal = null
          }
      this.$nextTick(() => {
        this.setCategoryOrder()
      })
        },
        startTime (tms) {
          const mouse = this.toTime(tms)

      if (this.dragEvent && this.dragTime === null) {
        let start = this.toTimestamp(this.dragEvent.start);

        this.dragTime = mouse - start
      }
      this.$nextTick(() => {
        this.setCategoryOrder()
      })
    },
    extendBottom(event) {
      this.createEvent = event
      this.createStart = this.toTimestamp(event.start)
      this.extendOriginal = this.toTimestamp(event.end)
    },
    mouseMove(tms) {
      const mouse = this.toTime(tms)

      if (this.dragEvent && this.dragTime !== null) {
        const start = this.toTimestamp(this.dragEvent.start)
        const end = this.toTimestamp(this.dragEvent.end)
        const duration = end - start
        const newStartTime = mouse - this.dragTime
        const newStart = this.roundTime(newStartTime)
        const newEnd = newStart + duration

        this.dragEvent.start = newStart;
        this.dragEvent.end = newEnd;
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false)
        const min = Math.min(mouseRounded, this.createStart)
        const max = Math.max(mouseRounded, this.createStart)

        this.createEvent.start = min
        this.createEvent.end = max
      }
      this.$nextTick(() => {
        this.setCategoryOrder()
      })
    },
    endDrag() {
      if (this.dragEvent && this.dragEvent.id) {
        let event = _.find(this.eventsBeforeDragg, (evt) => {
              return Number(evt.id) == Number(this.dragEvent.id)
            })
            if (event && !(event.start == this.dragEvent.start && event.end == this.dragEvent.end)) {
              this.updateEvent(this.dragEvent, false)
            }
          }
          if (this.createEvent && this.createEvent.id) {
            let event = _.find(this.eventsBeforeDragg, (evt) => {
              return Number(evt.id) == Number(this.createEvent.id)
            })
            if (event && !(event.start == this.createEvent.start && event.end == this.createEvent.end)) {
              this.updateEvent(this.createEvent, false)
            }
          }
          this.dragTime = null
          this.dragEvent = null
          this.createEvent = null
          this.createStart = null
          this.extendOriginal = null
          this.eventsBeforeDragg = []
    },
    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal
        } else {
          const i = this.events.indexOf(this.createEvent)
          if (i !== -1) {
            this.events.splice(i, 1)
          }
        }
      }

      this.createEvent = null
      this.createStart = null
      this.dragTime = null
      this.dragEvent = null
    },
    roundTime(time, down = true) {
      const roundTo = 15 // minutes
      const roundDownTime = roundTo * 60 * 1000

      return down
          ? time - time % roundDownTime
          : time + (roundDownTime - (time % roundDownTime))
    },
    toTime(tms) {
      return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
    },
        checkDate(v) {
          let d = null

          if (this.getterPlannerBoardType == 'day') {
            d = dayjs(new Date(v)).isoWeekday(1).format('YYYY-MM-DD')
          } else if (this.getterPlannerBoardType == 'week') {
            d = dayjs(new Date(v)).isoWeekday(1).format('YYYY-MM-DD')
          } else if (this.getterPlannerBoardType == 'month') {
            d = dayjs(new Date(v)).startOf('month').format('YYYY-MM-DD')
          }

          if (d) {
            let diff = dayjs(new Date()).diff(d, 'day');
            console.log(diff, this.isLoadedPrev, 'dddddd')
            if (diff >= 32 && !this.isLoadedPrev) {
              this.loadEventsFunction(false, true)
            }
          }
        },
    changedPlanner() {
          if (this.cal) {
            if (this.getterPlannerBoardType == 'day') {
              this.cal.renderProps.maxDays = 1
            } else if (this.getterPlannerBoardType == 'week') {
              this.cal.renderProps.maxDays = this.computedWeekDays.length
            } else if (this.getterPlannerBoardType == 'month') {
              this.cal.renderProps.maxDays = this.computedWeekDays.length
            }
          }

          this.checkDate(this.focus)

          if (this.isShowOpenTimeslots) {
            this.hideOpenEstimateTimeslots()
            this.$nextTick(() => {
              this.showOpenEstimateTimeslots()
            })
          }
          this.$nextTick(() =>{
            console.log('changed')
            this.setCategoryOrder()
          })
        },
        getEvents () {
          return
          const events = []
          let self = this;
          _.forEach(this.events, (e) =>  {
            let start = '';
            let end = '';
            if (this.getterPlannerBoardType !== 'month') {
              start = self.toTimestamp(e.dateStart + ' ' +  e.timeStart.slice(0, 5));
              end = self.toTimestamp(e.dateEnd + ' ' +  e.timeEnd.slice(0, 5));
            } else {
              start = e.dateStart + ' ' +  e.timeStart.slice(0, 5);
              end = e.dateEnd + ' ' +  e.timeEnd.slice(0, 5);
            }

        let event = {
          name: e.name,
          color: e.color,
          id: e.id,
          timed: true,
          category: e.groupId,
          ...e
        };
        // event.start = '';
        // event.end = end;
        // events.push(event);
      })
      this.events.splice(0, this.events.length);
      _.forEach(events, e => {
        this.events.push(e);
      })
      // this.events = events
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
    rndElement(arr) {
      return arr[this.rnd(0, arr.length - 1)]
    },
    getRandomNumber(min, max) {
      let rand = min - 0.5 + Math.random() * (max - min + 1);
      return Math.round(rand);
    },
    loadCategories() {
      Axios.get('/fe/planner-board/groups')
          .then(response => {
            if (response.data.groups && response.data._status) {
              this.groups.splice(0, this.groups.length);
              _.forEach(response.data.groups, (gr) => {
                this.groups.push({
                  isOpenColorPicker: false,
                  isSetColor: false,
                  ...gr,
                });
              });
              this.filteringCategories();
            }
          })
          .finally(() => {
          })
    },
    focusOnCategory(id) {
      setTimeout(() => {
        this.$refs['group-' + id][0].focus();
      })
    },
    addEmptyNewGroup() {
      let id = this.id--;
      this.groups.push({
        name: '',
        color: '',
        id: id,
        isOpenColorPicker: false,
        isSetColor: false,
      });
      this.filteringCategories();
      setTimeout(() => {
        this.$refs['group-' + id][0].focus();
      })
    },
    removeEmptyNewGroup(id) {
      let index = _.findIndex(this.groups, (gr) => {
        return Number(gr.id) === Number(id);
      });
      if (index !== -1) {
        this.groups.splice(index, 1);
      }
      ;
      this.filteringCategories();
    },
    addNewGroup(name, color, newEventId = null) {
      if (!color) {
        toastr.error('Color is required')
        return
      }
      if (!name) {
        toastr.error('Name is required')
        return
      }
      Axios.post('/fe/planner-board/group/add', {
        name: name,
        color: color,
      })
          .then((response) => {
            if (response.data && response.data._status && response.data.group) {
              toastr.success('Added new category');
              if (newEventId) {
                let index = _.findIndex(this.groups, (gr) => {
                  return Number(gr.id) === Number(newEventId);
                });
                if (index !== -1) {
                  this.groups.splice(index, 1);
                }
              }
              this.groups.push({
                ...response.data.group,
                isOpenColorPicker: false,
                isSetColor: false,
              });
              this.filteringCategories();
            }
          })
    },
    updateGroup(id, name, color) {
      if (id <= -10) {
        this.addNewGroup(name, color, id);
        return;
      }
      Axios.post('/fe/planner-board/group/update', {
        name: name,
        color: color,
        id: id,
      })
          .then((response) => {
            if (response.data && response.data._status && response.data.group) {
              toastr.success('Updated category');
              let updatedGroup = _.find(this.groups, (gr) => {
                return Number(gr.id) === Number(id);
              })
              if (updatedGroup) {
                updatedGroup.name = response.data.group.name;
                updatedGroup.color = response.data.group.color;
              }
              this.filteringCategories();
            }
          })
    },
    showColorPicker(gr) {
      gr.isOpenColorPicker = true;
      if (!gr.isSetColor) {
        gr.isSetColor = true;
        this.currentColor = gr.color;
      }
    },
    removeGroup(id) {
      if (id <= -10) {
        this.removeEmptyNewGroup(id);
        return;
      }
      Axios.post('/fe/planner-board/group/remove', {
        id: id,
      })
          .then((response) => {
            if (response.data && response.data._status) {
              toastr.error('Removed category')
              let index = _.findIndex(this.groups, (gr) => {
                return Number(gr.id) === Number(id);
              });
              if (index !== -1) {
                this.groups.splice(index, 1);
              }
              ;
              this.filteringCategories();
            }
          })
    },
    loadEvents(isShowNProgress = false) {

      if (isShowNProgress) {
        NProgress.start()
      }
      ;

          if (_.isEmpty(this.getBookingItems)) {
           this.loadEventsFunction(isShowNProgress, false)
           // this.loadEventsFunction(isShowNProgress, true)
          } else {
            // this.loadEventsFunction(isShowNProgress, true)
          }

      setTimeout(() => {
        if (isShowNProgress) {
          NProgress.done();
        }
      }, 2000)
    },
        loadEventsFunction(isShowNProgress, isPrev = false) {
          this.$store.dispatch('board/loadBookingBoard', {isPrev: isPrev}).then((r) => {
            if (r.data.production && !_.isEmpty(r.data.production)) {
              this.settings = r.data.production
            }
            if (r.data.attempts) {
              this.attempts = r.data.attempts
            }
            if (r.data.reasons) {
              this.reasons = r.data.reasons
            }
            if (isShowNProgress) {
              if (this.$route.params.eventId) {
                this.showOnSiteMenu(this.$route.params.isFromNewCard)
                setTimeout(() => {
                  this.selectCard(this.$route.params.eventId,true, true, this.$route.params.isFromNewCard)
                }, 100)
                this.focus = new Date((this.$route.params.eventDate))
              }
            }
          })
        },
    updateEvents(data) {
      if (this.dragEvent) {
        return
      }
      if (this.lastDraggedEvent) {
        let ev = _.find(data, (evt) => {
          return Number(evt.id) == Number(this.lastDraggedEvent)
        })
        if (ev) {
          ev = _.find(_.cloneDeep(this.events), (evt) => {
            return Number(evt.id) == Number(this.lastDraggedEvent)
          })
        }
      }
      this.lastDraggedEvent = null
      this.events.splice(0, this.events.length);
      let vm = this;
      _.forEach(data, function (item) {
        let newEvent = {
          name: item.title,
          color: item.color,
          start: item.timeStart !== null ? item.dateStart + ' ' + item.timeStart.slice(0, 5) : item.dateStart,
          end: item.timeEnd !== null ? item.dateEnd + ' ' + item.timeEnd.slice(0, 5) : item.dateEnd,
          timed: true,
          category: item.groupId,
          ...item
        }
        vm.events.push(newEvent)
      })

      if (this.createdOnSiteCard) {
        this.selectCard(this.createdOnSiteCard, true)
        let event = _.find(_.cloneDeep(this.events), (evt) => {
          return Number(this.createdOnSiteCard) == evt?.card?.id && evt.type == 'on-site'
        })
        if (event) {
          this.createdOnSiteCard = null
        }
      }
    },
    convertTime(time) {
      let newTime = time.split(':');
      if (newTime.length === 2) {
        return time + ':00'
      }
      return time;
    },
    convertDate(date) {
      if (!date) {
            return ''
          }
          if (_.includes(date, '/')) {
            date = date.replaceAll('/', '-')
            let [day, month, year] = date.split('-');
            if (day.length === 4) {
              return date;
            }
            return [year, month, day].join('-');
          }
          return date
    },
    addGeneralEvent() {
      if (!this.newGeneralEvent.dateStart) {
        toastr.error('Field "Date Booked (Start)" must not be empty');
        return;
      } else if (!this.newGeneralEvent.timeStart && !this.newGeneralEvent.isAllDayEvent) {
        toastr.error('Field "Time Start" must not be empty');
        return;
      } else if (!this.newGeneralEvent.dateEnd) {
        toastr.error('Field "Date Completed" must not be empty');
        return;
      } else if (!this.newGeneralEvent.timeEnd && !this.newGeneralEvent.isAllDayEvent) {
        toastr.error('Field "Time End" must not be empty');
        return;
      } else if (!this.newGeneralEvent.title) {
        toastr.error('Field "Title" must not be empty');
        return;
      }
      let selectedCategory = _.find(this.categoryOptions, (c) => {
        return c.id == this.newGeneralEvent.category;
      })
      NProgress.start();
      let data = {
        'type': this.newGeneralEvent.isAllDayEvent ? 'all-day' : 'general',
        "dateStart": this.convertDate(this.newGeneralEvent.dateStart), // required
        "cardId": this.findCardId(this.newGeneralEvent.cardNumber),
        "timeStart": this.newGeneralEvent.timeStart, // required
        "dateEnd": this.convertDate(this.newGeneralEvent.dateEnd), // required
        "timeEnd": this.newGeneralEvent.timeEnd, // required
        "title": this.newGeneralEvent.title, // required
        "note": this.newGeneralEvent.note, // optional
        "color": selectedCategory.color, // optional
        "cardNumber": this.newGeneralEvent.cardNumber, // optional
        reminder: +this.newGeneralEvent.reminder,
        isWorkDay: this.newGeneralEvent.isWorkDay,
        people: this.newGeneralEvent.people?.map(user => {
          return user.id
        }),
        location: this.newGeneralEvent.location,
        isAllDayEvent: this.newGeneralEvent.isAllDayEvent,
        groupId: this.newGeneralEvent.category,
      }
      Axios.post('/fe/planner-board/add', data)
          .then((response) => {
            if (response.data._status && response.data.event) {
              this.$store.commit('board/addBookingItem', response.data.event)
            }
          })
          .finally(() => {
            this.newGeneralEvent = {
              "dateStart": "", // required
              "timeStart": "", // required
              "dateEnd": "", // required
              "timeEnd": "", // required
              "title": "", // required
              "note": "", // optional
              "color": "", // optional
              "cardNumber": "", // optional
              isAllDayEvent: false,
              isWorkDay: true,
              location: '',
              category: null,
              createdBy: this.userInfo.full_name,
              reminder: null,
              people: []
            };
            this.$refs.generalEventModal.hide();
            NProgress.done();
          });

    },
    onClickSaveGeneralEvent() {
      if (this.isOpenEditGeneralEvent) {
        this.updateGeneralEvent();
      } else {
        this.addGeneralEvent();
      }
    },
    onClickSaveBookingEvent() {
      if (this.isOpenEditVehicleBookingEvent) {
        this.updateBookingEvent();
      } else {
        this.addBookingEvent();
      }
    },
    updateGeneralEvent() {
      let selectedCategory = _.find(this.categoryOptions, (c) => {
        return c.id == this.newGeneralEvent.category;
      })
      Axios.post(
          "/fe/planner-board/update",
          {
            "id": this.selectedEvent.id,
            'type': this.newGeneralEvent.type,
            "dateStart": this.convertDate(this.newGeneralEvent.dateStart),
            "timeStart": this.convertTime(this.newGeneralEvent.timeStart),
            "dateEnd": this.convertDate(this.newGeneralEvent.dateEnd),
            "timeEnd": this.convertTime(this.newGeneralEvent.timeEnd),
            "cardId": this.findCardId(this.newGeneralEvent.cardNumber),
            "title": this.newGeneralEvent.title,
            "note": this.newGeneralEvent.note,
            isWorkDay: this.newGeneralEvent.isWorkDay,
            "color": selectedCategory.color,
            "cardNumber": this.newGeneralEvent.cardNumber,
            reminder: +this.newGeneralEvent.reminder,
            location: this.newGeneralEvent.location,
            groupId: this.newGeneralEvent.category,
            people: this.newGeneralEvent.people?.map(user => {
              return user.id
            }),
          }
      ).then(response => {
        if (response.data && response.data._status) {
          // this.loadEvents();
          toastr.success('Updated!');
        }
      })
          .finally(() => {
            this.$refs.generalEventModal.hide();
          })
    },
    updateOnSiteEventWithoutDebounce() {
      if (this.isUpdatedFromUpdater) {
            return
          }
          console.log(this.isConflict, this.isConflictDayAvailable(), !this.activeCardEvent.dateStart)
          if (this.isConflict || this.isConflictDayAvailable() || !this.activeCardEvent.dateStart) {
        return
      }

      let event = _.find(_.cloneDeep(this.events), (evt) => {
        return Number(evt.id) == Number(this.activeCardEvent.id)
      })
      if (!event) {
        return
      }
      let timeDuration = null
      let difference = dayjs(event.dateEnd + ' ' + event.timeEnd).diff(event.dateStart + ' ' + event.timeStart, 'minute')
      if (difference == 15 || difference == 30 || difference == 45) {
        timeDuration = difference
      } else {
        timeDuration = this.planner.timeDuration
      }

      if (event.note == this.activeCardEvent.note && event.reminder == this.activeCardEvent.reminder && event.timeStart == this.activeCardEvent.timeStart &&
          event.dateEnd == this.activeCardEvent.dateEnd && event.timeEnd == this.activeCardEvent.timeEnd && event.dateStart == this.convertDate(this.activeCardEvent.dateStart)
          && timeDuration == this.activeCardEvent.timeDuration) {
        return
      }
      let data = {
        "id": this.activeCardEvent.id,
        "note": this.activeCardEvent.note,
        reminder: this.activeCardEvent.reminder,
        dateStart: this.convertDate(this.activeCardEvent.dateStart),
        timeStart: this.activeCardEvent.timeStart,
        dateEnd: dayjs(this.convertDate(this.activeCardEvent.dateStart) + ' ' + this.activeCardEvent.timeStart).add(this.activeCardEvent.timeDuration, 'minute').format('YYYY-MM-DD'),
        timeEnd: dayjs(this.convertDate(this.activeCardEvent.dateStart) + ' ' + this.activeCardEvent.timeStart).add(this.activeCardEvent.timeDuration, 'minute').format('HH:mm:ss'),
        people: this.activeCardEvent.people?.map(user => {
          return user.id
        }),
      }

      let originalEvent = _.find(_.cloneDeep(this.events), (e) => {
        return Number(e.id) == Number(this.activeCardEvent.id)
      })
      if (originalEvent) {
        originalEvent.dateStart = data.dateStart
        originalEvent.timeStart = data.timeStart
        originalEvent.dateEnd = data.dateEnd
        originalEvent.timeEnd = data.timeEnd
      }
    },
    updateOnSiteEvent(isIgnoreCheck = false, isChangedTime = false) {
          if (this.isUpdatedFromUpdater) {
            this.isUpdatedFromUpdater = false
            return
          }

          if (!this.activeCardEvent.dateStart) {
            return
          }

          if (this.isConflictDayAvailable()) {
            if (isChangedTime) {
              toastr.error('Booking is outside of allowable time block');
              this.isOnSiteTimeError = true
              this.isOnSiteDateError = false
            } else {
              toastr.error('Booking Date is outside allowable day block');
              this.isOnSiteDateError = true
              this.isOnSiteTimeError = false
            }
            return
          }
          this.isOnSiteTimeError = false
          this.isOnSiteDateError = false
      if (this.isConflict) {
        return
      }

      let event = _.find(_.cloneDeep(this.events), (evt) => {
        return Number(evt.id) == Number(this.activeCardEvent.id)
      })
      if (!event) {
        return
      }
      let timeDuration = null
      let difference = dayjs(event.dateEnd + ' ' + event.timeEnd).diff(event.dateStart + ' ' + event.timeStart, 'minute')
      if (difference == 15 || difference == 30 || difference == 45) {
        timeDuration = difference
      } else {
        timeDuration = this.planner.timeDuration
      }

      if (event.note == this.activeCardEvent.note && event.reminder == this.activeCardEvent.reminder && event.timeStart == this.activeCardEvent.timeStart &&
          event.dateEnd == this.activeCardEvent.dateEnd && event.timeEnd == this.activeCardEvent.timeEnd && event.dateStart == this.convertDate(this.activeCardEvent.dateStart)
          && timeDuration == this.activeCardEvent.timeDuration && !isIgnoreCheck) {
        return
      }
      let data = {
        "id": this.activeCardEvent.id,
        "note": this.activeCardEvent.note,
        isAttendedBooking: this.activeCardEvent.isAttendedBooking,
            attempt: this.activeCardEvent.attempt,
            reason: this.activeCardEvent.reason,
            reminder: this.activeCardEvent.reminder,
            dateStart: this.convertDate(this.activeCardEvent.dateStart),
            timeStart: this.activeCardEvent.timeStart,
        type: 'on-site',
        dateEnd: dayjs(this.convertDate(this.activeCardEvent.dateStart) + ' ' + this.activeCardEvent.timeStart).add(this.activeCardEvent.timeDuration, 'minute').format('YYYY-MM-DD'),
        timeEnd: dayjs(this.convertDate(this.activeCardEvent.dateStart) + ' ' + this.activeCardEvent.timeStart).add(this.activeCardEvent.timeDuration, 'minute').format('HH:mm:ss'),
        people: this.activeCardEvent.people?.map(user => {
          return user.id
        }),
      }

      let originalEvent = _.find(_.cloneDeep(this.events), (e) => {
        return Number(e.id) == Number(this.activeCardEvent.id)
      })
      if (originalEvent) {
        originalEvent.dateStart = data.dateStart
        originalEvent.timeStart = data.timeStart
        originalEvent.dateEnd = data.dateEnd
        originalEvent.timeEnd = data.timeEnd
      }

      Axios.post(
          "/fe/planner-board/update", data
      ).then(response => {
        if (response.data && response.data._status) {
          // this.loadEvents();
          toastr.success('Updated!');
        }
      })
          .finally(() => {
            if (originalEvent) {
              originalEvent.dateStart = data.dateStart
              originalEvent.timeStart = data.timeStart
              originalEvent.dateEnd = data.dateEnd
              originalEvent.timeEnd = data.timeEnd
            }
          })
    },
    updateBookingEvent() {
      let dataForBoard = [];
      let dataForRemovedBoards = [];

      if (this.newBookingEvent.boards.includes('Vehicle in Board')) {
        dataForBoard.push('in');
      }
      if (this.newBookingEvent.boards.includes('Departure Board')) {
        dataForBoard.push('departure');
      }
      if (this.selectedEvent.card && this.selectedEvent.card.boardIn && this.selectedEvent.card.boardIn.id && !dataForBoard.includes('in')) {
        dataForRemovedBoards.push(this.selectedEvent.card.boardIn.id);
      }
      if (this.selectedEvent.card && this.selectedEvent.card.boardDeparture && this.selectedEvent.card.boardDeparture.id && !dataForBoard.includes('departure')) {
        dataForRemovedBoards.push(this.selectedEvent.card.boardDeparture.id);
      }

      let selectedCategory = _.find(this.categoryOptions, (c) => {
        return c.id == this.newBookingEvent.category;
      })

      Axios.post(
          "/fe/planner-board/update",
          {
            "id": this.selectedEvent.id,
            type: 'vehicle',
            "dateStart": this.convertDate(this.newBookingEvent.dateStart),
            "timeStart": this.convertTime(this.newBookingEvent.timeStart),
            "dateEnd": this.convertDate(this.newBookingEvent.dateEnd),
            "timeEnd": this.convertTime(this.newBookingEvent.timeEnd),
            "title": this.newBookingEvent.title,
            "note": this.newBookingEvent.note,
            "color": selectedCategory.color,
            // "cardNumber": this.newBookingEvent.cardNumber,
            reminder: this.newBookingEvent.reminder,
            addToBoard: dataForBoard,
            removeFromBoard: dataForRemovedBoards,
            groupId: this.newBookingEvent.category,
            "cardId": this.findCardId(this.newBookingEvent.cardNumber),
          }
      ).then(response => {
        if (response.data && response.data._status) {
          // this.loadEvents();
          toastr.success('Updated!');
        }
      })
          .finally(() => {
            this.$refs.bookingEventModal.hide();
          })
    },
    addBookingEvent() {
      if (!this.newBookingEvent.dateStart) {
        toastr.error('Field "Date Booked (Start)" must not be empty');
        return;
      } else if (!this.newBookingEvent.timeStart) {
        toastr.error('Field "Time Start" must not be empty');
        return;
      } else if (!this.newBookingEvent.dateEnd) {
        toastr.error('Field "Date Completed" must not be empty');
        return;
      } else if (!this.newBookingEvent.timeEnd) {
        toastr.error('Field "Time End" must not be empty');
        return;
      } else if (!this.newBookingEvent.cardNumber) {
        toastr.error('Field "Card Number" must not be empty');
        return;
      }

      let title = '';
      if (this.newBookingEvent.cardNumber) {
        title += this.newBookingEvent.cardNumber
        if (this.newBookingEvent.vehicleRego || this.newBookingEvent.vehicleMake || this.newBookingEvent.vehicleModel) {
          title += ' - '
        }
      }
      if (this.newBookingEvent.vehicleRego) {
        title += this.newBookingEvent.vehicleRego + ' ';
      }
      if (this.newBookingEvent.vehicleMake) {
        title += this.newBookingEvent.vehicleMake + ' ';
      }
      if (this.newBookingEvent.vehicleModel) {
        title += this.newBookingEvent.vehicleModel;
      }

      let dataForBoard = [];
      if (this.newBookingEvent.boards.includes('Vehicle in Board')) {
        dataForBoard.push('in');
      }
      if (this.newBookingEvent.boards.includes('Departure Board')) {
        dataForBoard.push('departure');
      }
      let selectedCategory = _.find(this.categoryOptions, (c) => {
        return c.id == this.newBookingEvent.category;
      })
      // NProgress.start();
      let data = {
        type: 'vehicle',
        "cardId": this.findCardId(this.newBookingEvent.cardNumber), // required
        "dateStart": this.convertDate(this.newBookingEvent.dateStart), // required
        "timeStart": this.newBookingEvent.timeStart, // required
        "dateEnd": this.convertDate(this.newBookingEvent.dateEnd), // required
        "timeEnd": this.newBookingEvent.timeEnd, // required
        "title": title, // optional
        "note": this.newBookingEvent.note, // optional
        "color": selectedCategory.color, // optional
        reminder: +this.newBookingEvent.reminder,
        'addToBoard': dataForBoard,
        groupId: this.newBookingEvent.category,
      }
      Axios.post('/fe/planner-board/add', data)
          .then((response) => {
            if (response.data._status && response.data.event) {
              this.$store.commit('board/addBookingItem', response.data.event)
            }
          })
          .finally(() => {
            this.newBookingEvent = {
              "cardId": null,
              "dateStart": "",
              "timeStart": "",
              "dateEnd": "",
              "timeEnd": "",
              "title": "",
              "note": "",
              "color": "",
              category: null,

              boards: [],
              cardNumber: '',
              vehicleMake: '',
              vehicleRego: '',
              vehicleModel: '',
              vehicleOwnerName: '',
              reminder: null,
            };
            this.$refs.bookingEventModal.hide();
            NProgress.done();
          });
    },
        isOverbookedByTotal(d) {
          let b = !!(this.getCurrentDayTotal(d) && this.computedDayTotal && this.getCurrentDayTotal(d) > this.computedDayTotal)
          if (b) {
            this.addToOverbooked(d)
          } else {
            this.removeFromOverbooked(d)
          }
          return b
        },
        addToOverbooked(d) {
          if (!_.includes(this.isOverbookedArray, d)) {
            this.isOverbookedArray.push(d)
          }
        },
        removeFromOverbooked(d) {
          if (_.includes(this.isOverbookedArray, d)) {
            this.isOverbookedArray.splice(this.isOverbookedArray.indexOf(d), 1)
          }
        },
    addVehicleBookingEventNew(data) {
      if (!this.isOpenDay(data.dateStart)) {
        return
      }
      let dataClone = _.cloneDeep(data)
      let days = []
      if (data.dateStart >= data.dateEnd) {
        days.push(data.dateStart)
      } else  {
        let diff = dayjs(data.dateEnd).diff(data.dateStart, 'day');
        for (let i = 0; i <= diff; i++) {
          days.push(dayjs(data.dateStart).add(i, 'day').format('YYYY-MM-DD'))
        }
      }

      let b = _.some(days, (d) => {
        return _.includes(this.isOverbookedArray, d)
      })
      if (b) {
        this.vehicleAddData = dataClone
        this.$refs.overbookedModal.show()
      } else {
        Axios.post('/fe/planner-board/add', dataClone)
            .then((response) => {
              if (response.data._status && response.data.event) {
                this.$store.commit('board/addBookingItem', response.data.event)
              }
            })
      }
    },
    addVehicleBookingNewFromModal() {
      this.$refs.overbookedModal.hide()
      Axios.post('/fe/planner-board/add', this.vehicleAddData)
          .then((response) => {
            this.vehicleAddData = null
            if (response.data._status && response.data.event) {
              this.$store.commit('board/addBookingItem', response.data.event)
            }
          })
    },
    updateEvent(event, isNeedLoading = true) {
      let start = dayjs(new Date(new Date(event.start)))
      // let start = dayjs(new Date(new Date(event.start).setMinutes(0))).format('YYYY-MM-DD HH:mm:ss')
      let end = start.add(this.planner.timeDuration, 'minute').format('YYYY-MM-DD HH:mm:ss')
      start = start.format('YYYY-MM-DD HH:mm:ss')

      let countOfEvents = _.filter(this.events, (evt) => {
        return Number(evt.id) !== Number(event.id) && evt.type == 'on-site' && dayjs(evt.dateStart + ' ' + evt.timeStart).isBetween(start, end, null, '[]') && dayjs(evt.dateEnd + ' ' + evt.timeEnd).isBetween(start, end, null, '[]')
      })?.length

      let originalEvent = _.find(_.cloneDeep(this.events), (evnt) => {
        return Number(evnt.id) == Number(event.id)
      })

      if (countOfEvents >= this.planner.maxNbrPerSlot) {
        if (originalEvent) {
          originalEvent.start = new Date(event.dateStart + ' ' + event.timeStart).getTime()
          originalEvent.end = new Date(event.dateEnd + ' ' + event.timeEnd).getTime()
        }
        return
      }

      if (originalEvent) {
        originalEvent.dateStart = dayjs(new Date(event.start)).format('YYYY-MM-DD')
        originalEvent.dateEnd = dayjs(new Date(event.end)).format('YYYY-MM-DD')

        originalEvent.timeStart = dayjs(new Date(event.start)).format('HH:mm:ss')
        originalEvent.timeEnd = dayjs(new Date(event.end)).format('HH:mm:ss')
        if (this.activeCardEvent && Number(this.activeCardEvent.id) == Number(originalEvent.id)) {
          this.activeCardEvent.dateStart = dayjs(new Date(event.start)).format('YYYY-MM-DD')
          this.activeCardEvent.dateEnd = dayjs(new Date(event.end)).format('YYYY-MM-DD')

          this.activeCardEvent.timeStart = dayjs(new Date(event.start)).format('HH:mm:ss')
          this.activeCardEvent.timeEnd = dayjs(new Date(event.end)).format('HH:mm:ss')
        }
      }

      Axios.post(
          "/fe/planner-board/update",
          {
            id: event.id,
            dateStart: this.toDate(event.start),
            dateEnd: this.toDate(event.end),
          }
      ).then(response => {
        this.lastDraggedEvent = event.id
        if (isNeedLoading) {
          // this.loadEvents();
        }
      })
    },
    deleteEvent(id) {
      this.$refs.menu.save()
      NProgress.start();
      Axios.post(
          "/fe/planner-board/delete",
          {
            id: id,
          }
      ).then(response => {
        let event = _.find(this.events, function (item) {
          return item.id == id
        })
        let index = this.events.indexOf(event)
        this.events.splice(index, 1)
        toastr.error("Event Removed");
        this.$refs.menu.save()
      })
          .finally(() => {
            NProgress.done();
          })
    },
    editEvent(id) {
      this.selectedEvent = _.find(this.computedEvents, (e) => {
        return e.id == id
      })
      this.isSetTimeByEditMode = true;
      this.isOpenEditVehicleBookingEvent = true;
      this.isOpenEditGeneralEvent = true;
      if (this.selectedEvent.type == 'vehicle') {

        this.isVehicleBooking = true
        this.isEstimateBooking = false

        this.$nextTick(() => {
          this.$refs.vehicleBooking.selectVehicleCard(this.selectedEvent.card.id, true)
        })
        return

        this.newBookingEvent = {
          "cardId": this.selectedEvent.card.id,
          "dateStart": this.selectedEvent.dateStart,
          "type": this.selectedEvent.type,
          "timeStart": this.selectedEvent.timeStart,
          "dateEnd": this.selectedEvent.dateEnd,
          "timeEnd": this.selectedEvent.timeEnd,
          "title": this.selectedEvent.title,
          "note": this.selectedEvent.note,
          "color": this.selectedEvent.color,
          boards: [],
          category: this.selectedEvent.groupId,

          cardNumber: this.selectedEvent.card.number,
          vehicleMake: this.selectedEvent.card.vehicleMake,
          vehicleRego: this.selectedEvent.card.vehicleRego,
          vehicleModel: this.selectedEvent.card.vehicleModel,
          vehicleOwnerName: this.selectedEvent.card.vehicleOwner,
          reminder: +this.selectedEvent.reminder,
        };
        if (this.selectedEvent && this.selectedEvent.card && this.selectedEvent.card.boardIn && this.selectedEvent.card.boardIn.id) {
          this.newBookingEvent.boards.push('Vehicle in Board');
        }
        if (this.selectedEvent && this.selectedEvent.card && this.selectedEvent.card.boardDeparture && this.selectedEvent.card.boardDeparture.id) {
          this.newBookingEvent.boards.push('Departure Board');
        }
        this.$refs.bookingEventModal.show()
      } else {
        this.newGeneralEvent = {
          category: this.selectedEvent.groupId,
          "dateStart": this.selectedEvent.dateStart, // required
          "timeStart": this.selectedEvent.timeStart, // required
          "dateEnd": this.selectedEvent.dateEnd, // required
          "timeEnd": this.selectedEvent.timeEnd, // required
          "title": this.selectedEvent.title, // required
          "note": this.selectedEvent.note, // optional
          "color": this.selectedEvent.color, // optional
          "cardNumber": this.selectedEvent.cardNumber, // optional
          reminder: +this.selectedEvent.reminder,
          isAllDayEvent: this.selectedEvent.isAllDayEvent,
          isWorkDay: this.selectedEvent.isWorkDay,

          location: this.selectedEvent.location,
          type: this.selectedEvent.type,
          // createdBy: this.selectedEvent.createdBy,
          createdBy: this.userInfo.full_name,
          people: this.getUsersByIds(this.selectedEvent.people)
        };
        this.$refs.generalEventModal.show();
      }
    },
    getUsersByIds(ids) {
      return _.filter(this.computedUsers, (u) => {
        return _.includes(ids, u.id)
      })
    },
    closeEditModal() {
      this.isOpenEditGeneralEvent = false;
      this.isOpenEditVehicleBookingEvent = false;
      this.newBookingEvent = {
        "cardId": null,
        "dateStart": "",
        "timeStart": "",
        "dateEnd": "",
        "timeEnd": "",
        "title": "",
        "note": "",
        "color": "",
        category: null,
        boards: [],
        cardNumber: '',
        vehicleMake: '',
        vehicleRego: '',
        vehicleModel: '',
        vehicleOwnerName: '',
        reminder: null,
      };
      this.newGeneralEvent = {
        "dateStart": "", // required
        "timeStart": "", // required
        "dateEnd": "", // required
        "timeEnd": "", // required
        "title": "", // required
        "note": "", // optional
        "color": "", // optional
        "cardNumber": "", // optional
        category: null,
        location: '',
        isAllDayEvent: false,
        isWorkDay: true,
        createdBy: '',
        reminder: null,
        people: []
      };
    },
    showEvent({nativeEvent, event}) {
      console.log('event', event, )
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
        console.log(window.innerWidth)
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

          nativeEvent.stopPropagation()
        },
        getEventColor (event) {
          return event.color
        },
        viewDay ({ date }) {
          this.focus = date
          this.$store.commit("setPlannerBoardType", 'day');
          setTimeout(() => {
            this.scrollToTime();
            this.setType('', true)
          }, 100)
        },
        setCompactSidebar() {
          this.$nextTick(() => {
            document.body.classList.add('compact-sidebar');
          });
        },
        getCurrentTime() {
          return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0
        },
        scrollToTime() {
          if (!this.cal) {
            return
          }
          let hrs = new Date().getHours()
          let minutes = new Date().getMinutes()
          if (hrs < 4) {
            hrs = 0
            minutes = 0
          } else if (hrs > 18) {
            hrs = 23
            minutes = 59
          }  else {
            hrs -= 4
          }
          let now = dayjs(new Date()).set('hour', hrs).set('minute', minutes).format('HH:mm:ss')
          this.cal.scrollToTime(now)
        },
        limitText (count) {
          return `and ${count} other people`
        },
        isMobileWeekDays() {
          console.log(screen.orientation.type)
          if(screen.orientation.type == 'portrait-primary') {
            this.weekdays = [1, 2, 3, 4, 5]
          } else {
            this.weekdays = [1, 2, 3, 4, 5, 6, 0]
          }
        },
        updateOrientation() {
          const portrait = window.matchMedia('(orientation: portrait)')
          this.$data._changeHandler = (event) => {
            if (event.matches) {
              this.weekdays = [1, 2, 3, 4, 5]
            } else {
              this.weekdays = [1, 2, 3, 4, 5, 6, 0]
            }
          };
          portrait.addEventListener('change', this.$data._changeHandler)
        }
      },
      created() {
        this.debouncedSavePlannerBoard = _.debounce(this.savePlannerBoard, 1000);
        this.debouncedUpdateOnSiteEvent = _.debounce((v, v2) => {
          this.updateOnSiteEvent(v, v2)
        }, 500);
      },
      mounted() {
        if (!_.isEmpty(this.getBookingItems)) {
          this.updateEvents(_.cloneDeep(this.getBookingItems))
        }
        if (!_.isEmpty(this.getterProduction)) {
          this.settings =_.cloneDeep(this.getterProduction)
        }
        if (!_.isEmpty(this.getterAttempts)) {
          this.attempts = _.cloneDeep(this.getterAttempts)
        }
        if (!_.isEmpty(this.getterReasons)) {
          this.reasons = _.cloneDeep(this.getterReasons)
        }

        if (localStorage.getItem('bookingBoardType') == 'snapshot') {
          this.isBookingsSnapshot = true
        } else if (localStorage.getItem('bookingBoardType') == 'planner') {
          this.isPlannerDisplaying = true;
        } else {
          this.$set(this, 'isCalendarDisplaying', true)
        }
        this.loadCEStatuses()
        this.loadCategories();
        this.setCompactSidebar();
        this.loadEvents(_.isEmpty(this.getBookingItems));
        this.modalPaddingBottom = window.innerHeight - 1200;
        document.querySelector('.site-header').addEventListener('click', this.hideDropdown);
        document.querySelector('.site-sidebar').addEventListener('click', this.hideDropdown);
        this.ready = true
        this.scrollToTime()
        this.isMobileWeekDays()
        this.updateOrientation()
        this.setTitle()
        window.addEventListener('mouseup', this.onMouseUp);
        window.addEventListener('mousemove', this.onMouseMove);
        window.addEventListener('scroll', this.removeAllDraggableElements);
        this.setType('', true)
        if (this.vendorInfo.vendor_id) {
          this.planner = _.cloneDeep(this.vendorInfo.planner)
        }
        this.$set(this, 'intervalHeight', this.computedCalendarType !== 'month' ? 100 : 48)
        window.addEventListener("orientationchange", this.orientationChange);

        this.$nextTick(() => {
          setTimeout(() => {
            if (localStorage.getItem('isPlannerCollapsed') === 'true') {
              this.collapseEvents()
            }
            if (localStorage.getItem('isFullHeightCalendar') === 'true') {
              this.fullScreenEvents()
            }
          }, 0)
        })
        if (this.$route.params.isFromNewCard && !this.$route.params.eventId) {
          this.focus = new Date((this.$route.params.eventDate))
          this.showOnSiteMenu(true)
        }
      },
      unmounted() {
        document.querySelector('.site-header').removeEventListener('click', this.hideDropdown);
        document.querySelector('.site-sidebar').removeEventListener('click', this.hideDropdown);
        window.removeEventListener("orientationchange", this.orientationChange);
      },
      components: {
        Planner,
        NumberFormatter,
        BookingSnapshot,
        DatePicker,
        VueTimepicker,
        Multiselect,
        AdvancedSearch,
        TheTimePicker2,
        TheTimePicker,
        VehicleBooking
      },
      beforeDestroy() {
        clearInterval(this.intervalUpdateTitle);
        const portrait = window.matchMedia('(orientation: portrait)');
        portrait.removeEventListener('change', this.$data._changeHandler);
        this.setTitle(true)
        window.removeEventListener('mousedown', this.onMouseDown);
        window.removeEventListener('mousemove', this.onMouseMove);
        window.removeEventListener('scroll', this.removeAllDraggableElements);
      },
      beforeRouteLeave(to, from, next) {
        document.body.classList.remove('compact-sidebar');
        next();
      },
    }
</script>

<style scoped>
.calendar-area >>> .v-calendar-daily__head{
  max-height: 480px;
  overflow: hidden;
}
.calendar-area .full-screen-grid.v-calendar-daily{
  max-height: calc(100vh - 315px);
  overflow-y: auto;
  scrollbar-width: thin;
  margin-right: 0 !important;
}
.calendar-area .full-screen-grid >>> .v-calendar-daily__head{
  overflow: hidden;
  max-height: 200vh;
}
.calendar-area .full-screen-grid >>> .v-calendar-daily__body{
  display: none;
}
.text-to-end {
  justify-content: end !important;
  padding-right: 10px !important;
}

.text-to-center {
  justify-content: center !important;
  padding-right: 10px !important;
}

.holiday-event {
  justify-content: center !important;
  padding-left: 0px !important;
  font-weight: 700 !important;
  font-size: 13px !important;
}

.weekly-target{
  margin-right: 50px;
}
.planer-date-period-text-block{
  width: 350px;
}
.estimate-booking-settings-body-table-main-item-day {
  padding-top: 25px;
  width: 20% !important;
  margin-right: 10px;
}
.btn-tabs-booking{
  box-sizing: border-box;
  height: 40px;
  border: 1px solid rgba(27, 30, 56, 0.25);
  border-radius: 3px;
  background: white;
}
.calendar-area >>> .v-calendar-daily__day-interval{
  border-top: 1px solid rgb(225, 226, 229) !important;
}
.context-menu-calendar{
  border: 1px solid #bdbdbd;
  font-weight: bold;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.context-menu-calendar .calendar-card-details-context-menu span{
  font-weight: bold;
  padding: 8px 25px 5px 10px !important;
}
.context-menu-calendar .calendar-card-details-context-menu span:hover{
  color: #5E79FF !important;
  background-color: rgba(94, 121, 255, 0.1) !important;
}
.planer-date-period {
  white-space: nowrap;
  position: absolute;
  left: 310px;
}
.V3 .V3.compact-sidebar .planer-date-period{
  left: 160px;
}

.delete-supp-modal >>> .fade.in {
  transform: none;
}

.delete-supp-modal >>> .modal-wizard {
  background-color: rgba(51, 51, 51, 0.50);
}

.delete-supp-modal >>> .modal-dialog {
  height: 200px;
  left: calc(50% - 70px);
  transform: translate(-50%);
  top: 16%;
}

.subtitle-day-week-item {
  font-size: 12px;
  margin-bottom: 4px;
  font-weight: 400;
}

.blocked-estimate-booking-item {
  cursor: not-allowed !important;
}

.calendar-area >>> .v-calendar-daily__intervals-head .bx-collection,
.calendar-area >>> .v-calendar-daily__intervals-head .bxs-objects-horizontal-left{
  font-size: 25px;
  padding-right: 5px;
  padding-top: 5px;
  cursor: pointer;
}
.calendar-area >>> .v-calendar-daily__intervals-head .bxs-objects-horizontal-left{
  margin-bottom: 20px;
}
.calendar-area >>> .v-calendar-daily__scroll-area {
  scrollbar-width: thin;
}

.calendar-area >>> .v-calendar-daily__intervals-head {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.calendar-area >>> .v-calendar-daily__intervals-head .bx-collection.active,
.calendar-area >>> .v-calendar-daily__intervals-head .bxs-objects-horizontal-left.active{
  color: #0f7bd5;
}

.estimate-booking-body-main-right-item-date-time >>> .btn-default {
  color: #333;
  background-color: #fff;
  border: 1px solid rgba(28, 31, 57, 0.25);
  border-radius: 3px;
  height: 32px;
  padding: 0.5rem 0.75rem;
}

.estimate-booking-body-main-right-item-date-time >>> table {
  min-width: 170px;
}

.time-picker-btn >>> .btn-default {
  color: #333;
  background-color: #fff;
  border: 1px solid rgba(28, 31, 57, 0.25);
  border-radius: 3px;
  margin-bottom: 10px;
  height: 32px;
  padding: 0.5rem 0.75rem;
}

.time-picker-btn >>> td:nth-child(2) {
  padding-bottom: 10px;
}

.estimate-booking-settings-body-table-main-item-width >>> .btn-default {
  color: #333;
  background-color: #fff;
  border: 1px solid rgba(28, 31, 57, 0.25);
  border-radius: 3px;
  margin-bottom: 21px !important;
  margin-left: 5px;
  height: 32px;
  padding: 0.5rem 0.75rem;
}

.planner-page >>> .modal-content {
  min-width: 400px;
}

.calendar-area >>> .v-calendar-daily_head-weekday {
  font-weight: 700;
  font-size: 13px;
}

.calendar-area >>> .v-calendar-daily_head-day-label .v-btn__content {
  font-weight: 700;
  font-size: 18px;
}

.calendar-area >>> .v-calendar-weekly__head-weekday {
  font-weight: 700;
  font-size: 13px;
}

.calendar-area >>> .v-calendar-weekly__day-label .v-btn__content {
  font-weight: 700;
  font-size: 18px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.estimate-booking-body-search >>> .search-filter {
  z-index: 9 !important;
}

.estimate-booking {
  width: 50%;
  margin-top: 18px;
  border: solid 1px #b9b9b9;
  border-radius: 5px;
  position: relative;
}

.estimate-booking-display-block {
  display: block;
}

.estimate-booking-header {
  position: relative;
  background-color: #e1e2e5;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 5px 5px 0 0;
}

.estimate-booking-header-close {
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 22px;
  cursor: pointer;
}

.estimate-booking-header-close .bx-x:hover {
  color: #5b5b5b;
  transition: 0.2s;
}

.estimate-booking-body-settings {
  margin: 0 15px;
  padding: 15px 10px 0 10px;
  justify-content: space-between;
  font-size: 14px;
  border-bottom: 1px solid #b9b9b9;
}

.estimate-booking-body-settings-icon {
  position: relative;
  bottom: 2px;
}

.estimate-booking-body-settings-icon .bx-cog {
  font-size: 22px;
  color: #254e9c;
  cursor: pointer;
}

.estimate-booking-body-search {
  padding: 15px 10px;
  margin: 0 15px;
  border-bottom: 1px solid #b9b9b9;
}

.estimate-booking-body-main-left {
  width: 60%;
  overflow-x: auto;
  scrollbar-width: thin;
  max-height: 60vh;
}

.estimate-booking-body-main-right {
  width: 40%;
  display: none;
}

.estimate-booking-body-main-item-title {
  font-weight: bold;
}

.estimate-booking-body-main-item-info-num {
  font-weight: bold;
}

.estimate-booking-body-main-item {
  border: 2px solid #b9b9b9;
  border-radius: 5px;
  margin: 10px 15px;
  padding: 10px 20px;
  cursor: move;
  z-index: 100;
}

.estimate-booking-body-main-item-info-name {
  margin-left: 15px;
}

.estimate-booking-body-main-right {
  border: 2px solid #b9b9b9;
  border-radius: 5px;
  margin: 10px;
  min-width: 200px;
  padding: 10px 15px;
}

.estimate-booking-body-main-right-title {
  font-weight: 700;
  margin-bottom: 0px;
}

.estimate-booking-body-main-right-item {
  margin-bottom: 10px;
}

.estimate-booking-body-main-right-item-title {
  margin-bottom: 10px;
}

.estimate-booking-body-main-right-item-textarea {
  border: 1px solid rgba(28, 31, 57, 0.25);
  border-radius: 3px;
  resize: none;
  padding: 0.5rem 0.75rem;
  width: 100%;
  min-height: 70px;
}

.estimate-booking-settings {
  position: absolute;
  z-index: 10;
  right: 0;
  min-width: 280px;
  top: 25px;
  border: solid 1px #b9b9b9;
  border-radius: 5px;
}

.estimate-booking-settings:after {
  content: "";
  position: absolute;
  bottom: -50px;
  height: 1px;
  width: 1px;
}

.estimate-booking-settings-body {
  padding: 10px 15px;
  background-color: #fdfdfd;
  border-radius: 0 0 5px 5px;
}

.estimate-booking-settings-body-main .form-group {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  min-height: 56px;
}

.estimate-booking-settings-body-main .col-form-label {
  position: relative;
  top: 5px;
  padding-right: 10px;
}

.estimate-booking-settings-body-table {
  margin-top: 30px;
}

.estimate-booking-settings-body-table-title {
  font-weight: 700;
  padding-bottom: 5px;
  border-bottom: 1px solid #b9b9b9;
}

.estimate-booking-settings-body-table-title-item {
  width: 40%;
}

.estimate-booking-settings-body-table-title-item:nth-child(1) {
  width: 20%;
}

.estimate-booking-settings-body-table-main-item-width {
  width: 50%;
}

.estimate-booking-settings-body-table-main-item-width:nth-child(1) {
  /*width: 20%;*/
  /*padding-top: 23px;*/
}

.estimate-booking-settings-body-table-main-item-start {
  margin-right: 10px;
}

.estimate-booking-settings-body-table-main-item {
  height: 90px;
}

.estimate-booking-body-main-item-active {
  border-color: #5d78fd;
  background-color: #dde2fd !important;
}

.estimate-booking-body-main-right-active {
  border-color: #5d78fd;
  display: block;
}
.cards-view-button-center .btn-primary{
  border-radius: 3px;
  color: #f8f8f8;
  background: linear-gradient(135deg, rgb(114, 103, 231) 0%, rgb(98, 89, 239) 49.12%, rgb(78, 69, 238) 100%);
}
.cards-view-button-center{
  margin-left: 20vw;
  height: 41px;
}
.cards-view-button-center .btn{
  margin-right: 5px;
  margin-left: 5px;
  border: 1px solid rgba(27, 30, 56, 0.25);
  border-radius: 3px !important;
}
@media screen and (min-width: 1900px) {
  .cards-view-button-center{
    margin-left: 35vw;
  }
}
@media screen and (max-width: 1200px) {
  .cards-view-button-center{
    margin-left: 7vw;
  }
}
@media screen and (max-width: 880px) {
  .cards-view-button-center{
    margin-left: 20px;
  }
}

@media (max-width: 960px) {
  .weekly-target {
    font-size: 10px;
  }
}
</style>

<style scoped lang="scss">
.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: '';
  }

  &:hover::after {
    display: block;
  }
}

.bx-menu {
  font-size: 30px;
}

.planer-btn-menu {
  height: 28px !important;
  min-width: 30px !important;
  width: 30px !important;
}

  .calendar-card-details {
    display: flex;
  }
  .calendar-card-details-context-menu span:hover{
    background-color: #cdd5fd;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.76);
    padding: 5px 15px;
  }
  .calendar-card-details-context-menu span{
    display: block;
    padding: 5px 15px;
    height: 32px;
  }
  .calendar-card-details span {
    width: 50%;
    margin-bottom: 5px;
    display: block;
    color: rgb(0, 0, 0);
    font-size: 12px;
    font-weight: 400;

  }
  .add-booking-event-menu {
    padding: 0;
    position: absolute;
    left: 0;
    right: 0;
  }

.planer-period-menu {
  padding-right: 200px;
}

.planer-categories-menu {
  margin-right: 0;
  width: 150px;
}

.planer-options-menu {
  padding-left: 30px;
}

.planner-categories-btn {
  justify-content: space-around;
  padding: 50px 5px 20px 5px;
}

.planner-categories-btn .btn {
  padding: 10px 0px;
  text-align: center;
}

.planner-categories-btn .btn-primary {
  background-color: #5E79FF;
}
</style>
<style>
.v-event, .v-event-timed {
  min-height: 25px;
}

.event-name-template {
  display: flex;
  align-items: center;
}

.V3 .planner-page .red-blinking-time input {
  animation: red-blinking 1.5s linear infinite !important;
}

@keyframes red-blinking {
  0% {
    border-color: red;
  }
  50% {
    border-color: rgba(28, 31, 57, 0.25);
  }
  100% {
    border-color: red;
  }
}

.V3 .planner-page .available-day-blinking {
  background-color: #00b050 !important;
  border-color: #00b050 !important;
  animation: blinking-green 3s linear infinite;
  /*animation-iteration-count: infinite;*/
  /*animation-delay: 250ms;*/
}

@keyframes blinking-green {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

.planner-page .calendar-area .v-calendar .v-event-timed-container {
  margin-right: 0px !important;
}
.planner-page .v-calendar-daily_head-day .v-event {
  margin-bottom: 3px;
}
.planner-page .overbooked-modal .modal-content {
  height: 250px !important;
}
.planner-page .v-calendar-daily_head-weekday {
  order: 3;
  z-index: 7;
}

.planner-page .v-calendar-daily_head-day-label {
  order: 2;
  z-index: 7;
}

.planner-page .white-header .v-calendar-daily_head-weekday,
.planner-page .white-header .v-calendar-daily_head-day-label .v-btn__content {
  color: white !important;
}

.planner-page .subtitle-day-week {
  height: 100%;
  order: 1;
  margin: 8px 15px;
  text-align: center;
}

.planner-page .v-event {
  min-height: 20px;
}

.planner-page .v-calendar-daily .v-calendar-daily_head-day {
  display: flex;
  /*justify-content: space-between;*/
  flex-direction: column;
}


.planner-page .v-calendar-daily .v-calendar-daily_head-day > div {
  order: 99999;
  display: none;
}

.planner-page .v-calendar-daily .v-calendar-daily_head-day .v-calendar-daily_head-weekday {
  order: 1 !important;
  display: block !important;
}

.planner-page .v-calendar-daily .v-calendar-daily_head-day .v-calendar-daily_head-day-label {
  order: 2 !important;
  display: block !important;
}

.planner-page .v-calendar-daily .v-calendar-daily_head-day .subtitle-day-week {
  order: 3 !important;
  display: block !important;
}

.planner-page .v-calendar-daily .v-calendar-daily_head-day .v-event {
  order: 4;
  display: block !important;
}

.disabled-planner-cell {
  background-color: #e4e4e4 !important;
}

.available-planner-cell {
  background-color: #00b050 !important;
}
.collapse-tooltip .tooltip-inner {
  border-radius: 0px 10px 10px 10px !important;
}

.collapse-tooltip {
  margin-left: 0px !important;
}

.V3 .booking-status-select .multiselect__input {
  min-width: auto !important;
}

.Vue-Toastification__container {
  z-index: 999999999;
}

.planner-page .all-day-event {
  text-align: center;
}

.planner-page .choose-button {
  background-color: #5E79FF;
  color: white;
  font-size: 13px;
  font-weight: bold;
  border-radius: 3px;
  cursor: pointer;
  padding: 0.75rem 1rem;
  margin-top: 10px;
  margin-left: 160px;
  width: 80px;
  position: absolute;
  top: 265px;
}

.planner-page .category-input {
  cursor: pointer;
}

.planner-page .category-color-picker {
  position: absolute;
  right: 99px;
  top: -30px;
}

.planner-page .adv-search-block .text-grey {
  right: 1rem !important;
}

.planner-page .header {
  z-index: 99;
  display: flex;
  padding-top: 20px !important;
  width: 99% !important;
  overflow: inherit;
  justify-content: flex-end;
}

.planner-page .planner-icon {
  font-size: 35px;
  color: black;
}

.planner-page .planner-icon:hover {
  cursor: pointer;
}

.planner-page .v-toolbar__content {
  justify-content: space-between;
}

@media (min-width: 992px) {
  .planner-page .modal-lg {
    max-width: 500px !important;
  }
}

.planner-page .planner-modal-btn {
  justify-content: end;
  padding-top: 20px;
  position: sticky;
  bottom: 0;
  background-color: #fff;
}

.modal-wizard .modal-footer {
  background-color: #fff;
}

.planner-page .calendar-area .v-application--wrap {
  height: 75vh;
  padding-left: 15px;
  padding-right: 15px;
  min-height: auto;
}

.planner-page .category-color-picker {
  display: block;
}

.planner-page .category-color-picker .v-application {
  /*height: 256px;*/
  max-height: 330px;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgb(27 30 56 / 15%);
  border: 1px solid rgba(0, 0, 0, .15);
}

.planner-page .nav-tabs.nav-tabs-2 {
  position: absolute;
  top: 45px;
}

.planner-page .modal {
  right: -350px;
  z-index: 999999;
}

.planner-page .modal,
.planner-page .modal-wizard {
  top: 0 !important;
  left: 0 !important;
}

.planner-page .modal-dialog {
  right: 0;
  position: absolute;
  /*width: 330px;*/
  width: 400px;
  margin: 0;
}

@media screen and (max-height: 1160px) {
  .planner-page .modal-wizard .modal-footer {
    display: none;
  }

  .planner-page .planner-modal-btn {
    border-top: 1px solid rgba(27, 30, 56, 0.25);
    padding-bottom: 31px !important;
  }

  .planner-page .modal-content {
    height: 100%;
  }
}

@media screen and (min-height: 920px) and (max-height: 1159px) {
  .planner-page .modal-dialog {
    height: 100% !important;
  }
}

@media screen and (min-height: 1160px) {
  .planner-page .modal-dialog {
    height: 900px;
    bottom: 0;
    top: 286px;
  }

  .planner-page .modal-content {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .planner-page .planner-modal-btn {
    padding-top: 70px;
  }
}

.planner-page .fade.in {
  transform: translateX(-350px);
}

.planner-page .modal.fade .modal-dialog {
  right: 0;
}

.planner-page .modal-backdrop.in {
  opacity: 0 !important;
}

.planner-page .modal .form-group {
  margin: 0 !important;
  align-items: center;
  padding-bottom: 10px;
}

.planner-page .modal .multiselect__content-wrapper {
  height: 72px;
}

.planner-page .modal .reminder-multiselect .multiselect__content-wrapper {
  height: 150px;
}

.planner-page .modal .multiselect__tags {
  min-height: 32px !important;
}

.multiselect__option:after {
  line-height: 29px;
  padding-right: 5px;
  padding-left: 0px;
}

.V3 .planner-page .tabs-component {
  position: relative;
  top: 0;
}

.V3 .planner-page .tab-content-table {
  border-top: none;
}

.V3 .planner-page .form-control {
  padding: 0.5rem 0.75rem;
}

.text-category-menu {
  display: block;
  padding: 5px 10px 5px 15px !important;
  background-color: #80808038;
  font-size: 10px;
  font-weight: 700;
}

.V3 .planner-page .v-application {
  margin-top: 3px !important;
}

.categories-menu-color-icon {
  display: inline-block;
  height: 15px;
  width: 15px;
  border-radius: 3px;
  margin-right: 10px !important;
}

.V3 .planner-page .dropdown-menu-child {
  border-radius: 3px;
  right: -50px;
  min-width: 100px;
  padding-top: 0 !important;
}

.V3 .planner-page .dropdown-menu-child li a {
  padding: 10px 18px;
}

.V3 .planner-page .v-current-time {
  height: 2px;
  background-color: #7267E7;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;
}

.V3 .planner-page .v-current-time .first::before {
  content: '';
  position: absolute;
  background-color: #7267E7;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-top: -5px;
  margin-left: -6.5px;
}

.V3 .planner-page .v-calendar-weekly__day-label {
  cursor: default;
}

.V3 .planner-page .multiselect__tags {
  /*width: 176px !important;*/
}

.V3 .planner-page .multiselect-add-people .multiselect__content-wrapper {
  height: 130px;
}

.planner-header-menu-btn-block {
  display: flex;
}


.planer-date-period-text {
  font-size: 25px;
  font-weight: 700;
}

@media screen and (max-width: 1600px) {
  .planner-header-menu-btn-block {
    justify-content: flex-end;
  }

  .planer-period-menu,
  .planer-options-menu {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}
@media screen and (max-width: 1300px) {
  .planer-categories-menu{
    display: none;
  }
}
@media screen and (max-width: 1150px) {
  .weekly-target{
    margin-right: 0 !important;
  }
}
@media screen and (max-width: 1185px) {
  .planer-period-menu{
    width: 100px;
    padding: 0 !important;
  }
  .planer-period-menu .dropdown-options{
    width: 100px;
  }
  .planer-period-menu .dropdown-options .btn{
    width: 100px !important;
  }
  .planer-options-menu .dropdown-options{
    width: 115px;
    margin-right: 0;
  }
  .planer-options-menu .dropdown-options .btn{
    width: 115px;
  }
  .planer-date-period-text-block{
    width: 300px !important;
  }
}
@media screen and (max-width: 1000px) {
  .planer-date-period-text{
    font-size: 20px;
  }
  .planner-icon{
    font-size: 30px !important;
  }
  .planer-date-period-text-block{
    width: 250px !important;
  }
}
@media screen and (max-width: 890px) {
  .planer-date-period-text{
    font-size: 16px;
  }
  .planner-icon{
    font-size: 25px !important;
  }
  .planer-date-period-text-block{
    width: 190px !important;
    position: relative;
    right: 20px;
  }
  .planer-date-period{
    white-space: inherit !important;
  }
}
@media screen and (max-width: 800px) {
  .planer-date-period-text-block{
    width: 150px !important;
  }
}

@media screen and (max-width: 767px) {
  .V3 .fixed-header.compact-sidebar .site-header {
    left: 0;
  }

  .V3 .page-header {
    z-index: 99999;
    min-height: 75px;
  }

  .V3 .tabs-component {
    padding-top: 0;
  }

  .planner-page .planner-icon {
    font-size: 25px;
  }

  .planer-date-period-text-block {
    width: 200px !important;
    text-align: start;
    padding-bottom: 3px;
    margin-right: 20px;
  }

  .planer-date-period-text {
    font-size: 18px;
    color: black;
    padding-bottom: 5px;
  }

  .V3 .planner-page .planer-date-period {
    top: 135px;
    justify-content: flex-end;
  }

  .V3 .dropdown-options,
  .planer-categories-menu {
    width: 110px !important;
  }

  .V3 .dropdown-options .btn {
    width: 110px !important;
    padding: 0.75rem 5px;
  }

  .V3 .dropdown-options .dropdown-arrow {
    width: 25px !important;
  }

  .planer-period-menu .dropdown-options,
  .V3 .planer-period-menu .dropdown-options .btn {
    width: 80px !important;
  }

  .fa.pull-right {
    margin-right: 0.3em;
  }

  .planer-options-menu .dropdown-options,
  .V3 .planer-options-menu .dropdown-options .btn {
    width: 90px !important;
  }

  .V3 .planer-categories-menu .dropdown-options .dropdown-menu {
    width: 200px !important;
  }

  .V3 .planner-page .planer-categories-menu .form-control {
    width: 165px !important;
  }
  .weekly-target{
    display: none;
  }
}

@media (max-width: 460px) {
  .V3 .planner-page .planer-date-period {
    top: 130px;
  }
}

@media (max-width: 425px) {
  .V3 .tabs-component .tab-header.float-sm-right,
  .V3 .tabs-component .tab-header.float-sm-left {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
}

@media screen and (max-width: 450px) and (orientation: portrait) {
  .planer-categories-menu {
    display: none;
  }
}
</style>

<style scoped src="vuetify/dist/vuetify.min.css"></style>

