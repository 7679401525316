<template>
  <div class="">
    <div style="margin: 8px 0; display: flex">
      <div class="subtitle" style="margin-right: 15px">Non-Attendance Reasons</div>
      <button class="update-btn btn btn-secondary" v-if="showEditMenu == false" @click="editBookingReasons" style="margin-left: 8px">
        Edit Count
      </button>
      <button class="update-btn btn btn-secondary" v-if="showEditMenu == true" @click="save" style="margin-left: 8px">
        Save
      </button>
      <button class="update-btn btn btn-secondary" v-if="showEditMenu == true" @click="bookingReasons.push(null)" style="margin-left: 8px">
        Create Tracker
      </button>
      <button class="update-btn btn btn-secondary" v-if="showEditMenu == true" @click="cancelBookingReasons" style="margin-left: 15px">
        Cancel
      </button>
    </div>
    <div class="cards-all-snapshot-main-total non-attendance-block d-flex">
      <div class="cards-all-snapshot-main-total_item">
        <div class="cards-all-snapshot-main-total_item-border">
          <div class="cards-all-snapshot-main-total_item-sum cards-all-snapshot-main-total_item-sum-bold">
            {{reasons.length}}
          </div>
          <div class="cards-all-snapshot-main-total_item-name">
            Non Attendance (Current)
          </div>
        </div>
      </div>

      <b-table
          v-if="!showEditMenu"
          :items="getItems(itm)"
          :fields="getFields(itm)"
          v-for="(itm, ind) in bookingReasons"
          :key="ind"
          style=" margin: 5px 10px 0px 10px; max-width: 250px"
      ></b-table>

      <div v-if="showEditMenu == true" class="cards-all-snapshot-main-total_item"  v-for="(itm, ind) in bookingReasons" :key="ind">

        <div class="cards-all-snapshot-main-total_item-border" style="padding: 8px 12px">
          <div class="cards-all-snapshot-main-total_item-sum cards-all-snapshot-main-total_item-sum-bold"  style="float: right;">

            <div class=""  @focusout="closePopupMenu" tabindex="0">
              <i class='bx bx-dots-vertical-rounded' style="color: grey"  @click="showPopupMenu(itm + ind)" :id="itm + ind"></i>
              <ul v-if="showMenuItem == itm + ind"
                  class="card-menu-popup"
                  style="position: absolute; transform: translate(12px, -8px);"
                  :class="{'card-menu-popup-open-left': isRevertMenuCheck(itm + ind)}">
                <li class="parent" v-if="!itm && computedReasons.length">
                  <div class="title">
                    <i v-if="isRevertMenuCheck(itm + ind)"
                       class="bx bx-chevron-left"></i>
                    Add Reason
                    <i v-if="!isRevertMenuCheck(itm + ind)"
                       class="bx bx-chevron-right"></i>
                  </div>
                  <div class="sub-menu">
                    <ul class="sub-menu-inner">
                      <li v-for="(reason, index) in computedReasons" :key="index" @click="addBookingReasons(reason, ind)">{{ reason }}</li>
                    </ul>
                  </div>
                </li>
                <li  class="parent" :class="{'reverse-label': isRevertMenuCheck(itm + ind)}" @click="removeTrackers(itm, ind)">
                  <div class="title"
                  >
                    Remove Reason
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div style="font-weight: 900; font-size: 15px; text-align: center; margin-top: 20%;">
            {{itm}}
          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import Axios from "axios";
import _ from "lodash";
import dayjs from "dayjs";

export default {
  name: "TheReasons",
  data: function () {
    return {
      bookingReasons: [],
      addReasons: [],
      bookingReasonsBeforeEdit: [],
      removeBookingReasons: [],
      showMenuItem: null,
      showEditMenu: false,
    }
  },
  mounted() {
    let v = _.cloneDeep(this.vendorInfo)
    this.bookingReasons = v.bookingReasons ? v.bookingReasons : []
  },
  props: {
    reasons: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    vendorInfo: {
      handler(v) {
        this.bookingReasons = v.bookingReasons ? v.bookingReasons : []
      },
      deep: true,
    }
  },
  methods: {
    getFields(itm) {
      return [
          'reason',
        {
          key: 'count',
          label: itm
        }
      ]
    },
    getItems(itm) {
      let oneToFive = 0;
      let sixToTen = 0;
      let elevenToFifteen = 0;
      let greaterThenTen = 0;
      _.forEach(this.computedEvents, (e) => {
        if (e.reason == itm) {
          if (e.reasonDays >= 1 && e.reasonDays <= 5) {
            oneToFive += 1
          } else if (e.reasonDays >= 6 && e.reasonDays <= 10) {
            sixToTen += 1
          } else if (e.reasonDays >= 11 && e.reasonDays <= 15) {
            elevenToFifteen += 1
          } else if (e.reasonDays > 10) {
            greaterThenTen += 1
          }
        }
      })
      return [
        { reason: "1-5 Days", count: oneToFive},
        { reason: "6-10 Days", count: sixToTen},
        { reason: "11-15 Days", count: elevenToFifteen},
        { reason: "Greater 10 Days", count: greaterThenTen}
      ]
    },
    toTimestamp(date) {
      if (typeof(date) !== 'string') {
        return date
      }
      // "2022-07-25 09:00"
      if (date.includes(':')) {
        let [date1, date2] = date.split(' ');
        let [year, month, day] = date1.split('-');
        let [hours, minutes] = date2.split(':');

        let datum = new Date(year + '-' + month + '-' + day + 'T' + hours + ':' + minutes + ':00');
        return datum.getTime();

      } else {
        let [year, month, day] = date.split('-');
        let datum = new Date(year + '-' + month + '-' + day + 'T00:00:00');
        return datum.getTime();
      }
    },
    save() {
      this.showEditMenu = false

      let addBooking = _.filter(this.addReasons, (c) => {
        return !_.includes(this.bookingReasonsBeforeEdit, (c))
      })


      this.bookingReasons = _.filter(this.bookingReasons, (id) =>  {return !!id})

      NProgress.start();
      Axios.post('/fe/booking-trackers', {addBookingReason: _.filter(addBooking, (id) =>  {return _.isString(id)}), removeBookingReason: _.filter(this.removeBookingReasons, (id) =>  {return _.isString(id)})}
      ).then(res => {
        if (res.data._status) {
          this.isEdit = false
          this.$store.dispatch('loadVendorInfo');
          this.addReasons = [];
          this.removeBookingReasons = []
        }
      }).catch(e => {
        console.log(e);
      })
          .finally(()=>{
            NProgress.done();
          })
    },
    removeTrackers(id, index) {
      this.closePopupMenu()
      if (_.includes(this.addReasons, id)) {
        if (id == null) {
          let nInd = _.find(this.addReasons, (i) => {return i == null})
          if (nInd !== -1) {
            this.addReasons.splice(nInd, 1)
          }
        } else {
          this.addReasons = _.filter(this.addReasons, (rId) => {
            return rId !== id
          })
        }
      }
      if (_.includes(this.bookingReasons, id)) {
        if (id == null) {
          this.bookingReasons.splice(index, 1)
        } else {
          this.bookingReasons = _.filter(this.bookingReasons, (rId) => {
            return rId !== id
          })
        }
      }
      this.removeBookingReasons.push(id)
    },
    editBookingReasons() {
      this.showEditMenu = true
      this.bookingReasonsBeforeEdit = _.cloneDeep(this.bookingReasons)
    },
    addBookingReasons(id, index) {
      this.closePopupMenu();
      if (_.includes(this.removeBookingReasons, id)) {
        this.removeBookingReasons = _.filter(this.removeBookingReasons, (rId) => {
          return rId !== id
        })
      }
      this.addReasons.push(id)
      if (index == -1) {
        this.bookingReasons.push(id)
      } else {
        this.bookingReasons.splice(index, 1, id)
      }
    },
    showPopupMenu(id) {
      this.showMenuItem = id
    },
    closePopupMenu() {
      this.showMenuItem = null
    },
    cancelBookingReasons() {
      this.showEditMenu = false
      this.addReasons = []
      this.removeBookingReasons = []
      this.bookingReasons = this.bookingReasonsBeforeEdit
    },
    isRevertMenuCheck(id) {
      let el = document.getElementById(id)?.getBoundingClientRect();
      if (!el || (window.innerWidth - el.right) <= 330) {
        return true
      }
      return false
    },


  },
  computed: {
    ...mapGetters({
      vendorInfo: 'vendorInfo',
      getBookingItems: 'board/getBookingItems',
      filter: 'board/getBookingFilter',
    }),

    computedEvents() {
      let events = _.filter(this.getBookingItems, (e) => {
        return e.type == 'on-site' && e.reason
      })
      return _.map(events, (e) => {
        e.start = this.toTimestamp(e.dateStart + ' ' + e.timeStart);
        e.end = this.toTimestamp(e.dateEnd + ' ' + e.timeEnd);
        return e
      });
    },

    computedReasons() {
      return _.filter(this.reasons, (r) => {
        return !_.includes(this.bookingReasons, r)
      })
    }
  },
};
</script>


<style scoped>
.non-attendance-block >>> table th, .non-attendance-block >>> table td{
  padding: 3px 9px;
  border-right: 1px solid rgba(128, 128, 128, 0.55);
}
.non-attendance-block >>> table {
  border: 1px solid #F3F3F3;
  margin: 0 5px;
  flex: 20%;
  max-width: 300px;
}

.subtitle{
  font-weight: 700;
  font-size: 16px;
  padding-top: 6px;
}

.card-menu-popup-open-left {
  transform: translate(-121px, -8px) !important;
}
.card-menu-popup-open-left .sub-menu {
  left: -143px !important;
}

.reverse-label {
  display: flex;
  justify-content: end;
}

.cards-all-snapshot-main-total_item{
  text-align: center;
  flex: 20%;
  padding: 5px;
  max-width: 20%;
}
.cards-all-snapshot-main-total_item-border{
  border: 1px solid #c5c5c5;
  border-radius: 5px;
  padding: 20px 20px;
  height: 100%;
}
.cards-all-snapshot-main-total_item:first-child{
  margin-left: 0;
}
.cards-all-snapshot-main-total_item:last-child{
  margin-right: 0;
}
.cards-all-snapshot-main-total_item-sum{
  font-weight: 700;
  font-size: 23px;
}
.cards-all-snapshot-main-total_item-name{
  margin-top: 10px;
  font-weight: 700;
  font-size: 15px;
}
.cards-all-snapshot-main-total_item-sum-bold{
  font-size: 25px;
  font-weight: 900;
}



@media screen and (max-width: 1100px) {

  .cards-all-snapshot-main-total_item{
    flex: 33.333%;
    max-width: 30%;
  }
}

</style>
