import {Actions} from './actions';
import {Mutations} from './mutations';
import {Getters} from './getters';

const state = {
  itemsIn: [],
  itemsDeparture: [],
  timestamp: 0,
  zoomScaleIn: 1.4,
  zoomScaleDeparture: 1.4,
  bookingItems: [],
  production: null,
  attempts: null,
  reasons: null,
  isLoadedPrev: false,
  bookingFilter: 'Week',
};

export default {
  namespaced: true,
  mutations: Mutations,
  actions: Actions,
  state,
  getters: Getters,
};
