import _ from "lodash";
import Axios from "axios";

const boardLoader = (context, payload = {page: 1, withTimestamp: true, timestamp: 0}, type) => {
    let timestampValue = 0;
    let page = (payload.page) ? payload.page : 1;

    if (payload.withTimestamp) {
        timestampValue = (!payload.timestamp) ? context.getters['getTimestamp'] : payload.timestamp;
    }

    let url = type === 'departure' ? '/fe/board/departure' : '/fe/board/in';
    const dispatch = type === 'departure' ? 'loadDepartureBoard' : 'loadInBoard';

    url += `/${timestampValue}/page/${page}`;

    let request = Axios.get(url)
        .then((response) => {
            if (response.data.data) {
                let dataTimestamp = response.data._timestamp;
                if (dataTimestamp) context.commit('setTimestamp', dataTimestamp);
                let zoomScale = response.data.zoom;
                if (zoomScale && type === 'in') {
                    context.commit('setZoomScaleIn', zoomScale);
                } else if (zoomScale && type === 'departure') {
                    context.commit('setZoomScaleDeparture', zoomScale);
                }
                context.commit(dispatch, response.data.data);
                if (response.data.removedBoards) {
                    if (type === 'departure') {
                        context.commit('removeFromDepartureBoard', response.data.removedBoards);
                    } else {
                        context.commit('removeFromInBoard', response.data.removedBoards);
                    }
                }
                if (response.data._isNext && response.data._isNext === true) {
                    return context.dispatch(dispatch, {
                        page: (Number(page) + 1),
                        withTimestamp: payload.withTimestamp,
                        timestamp: timestampValue
                    });
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
    return request;

};

export const Actions = {
    loadInBoard(context, payload = {page: 1, withTimestamp: true, timestamp: 0}) {
        return boardLoader(context, payload, 'in');
    },
    loadDepartureBoard(context, payload = {page: 1, withTimestamp: true, timestamp: 0}) {
        return boardLoader(context, payload, 'departure');
    },
    loadBookingBoard(context, payload = 0) {
        return new Promise((resolve, reject) => {
            let url = !payload.isPrev ? '/fe/planner-board' : 'fe/planner-board/past';
            if (!_.isEmpty(payload?.timestamp)) url = '/fe/planner-board/' + payload.timestamp;

            if (payload.isPrev) {
                context.commit('loadedPrev')
            }

            Axios.get(url)
                .then(response => {
                    if (response.data.production) {
                        context.commit('addProduction', response.data.production)
                    }
                    if (response.data.reasons) {
                        context.commit('addReasons', response.data.reasons)
                    }
                    if (response.data.attemps) {
                        context.commit('addAttempts', response.data.attemps)
                    }
                    if (payload.timestamp) {
                        if (!_.isEmpty(response.data.data)) {
                            context.commit('updateBookingItems', response.data.data)
                        }
                        if (!_.isEmpty(response.data.removedPlannerBoards)) {
                            context.commit('removeBookingItems', response.data.removedPlannerBoards)
                        }
                    } else {
                        if (response.data.data) {
                            context.commit('addBookingItems', response.data.data)
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    console.log(error);
                    reject(error)
                });
        })

    }
};
